import { LOCALE } from 'constants/i18n';

import { isString } from 'types/utils';

import envs, { isLocalDev } from './envs';
import { isSSR } from './isSSR';
import qs, { CustomParsedQs } from './qs';

/**
 * Returns a string with:
 *   - A single slash at the beginning (i.e.: 'foo/bar' -> '/foo/bar')
 *   - No multiple slashes (i.e.: '/foo//bar' -> '/foo/bar')
 *   - No trailing slash (i.e.: '/foo/bar/' -> '/foo/bar')
 *   - Maintains root path ('/' -> '/')
 * @param pathname string
 * @private export used for testing
 */
export const sanitizeUrlPathname = (pathname: string): string =>
  pathname
    // Replace multiple slashes with a single one
    .replace(/\/{2,}/g, '/')
    // Ensure no trailing slash
    .replace(/\/$/, '')
    // Ensure a single slash at the beginning of the string
    .replace(/^\/?/, '/');

/**
 * Converts an object or string to a url search string with:
 *   - A single question mark at the beginning when there's parameters (i.e.: 'foo=bar' -> '?foo=bar')
 *   - Empty string is returned when there's no parameters (i.e.: {} -> '', '?' -> '', '' -> '' )
 * @param search
 */
const sanitizeUrlSearch = (search: string | CustomParsedQs): string =>
  // Parse search object to string
  (isString(search) ? search : qs.stringify(search))
    // Ensure a single question mark at the beginning of the string
    .replace(/^\?*/g, '?')
    // Ensure no '?' is returned
    .replace(/^\?+$/g, '');

/**
 * Returns a string with:
 *   - A single hash sign at the beginning (i.e.: '###foo' -> '#foo', 'foo' -> '#foo')
 *   - Empty string is returned when there's no hash (i.e.: '#' -> '', '' -> '')
 * @param hash string
 */
const sanitizeUrlHash = (hash: string): string => hash.replace(/^#*/g, '#').replace(/^#+$/g, '');

export type UrlParts<SearchType = CustomParsedQs> = {
  pathname: string;
  search?: SearchType;
  hash?: string;
};

type CreateUrlToFunction = (urlParts: UrlParts<string | CustomParsedQs>) => string;

export const convertToAbsoluteUrl = (relativeUrl: string) => {
  const baseUrl = envs.NEXT_PUBLIC_SITE_BASE_URL.replace(/\/+$/, '');
  return baseUrl + relativeUrl;
};

/**
 * Creates a relative url with sanitized pathname, search and hash strings. Includes trailing slash.
 * @param pathname
 * @param search
 * @param hash
 */
export const createRelativeUrlTo: CreateUrlToFunction = ({ pathname, search = '', hash = '' }) =>
  sanitizeUrlPathname(pathname) + sanitizeUrlSearch(search) + sanitizeUrlHash(hash);

const isLocalizedPathname = new RegExp(
  '^/(' +
    Object.values(LOCALE)
      .filter((locale) => locale !== LOCALE.Fi)
      .join('|') +
    ')(/|$|\\?)',
);

/**
 * Given a locale and a pathname (i.e. from contentful field pageSlug.pathname),
 * it generates a localized url pathname
 */
export const localizePathname = (locale: LOCALE, pathname: string) => {
  if (pathname.startsWith('#')) {
    // do not modify anchor links which point to the current page
    return pathname;
  }

  if (/^[a-z]+:/.test(pathname) || locale === LOCALE.Fi) {
    return pathname;
  }

  if (isLocalizedPathname.test(pathname)) {
    return pathname;
  }

  return `/${locale}${pathname}`;
};

const SITE_BASE_URL = envs.NEXT_PUBLIC_SITE_BASE_URL.endsWith('/')
  ? envs.NEXT_PUBLIC_SITE_BASE_URL.substring(0, envs.NEXT_PUBLIC_SITE_BASE_URL.length - 1)
  : envs.NEXT_PUBLIC_SITE_BASE_URL;

const allowedHostsRegexps = [
  /^(([a-z-]+)*\.)?vr\.fi$/,
  ...(isLocalDev ? [/^(localhost)|(127\.0\.0\.1)$/] : []),
];

const allowedProtocols = ['https:', ...(isLocalDev ? ['http:'] : [])];

export const isAllowedUrl = (url: URL) =>
  allowedHostsRegexps.some((rule) => rule.test(url.hostname)) &&
  allowedProtocols.includes(url.protocol);

export const currentOrigin = (): string => {
  if (isSSR()) return new URL(SITE_BASE_URL).origin;
  const currentUrl = new URL(window.location.href);
  return isAllowedUrl(currentUrl) ? currentUrl.origin : new URL(SITE_BASE_URL).origin;
};

/**
 * Creates an absolute url with sanitized pathname, search and hash strings.
 * @param urlParts
 */
export const createAbsoluteUrlTo: CreateUrlToFunction = (urlParts) => {
  // Ensure no trailing slash
  const baseUrl = currentOrigin().replace(/\/+$/, '');
  return baseUrl + createRelativeUrlTo(urlParts);
};
