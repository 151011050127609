import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';

import { useVariable } from 'hooks/useVariable';

import { Maybe } from 'types/utils';

import { setAppLoading } from 'state/app';
import envs from './envs';
import { fromEntries } from './object';
import { CustomParsedQs, default as qs } from './qs';
import { createRelativeUrlTo, currentOrigin, isAllowedUrl } from './route';

type NextRouter = ReturnType<typeof useRouter>;
type Url = Parameters<NextRouter['push']>[0] | URL;
type TransitionOptions = NonNullable<Parameters<NextRouter['push']>[1]>;

interface UseNavigateOptions extends TransitionOptions {
  replace?: boolean;
  state?: Record<string, any>;
}

export function useInternalNavigate() {
  const router = useRouter();

  return useCallback(
    (url: Url, { replace, ...options }: UseNavigateOptions = {}) => {
      if (!isAllowedUrl(new URL(url.toString(), currentOrigin()))) {
        throw new Error(
          `Requested URL is not internal: ${url} (origin ${
            new URL(url.toString(), currentOrigin()).origin
          } is not within allowed URL rules)`,
        );
      }
      const method = replace ? 'replace' : 'push';

      return router[method](url.toString(), options);
    },
    [router],
  );
}

export function useNavigate() {
  const router = useRouter();
  return useCallback(
    (url: Url, { replace, ...options }: UseNavigateOptions = {}) => {
      const method = replace ? 'replace' : 'push';
      return router[method](url.toString(), options);
    },
    [router],
  );
}

interface UseLocation {
  pathname: string;
  hash: string;
  search: string;
  state: Record<string, any>;
}

export function useLocation(): UseLocation {
  const path = usePathname();
  const searchParams = useSearchParams();

  return useMemo(() => {
    /** @see https://github.com/vercel/next.js/discussions/23991 */
    const { locale, scroll, shallow, ...state } =
      (typeof window !== 'undefined' && window.history?.state?.options) || {};
    const url = new URL(
      `${path}?${searchParams.toString()}#${
        typeof window !== 'undefined' ? window.location.hash : ''
      }`,
      'http://www.vr.fi',
    );

    return {
      pathname: url.pathname,
      hash: url.hash,
      search: url.search,
      state,
    };
  }, [path, searchParams]);
}

export type NextLocation = ReturnType<typeof useLocation>;

export const useQuery = () => {
  const searchParams = useSearchParams();
  return useMemo(() => fromEntries(searchParams.entries()), [searchParams]);
};

export const setQuery = <T extends object>(query: T) => {
  window.history.replaceState(null, '', `?${qs.stringify(query)}`);
};

export const modifyQuery = <T extends Record<string, any>>(change: T) => {
  const prevQuery = qs.parse(window.location.search);

  window.history.replaceState(
    null,
    '',
    `?${qs.stringify({
      ...prevQuery,
      ...change,
    })}`,
  );
};

export const safeUrl = (from: Maybe<string>) => {
  try {
    return new URL(from ?? '');
  } catch (e) {
    return new URL('/', currentOrigin());
  }
};

export const ciamLogoutReturnTo = (locale: LOCALE, returnToUrl: string, initLogout?: boolean) => {
  const { pathname, search, hash } = new URL(returnToUrl, envs.NEXT_PUBLIC_SITE_BASE_URL);

  return new URL(
    `/logout?locale=${locale}&returnTo=${encodeURIComponent(pathname + search + hash)}${
      initLogout ? '&initLogout=true' : ''
    }`,
    currentOrigin(),
  ).toString();
};

export const useOnRouteChange = (fn: () => void) => {
  const router = useRouter();
  const initialLoad = useRef(true);
  const fnRef = useVariable(fn);

  useEffect(() => {
    if (!initialLoad.current) {
      fnRef.current();
    }
    initialLoad.current = false;
  }, [fnRef, router]);
};

const LOGIN_PARAMS: Record<LOCALE, string> = {
  fi: 'kirjaudu-sisaan',
  sv: 'logga-in',
  en: 'login',
};

export const useLoginUrl = () => {
  const location = useLocation();
  const { locale } = useIntl();

  const search = useMemo(() => {
    const loginParam = LOGIN_PARAMS[locale];

    const parsedSearch = qs.parse(location.search);

    Object.values(LOGIN_PARAMS).forEach((loginParam) => delete parsedSearch[loginParam]);

    const previousSearch = qs.stringify(parsedSearch);

    return previousSearch ? `?${previousSearch}&${loginParam}` : `?${loginParam}`;
  }, [locale, location.search]);

  return urlToRelativeUrl({
    ...location,
    search,
  });
};

export const removeLoginParams = (relativeUrl: string) => {
  const url = new URL(relativeUrl, 'https://www.vr.fi');
  return urlToRelativeUrl(removeLoginParamsFromUrl(url));
};

export const removeLoginParamsFromUrl = (url: URL) => {
  const newURL = new URL(url);
  Object.values(LOGIN_PARAMS).forEach((param) => newURL.searchParams.delete(param));
  return newURL;
};

export const useHasLoginParam = () => {
  const searchParams = useSearchParams();
  return Object.values(LOGIN_PARAMS).some((param) => searchParams.has(param));
};

export const useRemoveLoginParam = () => {
  const router = useRouter();

  return useCallback(async () => {
    const newUrl = new URL(window.location.href);
    Object.values(LOGIN_PARAMS).forEach((param) => newUrl.searchParams.delete(param));
    router.replace(newUrl.href);
  }, [router]);
};

/** @private export used for testing */
export const urlToRelativeUrl = (url: { pathname: string; search: string; hash: string }) =>
  `${url.pathname}${url.search}${url.hash}`;

export const useModifyRoute = () => {
  const router = useRouter();

  return useCallback(
    (
      method: 'push' | 'replace',
      fn: (prev: { pathname: string; search: CustomParsedQs; hash: string }) => {
        pathname: string;
        search: CustomParsedQs;
        hash: string;
      },
      options?: TransitionOptions,
    ) => {
      const newRoute = fn({
        pathname: window.location.pathname,
        search: qs.parse(window.location.search),
        hash: window.location.hash,
      });
      router[method](createRelativeUrlTo(newRoute), options);
    },
    [router],
  );
};

const startExternalNavigate = () => {
  setAppLoading(true);
  onCachedBack(() => {
    setAppLoading(false);
  });
};

export const onCachedBack = (fn: () => void) => {
  const onPageShow = (event: PageTransitionEvent) => {
    // Application's state is persisted if coming back from the payment provider using the back button.
    // We have to remove loading state if this happens.
    // https://web.dev/articles/bfcache
    if (event.persisted) {
      fn();
    }
    window.removeEventListener('pageshow', onPageShow);
  };

  window.addEventListener('pageshow', onPageShow);
};

export const externalNavigate = (href: string) => {
  startExternalNavigate();
  window.location.href = href;
};

export const doFormPost = (action: string, body: Record<string, string> = {}) => {
  startExternalNavigate();

  const form = document.createElement('form');
  form.method = 'POST';
  form.action = action;

  Object.entries(body).forEach(([key, value]) => {
    const input = document.createElement('input');
    input.type = 'hidden';
    input.name = key;
    input.value = value;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();

  setTimeout(() => {
    document.body.removeChild(form);
  });
};
