export const CURRENCY_OPTIONS = {
  style: 'currency' as const,
  currency: 'EUR',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
};

// Order of enum values affects language selector's option ordering
export enum LOCALE {
  Fi = 'fi',
  En = 'en',
  Sv = 'sv',
}

export const LOCALES: Record<LOCALE, { code: LOCALE; label: string }> = {
  [LOCALE.Fi]: {
    code: LOCALE.Fi,
    label: 'Suomi',
  },
  [LOCALE.En]: {
    code: LOCALE.En,
    label: 'English',
  },
  [LOCALE.Sv]: {
    code: LOCALE.Sv,
    label: 'Svenska',
  },
};
