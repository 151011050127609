// THIS IS A GENERATED FILE
import { FieldPolicy, FieldReadFunction, TypePolicies, TypePolicy } from '@apollo/client/cache';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: string; output: string };
  DateTime: { input: string; output: string };
  JSON: { input: any; output: any };
  JSONObject: { input: any; output: any };
  NaturalNumber: { input: number; output: number };
  Time: { input: string; output: string };
};

export type AdditionalProduct = {
  __typename?: 'AdditionalProduct';
  count: Scalars['Int']['output'];
  type?: Maybe<ProductType>;
};

export type AdditionalReservationOffer = {
  __typename?: 'AdditionalReservationOffer';
  commercialTrainNumber?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: AdditionalReservationProduct;
  totalPrice?: Maybe<Scalars['Int']['output']>;
};

export enum AdditionalReservationProduct {
  AdjacentSeatEco = 'ADJACENT_SEAT_ECO',
  AdjacentSeatExtra = 'ADJACENT_SEAT_EXTRA',
  AdjacentSeatRestaurantWagon = 'ADJACENT_SEAT_RESTAURANT_WAGON',
}

export type AdditionalServiceInput = {
  type: Scalars['String']['input'];
};

export type AssemblyWagon = {
  __typename?: 'AssemblyWagon';
  attributes?: Maybe<Array<Scalars['String']['output']>>;
  cabinSizes?: Maybe<Array<Scalars['Int']['output']>>;
  classes?: Maybe<Array<Scalars['String']['output']>>;
  coachConnection?: Maybe<Scalars['String']['output']>;
  coachNumber?: Maybe<Scalars['Int']['output']>;
  coachPriority?: Maybe<Scalars['Int']['output']>;
  coachType?: Maybe<Scalars['String']['output']>;
  commercialTrainArrivalStation?: Maybe<Scalars['String']['output']>;
  commercialTrainDepartureDate?: Maybe<Scalars['String']['output']>;
  commercialTrainDepartureStation?: Maybe<Scalars['String']['output']>;
  commercialTrainNumber?: Maybe<Scalars['String']['output']>;
  floorCount?: Maybe<Scalars['Int']['output']>;
  placeType?: Maybe<Scalars['String']['output']>;
  reservable?: Maybe<Scalars['Boolean']['output']>;
  salesWagonName?: Maybe<Scalars['String']['output']>;
};

export enum AvailabilityStatus {
  /** Cabins are available */
  Available = 'AVAILABLE',
  /** Some cabin types are unavailable */
  Limited = 'LIMITED',
  /** No cabins on trains */
  None = 'None',
  /** No cabins are available */
  Unavailable = 'UNAVAILABLE',
}

export type AvecraExternalServiceLeg = {
  __typename?: 'AvecraExternalServiceLeg';
  foodSelection1?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['ID']['output'];
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  serviceProvider?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  supplementalServices?: Maybe<Array<Scalars['String']['output']>>;
};

export type AvecraOffers = {
  __typename?: 'AvecraOffers';
  count: Scalars['Int']['output'];
  products: Array<AvecraProduct>;
};

export type AvecraProduct = {
  __typename?: 'AvecraProduct';
  available: Scalars['Boolean']['output'];
  foodSelection1: Array<Scalars['String']['output']>;
  foodSelection2: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Scalars['ID']['output'];
  productId: Scalars['String']['output'];
  purchaseType: AvecraPurchaseType;
  supplementalServices: Array<Scalars['String']['output']>;
  unavailabilityReasons: Array<AvecraUnavailabilityReason>;
};

export type AvecraProductInput = {
  foodSelection1?: InputMaybe<Array<Scalars['String']['input']>>;
  product: Scalars['String']['input'];
  supplementalServices?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AvecraProductsPerLeg = {
  __typename?: 'AvecraProductsPerLeg';
  legId: Scalars['String']['output'];
  productAvailability: AvecraOffers;
};

export enum AvecraPurchaseType {
  /** on Demand */
  OnDemand = 'ON_DEMAND',
  /** Pre order */
  Preorder = 'PREORDER',
  PreorderGroup = 'PREORDER_GROUP',
}

export type AvecraService = {
  __typename?: 'AvecraService';
  avecraGroupId: Scalars['ID']['output'];
  deliveryState?: Maybe<Scalars['String']['output']>;
  deliveryTime?: Maybe<Scalars['String']['output']>;
  purchaseType: Scalars['String']['output'];
  services: Array<AvecraExternalServiceLeg>;
};

export enum AvecraUnavailabilityReason {
  ArrivalTimeNotAllowed = 'ARRIVAL_TIME_NOT_ALLOWED',
  FoodSelectionIsMandatory = 'FOOD_SELECTION_IS_MANDATORY',
  InvalidFoodSelection = 'INVALID_FOOD_SELECTION',
  /** Avecra product is not valid during the time of travel */
  NotValidDuringTravel = 'NOT_VALID_DURING_TRAVEL',
  NoRestaurantInTrain = 'NO_RESTAURANT_IN_TRAIN',
  NoValidSupplementalServices = 'NO_VALID_SUPPLEMENTAL_SERVICES',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  /** Avecra on-demand product has been removed (by train personnel) from sales for the given train */
  ProductSaleNotAllowed = 'PRODUCT_SALE_NOT_ALLOWED',
  /** Train arrival too close for on-demand products */
  TooCloseToArrival = 'TOO_CLOSE_TO_ARRIVAL',
  /** Train departure too close for preorder products */
  TooCloseToDeparture = 'TOO_CLOSE_TO_DEPARTURE',
  /** Train has already departed, applies to preorder products if order before departure limit is not otherwise adjusted */
  TrainHasDeparted = 'TRAIN_HAS_DEPARTED',
  /** Avecra products are not supported in the train */
  TrainNotAllowed = 'TRAIN_NOT_ALLOWED',
  /** Train sales for on-demand products is closed by train personnel, or has not been opened */
  TrainSalesClosed = 'TRAIN_SALES_CLOSED',
  TravelTimeTooShort = 'TRAVEL_TIME_TOO_SHORT',
}

export type BedCabin = {
  __typename?: 'BedCabin';
  cabins: Array<CabinDetails>;
  discount?: Maybe<Discount>;
  id: Scalars['String']['output'];
  placeType: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  priceDifference: Scalars['Int']['output'];
  validUntil: Scalars['String']['output'];
};

export type Bicycle = {
  __typename?: 'Bicycle';
  wagonNumber?: Maybe<Scalars['Int']['output']>;
};

export type BicycleInput = {
  amount: Scalars['NaturalNumber']['input'];
};

export enum BookingState {
  Activated = 'ACTIVATED',
  Cancelled = 'CANCELLED',
  Paid = 'PAID',
  Refunded = 'REFUNDED',
  Used = 'USED',
}

export enum BookingType {
  PlaceReservation = 'PLACE_RESERVATION',
  Service = 'SERVICE',
  ServiceFee = 'SERVICE_FEE',
  TravelRight = 'TRAVEL_RIGHT',
}

/** Deprecated, this can be removed in the next release (after 35.6.0) */
export type BreakfastOffers = {
  __typename?: 'BreakfastOffers';
  count: Scalars['Int']['output'];
  products: Array<AvecraProduct>;
};

export type BundleDetails = {
  __typename?: 'BundleDetails';
  bookingId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  usageEndTime?: Maybe<Scalars['String']['output']>;
  usageStartTime?: Maybe<Scalars['String']['output']>;
  usagesCount?: Maybe<Scalars['Int']['output']>;
  usagesLeft?: Maybe<Scalars['Int']['output']>;
};

export type BusJourneyOffer = {
  __typename?: 'BusJourneyOffer';
  arrivalTime?: Maybe<Scalars['String']['output']>;
  availableForSale?: Maybe<Scalars['Boolean']['output']>;
  bicycle?: Maybe<Scalars['Boolean']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  matkahuoltoDestinationPlaceId?: Maybe<Scalars['String']['output']>;
  matkahuoltoDestinationPlaceName?: Maybe<Scalars['String']['output']>;
  notAvailableReasons?: Maybe<Array<Scalars['Int']['output']>>;
  offerGroupId?: Maybe<Scalars['Int']['output']>;
  passengerJourneyId?: Maybe<Scalars['ID']['output']>;
  passengerType: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: Scalars['String']['output'];
  serviceFeePrice?: Maybe<Scalars['Int']['output']>;
  stationAbbreviation?: Maybe<Scalars['String']['output']>;
  validUntil?: Maybe<Scalars['String']['output']>;
};

export type BusJourneyOfferGroup = {
  __typename?: 'BusJourneyOfferGroup';
  arrivalTime?: Maybe<Scalars['String']['output']>;
  availableForSale?: Maybe<Scalars['Boolean']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  key: Scalars['String']['output'];
  legs?: Maybe<Array<BusJourneyOfferLeg>>;
  notAvailableReasons?: Maybe<Array<Scalars['Int']['output']>>;
  offers: Array<BusJourneyOffer>;
  price: Scalars['Int']['output'];
  serviceFeePrice?: Maybe<Scalars['Int']['output']>;
};

export type BusJourneyOfferLeg = {
  __typename?: 'BusJourneyOfferLeg';
  arrivalTime?: Maybe<Scalars['String']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  fromPlaceId: Scalars['String']['output'];
  fromPlaceName?: Maybe<Scalars['String']['output']>;
  fromStopAreaName?: Maybe<Scalars['String']['output']>;
  fromStopName?: Maybe<Scalars['String']['output']>;
  lineName?: Maybe<Scalars['String']['output']>;
  lineOperator?: Maybe<Scalars['String']['output']>;
  toPlaceId: Scalars['String']['output'];
  toPlaceName?: Maybe<Scalars['String']['output']>;
  toStopAreaName?: Maybe<Scalars['String']['output']>;
  toStopName?: Maybe<Scalars['String']['output']>;
};

export type BusinessInfo = {
  __typename?: 'BusinessInfo';
  businessId: Scalars['String']['output'];
  city: Scalars['String']['output'];
  name: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  street: Scalars['String']['output'];
};

export type CabinDetails = {
  __typename?: 'CabinDetails';
  attribute?: Maybe<Scalars['String']['output']>;
  discountCategory?: Maybe<Scalars['String']['output']>;
  price: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  type: CabinType;
};

export type CabinLocationInput = {
  commercialTrainNumber?: InputMaybe<Scalars['String']['input']>;
  /** Desired compartment number (cabin number) for new cabin to be added to sales order. Should equal logicalSection field in Availability service. */
  compartmentNumber?: InputMaybe<Scalars['Int']['input']>;
  /** Desired wagon number for new cabin to be added to sales order */
  wagonNumber?: InputMaybe<Scalars['Int']['input']>;
};

export type CabinOperationInput = {
  /** Optional: desired location for the newly added cabin for ADD and REPLACE operations. Unnecessary for REMOVE. Type of chosen cabin must match type of offer. If no location is given, Forte will decide upon location. */
  desiredCabinLocation?: InputMaybe<CabinLocationInput>;
  /** New cabin type. Required for ADD and REPLACE operations. Unnecessary for REMOVE. */
  newCabinType?: InputMaybe<CabinType>;
  /** Old cabin ID to replace/remove. Required for REPLACE and REMOVE operations. Unnecessary for ADD. */
  oldCabinId?: InputMaybe<Scalars['ID']['input']>;
  /** Operation type: ADD/REPLACE/REMOVE. */
  type: CabinOperationType;
};

export enum CabinOperationType {
  Add = 'ADD',
  Remove = 'REMOVE',
  Replace = 'REPLACE',
}

export type CabinPassenger = {
  __typename?: 'CabinPassenger';
  passengerLegId: Scalars['String']['output'];
  reservation?: Maybe<CabinReservation>;
  type: Scalars['String']['output'];
};

export type CabinReservation = {
  __typename?: 'CabinReservation';
  attribute?: Maybe<Scalars['String']['output']>;
  cabinId: Scalars['String']['output'];
  compartmentNumber: Scalars['String']['output'];
  floor: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  placeNumber: Scalars['String']['output'];
  position: Scalars['String']['output'];
  validUntil?: Maybe<Scalars['String']['output']>;
  wagonNumber: Scalars['String']['output'];
};

export enum CabinService {
  /** Accessible cabin */
  Inv = 'INV',
  /** Shower cabin */
  Kyk = 'KYK',
  /** Pet cabin */
  Lem = 'LEM',
  /** Perhe i.e. Family cabin */
  Per = 'PER',
  /** Limited place / Reserved place (rajoitetusti varattava paikka) */
  Rvp = 'RVP',
  /** Conference cabin */
  Sbn = 'SBN',
  /** Työ i.e. work cabin */
  Tyo = 'TYO',
}

export enum CabinType {
  BerthCabinCemt = 'BERTH_CABIN_CEMT',
  BerthCabinEdm = 'BERTH_CABIN_EDM',
  BerthCabinWithShower = 'BERTH_CABIN_WITH_SHOWER',
  SeatInFamilyCabin = 'SEAT_IN_FAMILY_CABIN',
  SeatInNegotiationCabin = 'SEAT_IN_NEGOTIATION_CABIN',
  SeatInWorkingCabin = 'SEAT_IN_WORKING_CABIN',
}

export type Calendar = {
  __typename?: 'Calendar';
  days: Array<Day>;
};

export enum CancelJourneyReason {
  CancellationInsurance = 'CANCELLATION_INSURANCE',
  MultiTicket = 'MULTI_TICKET',
  Other = 'OTHER',
}

export type CancelJourneyResponse = {
  __typename?: 'CancelJourneyResponse';
  reason: CancelJourneyReason;
  status: CancelJourneyStatus;
};

export enum CancelJourneyStatus {
  /** Cancelling partially cancelled orders is not possible when payed with net bank */
  AlreadyPartiallyCancelled = 'ALREADY_PARTIALLY_CANCELLED',
  /** Happens if the journey has already started or ended. Or soft booking expired */
  BookingsExpired = 'BOOKINGS_EXPIRED',
  /** Happens if journey is already cancelled */
  BookingsNotInCancellableStates = 'BOOKINGS_NOT_IN_CANCELLABLE_STATES',
  CancellationPeriodEnded = 'CANCELLATION_PERIOD_ENDED',
  CommuterDeactivationPeriodEnded = 'COMMUTER_DEACTIVATION_PERIOD_ENDED',
  NoCancellationInsurance = 'NO_CANCELLATION_INSURANCE',
  Ok = 'OK',
  /** Any other error */
  TechnicalError = 'TECHNICAL_ERROR',
}

/** response type for cancelJourneys() mutation */
export type CancelJourneysResponse = {
  __typename?: 'CancelJourneysResponse';
  needsManualRefunding?: Maybe<Scalars['Boolean']['output']>;
  reason: CancelReason;
  status: CancelStatus;
};

export enum CancelReason {
  CancellationInsurance = 'CANCELLATION_INSURANCE',
  MultiTicket = 'MULTI_TICKET',
  Other = 'OTHER',
}

/** response type for cancel() mutation */
export type CancelResponse = {
  __typename?: 'CancelResponse';
  reason: CancelReason;
  status: CancelStatus;
};

export enum CancelStatus {
  /** Cancelling partially cancelled orders is not possible when payed with net bank */
  AlreadyPartiallyCancelled = 'ALREADY_PARTIALLY_CANCELLED',
  /** Happens if the journey has already started or ended. Or soft booking expired */
  BookingsExpired = 'BOOKINGS_EXPIRED',
  /** Happens if journey is already cancelled */
  BookingsNotInCancellableStates = 'BOOKINGS_NOT_IN_CANCELLABLE_STATES',
  CancellationPeriodEnded = 'CANCELLATION_PERIOD_ENDED',
  CommuterDeactivationPeriodEnded = 'COMMUTER_DEACTIVATION_PERIOD_ENDED',
  /** Cancellation would result in an order state where there are more vehicles than passengers. This is not allowed: a passenger may only have a maximum of one vehicle. */
  MoreVehiclesThanPassengers = 'MORE_VEHICLES_THAN_PASSENGERS',
  /** When trying to cancel vehicles in an order where there are no vehicles (or passengers when no passengers, but this shouldn't happen generally). */
  NothingToCancel = 'NOTHING_TO_CANCEL',
  NoCancellationInsurance = 'NO_CANCELLATION_INSURANCE',
  /** When no journeys were passed to fetch cancellationSummary or no matches found in order */
  NoMatches = 'NO_MATCHES',
  Ok = 'OK',
  /** Any other error */
  TechnicalError = 'TECHNICAL_ERROR',
}

export enum CancelVehicleReason {
  CancellationInsurance = 'CANCELLATION_INSURANCE',
  Other = 'OTHER',
}

export type CancelVehicleResponse = {
  __typename?: 'CancelVehicleResponse';
  reason: CancelVehicleReason;
  status: CancelVehicleStatus;
};

export enum CancelVehicleStatus {
  /** Cancelling partially cancelled orders is not possible when payed with net bank */
  AlreadyPartiallyCancelled = 'ALREADY_PARTIALLY_CANCELLED',
  /** Happens if the journey has already started or ended. Or soft booking expired */
  BookingsExpired = 'BOOKINGS_EXPIRED',
  /** Happens if journey is already cancelled */
  BookingsNotInCancellableStates = 'BOOKINGS_NOT_IN_CANCELLABLE_STATES',
  CancellationPeriodEnded = 'CANCELLATION_PERIOD_ENDED',
  NoCancellationInsurance = 'NO_CANCELLATION_INSURANCE',
  Ok = 'OK',
  /** Any other error */
  TechnicalError = 'TECHNICAL_ERROR',
}

export type CancellableInput = {
  /** Passenger journey ID that contains the passengers / vehicles to cancel */
  journeyId: Scalars['ID']['input'];
  /** If only PASSENGER journey canceled, vehicle will be re-assigned to other passengers. */
  types: Array<CancellableType>;
};

export enum CancellableType {
  Passenger = 'PASSENGER',
  Vehicle = 'VEHICLE',
}

export type CancellationInsurance = {
  __typename?: 'CancellationInsurance';
  id: Scalars['ID']['output'];
  placePrice?: Maybe<Scalars['Int']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<ProductType>;
  ticketClass?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  validUntil?: Maybe<Scalars['String']['output']>;
};

/** Describes what kind of coverage each part of the journey will receive */
export type CancellationInsuranceCoverage = {
  __typename?: 'CancellationInsuranceCoverage';
  outbound: CancellationInsuranceCoverageEnum;
  /** This field will be null when the journey is one-way */
  return?: Maybe<CancellationInsuranceCoverageEnum>;
};

export enum CancellationInsuranceCoverageEnum {
  /** Cancellation insurance covers all parts of the journey */
  Full = 'FULL',
  /** Cancellation insurance does not cover any part of the journey */
  None = 'NONE',
  /** Cancellation insurance covers some parts of the journey */
  Partial = 'PARTIAL',
}

export type CancellationInsuranceOffer = {
  __typename?: 'CancellationInsuranceOffer';
  coverage?: Maybe<CancellationInsuranceCoverage>;
  expiresAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
};

export type CancellationInsuranceOfferResponse = CancellationInsuranceOffer | NoOffer;

/** response type for cancellationSummary() query */
export type CancellationSummary = {
  __typename?: 'CancellationSummary';
  bookingCancellationSummaryList?: Maybe<Array<CancellationSummaryListItem>>;
  nonRefundableAmount?: Maybe<Scalars['Int']['output']>;
  refundAmount: Scalars['Int']['output'];
  status: CancelStatus;
};

export type CancellationSummaryInput = {
  passengers: Array<Scalars['String']['input']>;
  vehicles: Array<Scalars['String']['input']>;
};

export type CancellationSummaryListItem = {
  __typename?: 'CancellationSummaryListItem';
  amount?: Maybe<Scalars['Int']['output']>;
  amountToRefund?: Maybe<Scalars['Int']['output']>;
  cost?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Car = {
  __typename?: 'Car';
  height?: Maybe<Scalars['Int']['output']>;
  length?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type ChangeSeatInput = {
  /** A new unused offer. This offer includes possible adjacent seats to book. The offers should be fetched using changeSeatOffers query. */
  additionalReservationOfferId?: InputMaybe<Scalars['ID']['input']>;
  /** The desired adjacent seat number */
  adjacentSeatNumber?: InputMaybe<Scalars['Int']['input']>;
  /** A new unused offer. Should be provided in all cases except when we are just adding an adjacent seat to already booked seat. The offers should be fetched using changeSeatOffers query. */
  desiredSeatOfferId?: InputMaybe<Scalars['ID']['input']>;
  passengerJourneyId?: InputMaybe<Scalars['ID']['input']>;
  /** The id of the reservation to be modified */
  reservationId?: InputMaybe<Scalars['ID']['input']>;
  /** The desired seat number */
  seatNumber?: InputMaybe<Scalars['Int']['input']>;
  /** The desired wagon number */
  wagon?: InputMaybe<Scalars['Int']['input']>;
};

export type ChangeSeatsError = {
  __typename?: 'ChangeSeatsError';
  code?: Maybe<ChangeSeatsErrorCode>;
};

export enum ChangeSeatsErrorCode {
  ChangeFailed = 'CHANGE_FAILED',
  InvalidBookings = 'INVALID_BOOKINGS',
  NotReservable = 'NOT_RESERVABLE',
  ServiceError = 'SERVICE_ERROR',
  Unknown = 'UNKNOWN',
}

export type ChangeSeatsResponse = ChangeSeatsError | SalesSession;

export type CiamCodeExchangeResponse = {
  __typename?: 'CiamCodeExchangeResponse';
  user: User;
};

export type CiamCodeExchangeUser = {
  __typename?: 'CiamCodeExchangeUser';
  companyIds?: Maybe<Array<Scalars['String']['output']>>;
  customerId: Scalars['ID']['output'];
};

export type CiamResetPasswordResponse = {
  __typename?: 'CiamResetPasswordResponse';
  success: Scalars['Boolean']['output'];
};

export type CiamUserInfo = UserInfo & {
  __typename?: 'CiamUserInfo';
  activeCompanyId: Scalars['String']['output'];
  activeCompanyName: Scalars['String']['output'];
  activeEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type ClaimOrderResponse = {
  __typename?: 'ClaimOrderResponse';
  status: ClaimOrderStatus;
};

export enum ClaimOrderStatus {
  Ok = 'OK',
  OrderNumberAndReferenceNumberWontMatch = 'ORDER_NUMBER_AND_REFERENCE_NUMBER_WONT_MATCH',
  TicketBelongsToAnotherAccount = 'TICKET_BELONGS_TO_ANOTHER_ACCOUNT',
  UnknownError = 'UNKNOWN_ERROR',
}

export type CodeExchangeResponse = {
  __typename?: 'CodeExchangeResponse';
  success: Scalars['Boolean']['output'];
  user: User;
};

export type CommercialTrain = {
  __typename?: 'CommercialTrain';
  departureTime: Scalars['String']['output'];
  fromStation: Scalars['String']['output'];
  toStation: Scalars['String']['output'];
  trainNumber: Scalars['String']['output'];
  trainType: TrainType;
  type: JoinType;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type CompanyAdminInput = {
  emailAddress: Scalars['String']['input'];
};

export type CompanyContactInput = {
  emailAddress: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  mobilePhone: Scalars['String']['input'];
  title: Scalars['String']['input'];
};

export enum CompanyCreateStatus {
  Draft = 'DRAFT',
  Ok = 'OK',
}

export type CompanyRole = {
  __typename?: 'CompanyRole';
  canPurchase?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  role: JwtCompanyRole;
};

export type CompanyWithRole = {
  __typename?: 'CompanyWithRole';
  name: Scalars['String']['output'];
  name2?: Maybe<Scalars['String']['output']>;
  roles: Array<CompanyRole>;
};

/** Filters to filter connections by */
export enum ConnectionFilter {
  AccessibleJourney = 'ACCESSIBLE_JOURNEY',
  BedCabin = 'BED_CABIN',
  CommuterTraffic = 'COMMUTER_TRAFFIC',
}

export type ConsumerUserDetails = {
  __typename?: 'ConsumerUserDetails';
  car: Car;
  cityName?: Maybe<Scalars['String']['output']>;
  consentB2BMarketing?: Maybe<Scalars['Boolean']['output']>;
  consentCustomerResearch?: Maybe<Scalars['Boolean']['output']>;
  consentMarketing?: Maybe<Scalars['Boolean']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  dateOfBirth: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  gender?: Maybe<Scalars['Int']['output']>;
  lastName: Scalars['String']['output'];
  mobilePhoneNumber?: Maybe<Scalars['String']['output']>;
  otherPhoneNumber?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  serviceLanguage: LanguageCode;
  streetName?: Maybe<Scalars['String']['output']>;
};

export type ConsumerUserInfo = UserInfo & {
  __typename?: 'ConsumerUserInfo';
  activeCompanyId?: Maybe<Scalars['String']['output']>;
  activeCompanyName?: Maybe<Scalars['String']['output']>;
  activeEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type CorporateOffer = {
  __typename?: 'CorporateOffer';
  canCancelBeforeDeparture?: Maybe<Scalars['Boolean']['output']>;
  changeFreeOfCharge?: Maybe<Scalars['Boolean']['output']>;
  includedProducts?: Maybe<Array<Maybe<Product>>>;
  nonRefundableAmount?: Maybe<Scalars['Int']['output']>;
  placeType?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CorporateProductOption = {
  __typename?: 'CorporateProductOption';
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CorporateUserDetails = {
  __typename?: 'CorporateUserDetails';
  emailAddress: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type CorporateUserInfo = UserInfo & {
  __typename?: 'CorporateUserInfo';
  activeCompanyId: Scalars['String']['output'];
  activeCompanyName: Scalars['String']['output'];
  activeEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type CreateCrmCompanyInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressPOBox?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  businessId: Scalars['String']['input'];
  companyAdmins: Array<CompanyAdminInput>;
  companyContacts: Array<CompanyContactInput>;
  companyName: Scalars['String']['input'];
  companyNameRow2?: InputMaybe<Scalars['String']['input']>;
  contactLanguage?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  departmentRow2?: InputMaybe<Scalars['String']['input']>;
  eInvoiceAddress?: InputMaybe<Scalars['String']['input']>;
  invoiceReference?: InputMaybe<Scalars['String']['input']>;
  invoicedCompanyName?: InputMaybe<Scalars['String']['input']>;
  invoicedCompanyNameRow2?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressCity?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressCountry?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPOBox?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPostalCode?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressStreet?: InputMaybe<Scalars['String']['input']>;
  invoicingEmailAddress?: InputMaybe<Scalars['String']['input']>;
  invoicingLanguage?: InputMaybe<InvoicingLanguage>;
  invoicingMethod?: InputMaybe<InvoicingMethod>;
  invoicingRequested: Scalars['Boolean']['input'];
  parentCompanyNumber?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSeriesBundleOffersResponse = NoConnections | SeriesBundleOffers;

export type Creator = {
  __typename?: 'Creator';
  companyType?: Maybe<Scalars['String']['output']>;
};

export type CrmCompany = {
  __typename?: 'CrmCompany';
  additionalInformationForCustomerService?: Maybe<Scalars['String']['output']>;
  addressCity?: Maybe<Scalars['String']['output']>;
  addressCountry?: Maybe<Scalars['String']['output']>;
  addressPOBox?: Maybe<Scalars['String']['output']>;
  addressPostalCode?: Maybe<Scalars['String']['output']>;
  addressStreet?: Maybe<Scalars['String']['output']>;
  businessId?: Maybe<Scalars['String']['output']>;
  companyCRMId: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  companyNameRow2?: Maybe<Scalars['String']['output']>;
  companyNumber: Scalars['String']['output'];
  department?: Maybe<Scalars['String']['output']>;
  departmentRow2?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Scalars['Float']['output']>;
  discountCodesInUse?: Maybe<Scalars['Boolean']['output']>;
  eInvoiceAddress?: Maybe<Scalars['String']['output']>;
  invoiceReference?: Maybe<Scalars['String']['output']>;
  invoicedCompanyName?: Maybe<Scalars['String']['output']>;
  invoicedCompanyNameRow2?: Maybe<Scalars['String']['output']>;
  invoicingAddressCity?: Maybe<Scalars['String']['output']>;
  invoicingAddressCountry?: Maybe<Scalars['String']['output']>;
  invoicingAddressPOBox?: Maybe<Scalars['String']['output']>;
  invoicingAddressPostalCode?: Maybe<Scalars['String']['output']>;
  invoicingAddressStreet?: Maybe<Scalars['String']['output']>;
  invoicingAllowed: Scalars['Boolean']['output'];
  invoicingEmailAddress?: Maybe<Scalars['String']['output']>;
  invoicingInterval?: Maybe<InvoicingInterval>;
  invoicingLanguage?: Maybe<InvoicingLanguage>;
  invoicingMethod?: Maybe<InvoicingMethod>;
  isActive: Scalars['Boolean']['output'];
  lastUpdated: Scalars['String']['output'];
  paymentTerm?: Maybe<PaymentTerm>;
  taxNumber?: Maybe<Scalars['String']['output']>;
};

export type CrmCompanyCreate = {
  __typename?: 'CrmCompanyCreate';
  businessId?: Maybe<Scalars['String']['output']>;
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  status: CompanyCreateStatus;
};

export type CrmCompanyInput = {
  addressCity?: InputMaybe<Scalars['String']['input']>;
  addressCountry?: InputMaybe<Scalars['String']['input']>;
  addressPOBox?: InputMaybe<Scalars['String']['input']>;
  addressPostalCode?: InputMaybe<Scalars['String']['input']>;
  addressStreet?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  departmentRow2?: InputMaybe<Scalars['String']['input']>;
  eInvoiceAddress?: InputMaybe<Scalars['String']['input']>;
  invoiceReference?: InputMaybe<Scalars['String']['input']>;
  invoicedCompanyName?: InputMaybe<Scalars['String']['input']>;
  invoicedCompanyNameRow2?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressCity?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressCountry?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPOBox?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressPostalCode?: InputMaybe<Scalars['String']['input']>;
  invoicingAddressStreet?: InputMaybe<Scalars['String']['input']>;
  invoicingEmailAddress?: InputMaybe<Scalars['String']['input']>;
  invoicingLanguage?: InputMaybe<InvoicingLanguage>;
  invoicingMethod?: InputMaybe<InvoicingMethod>;
};

export type CrmCustomer = {
  __typename?: 'CrmCustomer';
  customerId?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
};

export enum CrmTravellerStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  InvitationExpired = 'INVITATION_EXPIRED',
  Invited = 'INVITED',
}

export type CrmUser = {
  __typename?: 'CrmUser';
  b2bTravellerId: Scalars['String']['output'];
  companyName: Scalars['String']['output'];
  companyNumber: Scalars['String']['output'];
  createdBy: Scalars['String']['output'];
  createdOn: Scalars['String']['output'];
  customer?: Maybe<CrmCustomer>;
  emailAddress: Scalars['String']['output'];
  invitationLanguage?: Maybe<InvitationLanguage>;
  invitationTimeToLive?: Maybe<Scalars['String']['output']>;
  lastUpdated: Scalars['String']['output'];
  role: JwtCompanyRole;
  status: CrmTravellerStatus;
};

export type CrmUsersInput = {
  includeInactive?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  role?: InputMaybe<JwtCompanyRole>;
  searchText?: InputMaybe<Scalars['String']['input']>;
};

export type Day = {
  __typename?: 'Day';
  date: Scalars['Date']['output'];
  error?: Maybe<Error>;
  id?: Maybe<Scalars['ID']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
};

export type DeleteCompanyUserStatus = {
  __typename?: 'DeleteCompanyUserStatus';
  success: Scalars['Boolean']['output'];
};

export enum Direction {
  Arrival = 'ARRIVAL',
  Departure = 'DEPARTURE',
}

export type Discount = {
  __typename?: 'Discount';
  codes?: Maybe<Array<DiscountCode>>;
  discountPercentage: Scalars['Float']['output'];
  ids?: Maybe<Array<Scalars['String']['output']>>;
};

export type DiscountCode = {
  __typename?: 'DiscountCode';
  travelEndDate: Scalars['DateTime']['output'];
  travelStartDate: Scalars['DateTime']['output'];
  /** Known values: FDF, PERSONAL */
  type: Scalars['String']['output'];
  /** Known values: SINGLE_USE, MULTI_USE */
  usageType: Scalars['String']['output'];
};

export type EmployeeUserDetails = {
  __typename?: 'EmployeeUserDetails';
  alias: Scalars['String']['output'];
  department: Scalars['String']['output'];
  emailAddress: Scalars['String']['output'];
  employeeId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type EmployeeUserInfo = UserInfo & {
  __typename?: 'EmployeeUserInfo';
  activeCompanyId?: Maybe<Scalars['String']['output']>;
  activeCompanyName?: Maybe<Scalars['String']['output']>;
  activeEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type Error = {
  __typename?: 'Error';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Possible error codes in error extensions */
export enum ErrorCode {
  AccessTokenExpired = 'AccessTokenExpired',
  AccessTokenRefreshFailed = 'AccessTokenRefreshFailed',
  AddingExternalBusJourneyOfferFailed = 'AddingExternalBusJourneyOfferFailed',
  BadRequest = 'BadRequest',
  FailedToAddLongTermCode = 'FailedToAddLongTermCode',
  FormValidationFailed = 'FormValidationFailed',
  InternalError = 'InternalError',
  InvitedRolesAlreadyExist = 'InvitedRolesAlreadyExist',
  /** Multi ticket does not have enough usages left */
  NotEnoughMultiTicketUsages = 'NotEnoughMultiTicketUsages',
  RecaptchaFailed = 'RecaptchaFailed',
  RemovingExternalBusJourneyOfferFailed = 'RemovingExternalBusJourneyOfferFailed',
  SessionExpired = 'SessionExpired',
  Unauthenticated = 'Unauthenticated',
}

export type ExistingPassengerInput = {
  passengerJourneyId: Scalars['ID']['input'];
};

export type ExternalJourneyLeg = {
  __typename?: 'ExternalJourneyLeg';
  arrivalTime?: Maybe<Scalars['DateTime']['output']>;
  departureTime?: Maybe<Scalars['DateTime']['output']>;
  facilities?: Maybe<Array<Scalars['String']['output']>>;
  fromPlaceId: Scalars['String']['output'];
  fromPlaceName?: Maybe<Scalars['String']['output']>;
  fromStopAreaName?: Maybe<Scalars['String']['output']>;
  fromStopName?: Maybe<Scalars['String']['output']>;
  lineName: Scalars['String']['output'];
  lineOperator: Scalars['String']['output'];
  toPlaceId: Scalars['String']['output'];
  toPlaceName?: Maybe<Scalars['String']['output']>;
  toStopAreaName?: Maybe<Scalars['String']['output']>;
  toStopName?: Maybe<Scalars['String']['output']>;
};

export type ExternalService = {
  __typename?: 'ExternalService';
  busConnectionKey?: Maybe<Scalars['String']['output']>;
  details: ExternalServiceDetails;
  fee?: Maybe<JourneyFee>;
  id: Scalars['ID']['output'];
  /** @deprecated use isExternalBusService */
  isBusConnectionOffer: Scalars['Boolean']['output'];
  isExternalBusService: Scalars['Boolean']['output'];
  price: Scalars['Int']['output'];
  /**
   * Known values:
   * MATKAHUOLTO_TICKET: Bus connection with Matkahuolto
   */
  product: Scalars['String']['output'];
};

export type ExternalServiceDetails = {
  __typename?: 'ExternalServiceDetails';
  bicycle: Scalars['Boolean']['output'];
  externalJourneyLegs?: Maybe<Array<ExternalJourneyLeg>>;
  transferStation?: Maybe<Scalars['String']['output']>;
};

export enum FailedToAddLongTermCodeReason {
  ApplyFailed = 'ApplyFailed',
  DailyLimitReached = 'DailyLimitReached',
  LimitReached = 'LimitReached',
  OverlappingTrip = 'OverlappingTrip',
}

export type FailedToFetchResponse = {
  __typename?: 'FailedToFetchResponse';
  message?: Maybe<Scalars['String']['output']>;
};

export type FinalizePaymentResponse = {
  __typename?: 'FinalizePaymentResponse';
  orderNumber: Scalars['String']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentMethod: PaymentMethodName;
  paymentRefNum?: Maybe<Scalars['String']['output']>;
};

export type FinalizePaymentResponse_V2 = PaymentFinalized | PaymentFinalizedWithInfo;

export enum Floor {
  Downstairs = 'DOWNSTAIRS',
  Upstairs = 'UPSTAIRS',
}

export type FormUploadResponse = {
  __typename?: 'FormUploadResponse';
  status?: Maybe<Scalars['String']['output']>;
};

export enum ForteJwtRole {
  ForteCorporateUser = 'FORTE_CORPORATE_USER',
  FortePlEmployee = 'FORTE_PL_EMPLOYEE',
  ForteTramEmployee = 'FORTE_TRAM_EMPLOYEE',
  ForteTravelAgencyClerk = 'FORTE_TRAVEL_AGENCY_CLERK',
  ForteUser = 'FORTE_USER',
  ForteVrEmployee = 'FORTE_VR_EMPLOYEE',
}

export type Group = {
  __typename?: 'Group';
  state?: Maybe<Scalars['String']['output']>;
  ticketViews?: Maybe<Array<GroupTicketView>>;
};

export type GroupJourney = {
  __typename?: 'GroupJourney';
  additionalProducts?: Maybe<Array<AdditionalProduct>>;
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['String']['output'];
  avecraOrders?: Maybe<LegWithMeals>;
  avecraOrdersPerLeg?: Maybe<Array<LegWithMeals>>;
  cabins: Array<GroupJourneyCabin>;
  /** These are only used for orders */
  cancelStatus: GroupJourneyCancelStatus;
  /** This returns either SalesService corporate offer or Order service CorporateTicket regarding parent type */
  corporate?: Maybe<CorporateOffer>;
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['String']['output'];
  groupId: Scalars['String']['output'];
  hasJourneyStarted: Scalars['Boolean']['output'];
  idWithDepartureStation: Scalars['ID']['output'];
  /** @deprecated Corona is over */
  isCoronaTestRecommended: Scalars['Boolean']['output'];
  masterTicketViewUrl?: Maybe<Scalars['String']['output']>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  passengerJourneys?: Maybe<Array<PassengerJourney>>;
  summary?: Maybe<Summary>;
  trafficType?: Maybe<Scalars['String']['output']>;
  usageEndTime: Scalars['String']['output'];
  usageStartTime: Scalars['String']['output'];
  vehicles?: Maybe<Array<Vehicle>>;
};

export type GroupJourneyCabin = {
  __typename?: 'GroupJourneyCabin';
  attribute?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legKey: Scalars['String']['output'];
  passengers?: Maybe<Array<CabinPassenger>>;
  size?: Maybe<Scalars['Int']['output']>;
  totalPrice: Scalars['Int']['output'];
  type: CabinType;
};

export enum GroupJourneyCancelStatus {
  Cancellable = 'CANCELLABLE',
  JourneyStarted = 'JOURNEY_STARTED',
  NotCancellable = 'NOT_CANCELLABLE',
  SeriesTicketCancellationPeriodPassed = 'SERIES_TICKET_CANCELLATION_PERIOD_PASSED',
  TravelAgentCanCancelRecentPurchase = 'TRAVEL_AGENT_CAN_CANCEL_RECENT_PURCHASE',
}

export type GroupSalesJourneyOption = {
  __typename?: 'GroupSalesJourneyOption';
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['DateTime']['output'];
  availability: OptionAvailability;
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['DateTime']['output'];
  error?: Maybe<NoOfferReason>;
  highestLegTrainFill?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  legs: Array<JourneyOptionLeg>;
  passengers?: Maybe<Array<GroupSalesJourneyOptionPassenger>>;
  totalPrice: Scalars['Int']['output'];
};

export type GroupSalesJourneyOptionPassenger = {
  __typename?: 'GroupSalesJourneyOptionPassenger';
  amount?: Maybe<Scalars['Int']['output']>;
  type: PassengerType;
};

export type GroupSalesPassengerInput = {
  additionalServices?: InputMaybe<Array<AdditionalServiceInput>>;
  age?: InputMaybe<Scalars['Int']['input']>;
  amount: Scalars['Int']['input'];
  key?: InputMaybe<Scalars['ID']['input']>;
  /** Can be used to specify whether child passenger needs its own place; default=true; if set to false then also age must be specified */
  reservationPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  type: PassengerType;
};

export type GroupTicketView = {
  __typename?: 'GroupTicketView';
  arrivalStation?: Maybe<Scalars['String']['output']>;
  departureStation?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type Health = {
  __typename?: 'Health';
  status: Scalars['String']['output'];
  /** Current version of vr-web-backend */
  version: Scalars['String']['output'];
};

export type IncidentMessage = {
  __typename?: 'IncidentMessage';
  body: Scalars['String']['output'];
  created: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  incidentId: Scalars['String']['output'];
  language: Scalars['String']['output'];
  start: Scalars['DateTime']['output'];
  subject: Scalars['String']['output'];
  tag?: Maybe<Scalars['String']['output']>;
};

export enum InvitationLanguage {
  En = 'en',
  Fi = 'fi',
  Sv = 'sv',
}

export type Invoice = {
  __typename?: 'Invoice';
  editable?: Maybe<Scalars['Boolean']['output']>;
  passengers?: Maybe<Array<InvoicePassenger>>;
  singleInvoice?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoiceNotEditable = {
  __typename?: 'InvoiceNotEditable';
  invoiceNotEditable?: Maybe<Scalars['Boolean']['output']>;
};

export type InvoicePassenger = {
  __typename?: 'InvoicePassenger';
  journeyId: Scalars['String']['output'];
  reference?: Maybe<Scalars['String']['output']>;
};

export type InvoicePassengerInput = {
  journeyId: Scalars['String']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
};

export type InvoicePaymentResponse = {
  __typename?: 'InvoicePaymentResponse';
  payment?: Maybe<Payment>;
};

export enum InvoicingInterval {
  Interval_1StOfJanuaryAnd_1StOfJuly = 'INTERVAL_1ST_OF_JANUARY_AND_1ST_OF_JULY',
  Interval_15ThAndLastDayOfMonth = 'INTERVAL_15TH_AND_LAST_DAY_OF_MONTH',
  IntervalEveryDay = 'INTERVAL_EVERY_DAY',
  IntervalEveryFriday = 'INTERVAL_EVERY_FRIDAY',
  IntervalLastDayOfMonth = 'INTERVAL_LAST_DAY_OF_MONTH',
  IntervalNoInvoicing = 'INTERVAL_NO_INVOICING',
}

export enum InvoicingLanguage {
  En = 'en',
  Fi = 'fi',
}

export enum InvoicingMethod {
  Einvoice = 'EINVOICE',
  Email = 'EMAIL',
}

export enum JwtCompanyRole {
  B2BAdmin = 'B2B_ADMIN',
  B2BReportViewer = 'B2B_REPORT_VIEWER',
  ForteCorporateUser = 'FORTE_CORPORATE_USER',
  ForteDiscountCodeManager = 'FORTE_DISCOUNT_CODE_MANAGER',
  ForteTravelAgencyClerk = 'FORTE_TRAVEL_AGENCY_CLERK',
  TravelAgentAdmin = 'TRAVEL_AGENT_ADMIN',
}

export enum JoinType {
  Merging = 'MERGING',
  Splitting = 'SPLITTING',
}

export enum JourneyCancellationPolicy {
  CancellationInsurance = 'CANCELLATION_INSURANCE',
  CancellationInsuranceInOrderButNotInJourney = 'CANCELLATION_INSURANCE_IN_ORDER_BUT_NOT_IN_JOURNEY',
  NotCancellable = 'NOT_CANCELLABLE',
  RefundToMultiticket = 'REFUND_TO_MULTITICKET',
}

export type JourneyCancellationSummary = {
  __typename?: 'JourneyCancellationSummary';
  refundAmount: Scalars['Int']['output'];
  status: CancelJourneyStatus;
};

export enum JourneyDirection {
  Outbound = 'OUTBOUND',
  Return = 'RETURN',
}

export type JourneyFee = {
  __typename?: 'JourneyFee';
  id: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  product?: Maybe<ProductType>;
};

export type JourneyLeg = {
  __typename?: 'JourneyLeg';
  additionalReservationOffers?: Maybe<Array<JourneyLegOffer>>;
  additionalReservationServices?: Maybe<Array<Maybe<PassengerService>>>;
  additionalReservations?: Maybe<Array<Maybe<Reservation>>>;
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['String']['output'];
  avecraOffers: AvecraOffers;
  avecraServices?: Maybe<Array<AvecraService>>;
  bicycles: Array<Bicycle>;
  /** Deprecated, use avecraOffers instead */
  breakfastOffers: BreakfastOffers;
  commercialTrainNumber?: Maybe<Scalars['String']['output']>;
  /** @deprecated will be undefined in multi-leg commuter journeys use train info */
  commuterLineIdentifier?: Maybe<Scalars['String']['output']>;
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  legKey: Scalars['String']['output'];
  /** @deprecated Relevant fields moved to reservation */
  offers: Array<JourneyLegOffer>;
  reservation?: Maybe<Reservation>;
  /** @deprecated Relevant fields moved to reservation */
  services?: Maybe<Array<Maybe<PassengerService>>>;
  ticketViewUrl?: Maybe<Scalars['String']['output']>;
  trafficType?: Maybe<Scalars['String']['output']>;
  trainFillPercentage?: Maybe<Scalars['Int']['output']>;
  trainInfo?: Maybe<TrainInfo>;
  trainNumber?: Maybe<Scalars['String']['output']>;
  trainType?: Maybe<TrainType>;
};

export type JourneyLegOffer = {
  __typename?: 'JourneyLegOffer';
  id: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  product?: Maybe<Scalars['String']['output']>;
  ticketClass: Scalars['String']['output'];
};

export type JourneyOption = {
  __typename?: 'JourneyOption';
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['DateTime']['output'];
  availability: OptionAvailability;
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['DateTime']['output'];
  discount?: Maybe<Discount>;
  error?: Maybe<NoOfferReason>;
  highestLegTrainFill?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  legs: Array<JourneyOptionLeg>;
  passengers: Array<JourneyOptionPassenger>;
  totalPrice: Scalars['Int']['output'];
};

export type JourneyOptionLeg = {
  __typename?: 'JourneyOptionLeg';
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['DateTime']['output'];
  commercialLineIdentifier?: Maybe<Scalars['String']['output']>;
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['DateTime']['output'];
  /** @deprecated Use legKey instead */
  id?: Maybe<Scalars['String']['output']>;
  legKey?: Maybe<Scalars['ID']['output']>;
  productAttributes: Array<ProductAttribute>;
  trainAttributes: Array<Scalars['String']['output']>;
  trainFillPercentage?: Maybe<Scalars['Int']['output']>;
  trainNumber: Scalars['String']['output'];
  trainType: TrainType;
  type: Scalars['String']['output'];
};

export type JourneyOptionPassenger = {
  __typename?: 'JourneyOptionPassenger';
  offers: Array<PassengerOffer>;
  type: PassengerType;
};

export type JourneyPassenger = {
  __typename?: 'JourneyPassenger';
  eligibleForCancellationInsurance: Scalars['Boolean']['output'];
  linkedToPassenger?: Maybe<Scalars['String']['output']>;
  passengerLinkType?: Maybe<PassengerType>;
  type: PassengerType;
};

export enum JourneyPurchaseType {
  RedeemedWithMultiTicket = 'REDEEMED_WITH_MULTI_TICKET',
  RedeemedWithSeasonTicket = 'REDEEMED_WITH_SEASON_TICKET',
  SinglePurchase = 'SINGLE_PURCHASE',
}

/**
 * Milas explanation of the various journey states:
 *
 * confirmed => order just created, it is not returned from Orders /API except for group orders
 * not confirmed => order cancelled before any payment, e.g. cancelled sales session
 * paid => user has paid for the order, but not activated. Should be a temporary situation or it is an. error case, when activation was not done. Usually Forte catches and auto-refund such orders
 * activated => journey has valid, activated tickets
 * used => scanned by conductor
 * cancelled => order/journey was  cancelled, no refund paid
 * refunded => money has been returned
 */
export enum JourneyState {
  Activated = 'ACTIVATED',
  Cancelled = 'CANCELLED',
  Confirmed = 'CONFIRMED',
  NotConfirmed = 'NOT_CONFIRMED',
  Refunded = 'REFUNDED',
  /** State has some unmapped value, you can add it to this enum */
  Unknown = 'UNKNOWN',
  Used = 'USED',
}

export enum LanguageCode {
  En = 'EN',
  Fi = 'FI',
  Sv = 'SV',
}

export type LegExtraInfo = {
  __typename?: 'LegExtraInfo';
  trainInfo?: Maybe<TrainInfo>;
  wagons: Array<AssemblyWagon>;
};

export type LegInput = {
  arrivalStation: Scalars['String']['input'];
  departureStation: Scalars['String']['input'];
  departureTime: Scalars['DateTime']['input'];
  trainNumber: Scalars['String']['input'];
};

export type LegStop = {
  __typename?: 'LegStop';
  /** Arrival time of the train to this stop. Null if the stop is the first stop of the route. */
  arrivalTime?: Maybe<Scalars['DateTime']['output']>;
  /** Departure time of the train from this stop. Null if the stop is the last stop of the route. */
  departureTime?: Maybe<Scalars['DateTime']['output']>;
  station: Scalars['String']['output'];
};

export type LegWithMeals = {
  __typename?: 'LegWithMeals';
  arrivalStation: Scalars['String']['output'];
  departureStation: Scalars['String']['output'];
  meals: Array<Meal>;
};

export type LineItem = {
  __typename?: 'LineItem';
  /** Count of that line item (all items should have same unit price) */
  count: Scalars['Int']['output'];
  /** Other information relevant to the item */
  details?: Maybe<LineItemDetails>;
  product?: Maybe<Scalars['String']['output']>;
  productAttribute?: Maybe<Scalars['String']['output']>;
  productSubItems?: Maybe<Array<LineItemProductSubItem>>;
  /** Enum representing the */
  productType: Scalars['String']['output'];
  serviceProvider?: Maybe<Scalars['String']['output']>;
  subItems?: Maybe<Array<LineItemSubItem>>;
  /** Total price for that line item (i.e. count x unit price) */
  totalPrice: SummaryGrossPrice;
  /** Unit price of each item */
  unitPrice: SummaryGrossPrice;
  values?: Maybe<Values>;
};

export enum LineItemDetails {
  ZeroPriceReasonFreeLeg = 'ZERO_PRICE_REASON_FREE_LEG',
  ZeroPriceReasonFreeOfCharge = 'ZERO_PRICE_REASON_FREE_OF_CHARGE',
  ZeroPriceReasonIncluded = 'ZERO_PRICE_REASON_INCLUDED',
  ZeroPriceReasonIncludedInPrice = 'ZERO_PRICE_REASON_INCLUDED_IN_PRICE',
  ZeroPriceReasonInCabin = 'ZERO_PRICE_REASON_IN_CABIN',
  ZeroPriceReasonInCompartment = 'ZERO_PRICE_REASON_IN_COMPARTMENT',
  ZeroPriceReasonPaidByDiscountCode = 'ZERO_PRICE_REASON_PAID_BY_DISCOUNT_CODE',
  ZeroPriceReasonPaidByMultiTicket = 'ZERO_PRICE_REASON_PAID_BY_MULTI_TICKET',
  ZeroPriceReasonPaidBySeasonTicket = 'ZERO_PRICE_REASON_PAID_BY_SEASON_TICKET',
  ZeroPriceReasonUnknown = 'ZERO_PRICE_REASON_UNKNOWN',
}

export type LineItemProductSubItem = {
  __typename?: 'LineItemProductSubItem';
  count: Scalars['Int']['output'];
  productType: Scalars['String']['output'];
  totalPrice?: Maybe<SummaryGrossPrice>;
  type: LineItemSubItemTypes;
  unitPrice?: Maybe<SummaryGrossPrice>;
  values?: Maybe<Values>;
};

export type LineItemSubItem = {
  __typename?: 'LineItemSubItem';
  count: Scalars['Int']['output'];
  key: Scalars['String']['output'];
  type: LineItemSubItemTypes;
};

export enum LineItemSubItemTypes {
  CabinPassengers = 'CABIN_PASSENGERS',
  CorporateUser = 'CORPORATE_USER',
  Product = 'PRODUCT',
}

export type LoginResponse = {
  __typename?: 'LoginResponse';
  user: User;
};

export type LoginResponseV2 = {
  __typename?: 'LoginResponseV2';
  userCarOwner?: Maybe<Scalars['Boolean']['output']>;
  userCity?: Maybe<Scalars['String']['output']>;
  userEmailPermit?: Maybe<Scalars['Boolean']['output']>;
  userId: Scalars['String']['output'];
  userIdentity: User;
  userLanguage?: Maybe<Scalars['String']['output']>;
};

export type MatakahuoltoDestination = {
  __typename?: 'MatakahuoltoDestination';
  matkahuoltoPlaceId: Scalars['String']['output'];
};

export type Meal = {
  __typename?: 'Meal';
  count: Scalars['Int']['output'];
  drink?: Maybe<Scalars['String']['output']>;
  foodOption?: Maybe<Scalars['String']['output']>;
  passengerLegIds: Array<Scalars['String']['output']>;
  product: Scalars['String']['output'];
};

export type MultiTicket = {
  __typename?: 'MultiTicket';
  arrivalStation: Scalars['String']['output'];
  bookingId: Scalars['ID']['output'];
  departureStation: Scalars['String']['output'];
  orderNumber: Scalars['String']['output'];
  passengerType: PassengerType;
  payments: Array<Payment>;
  price: Scalars['Int']['output'];
  seatClass: SeatClass;
  ticketCount: Scalars['Int']['output'];
  type: MultiTicketType;
  usageEndTime: Scalars['DateTime']['output'];
  usageStartTime: Scalars['DateTime']['output'];
  usagesLeft: Scalars['Int']['output'];
};

export type MultiTicketInput = {
  includeDepleted?: InputMaybe<Scalars['Boolean']['input']>;
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum MultiTicketType {
  Commuter = 'COMMUTER',
  LongDistanceAndCommuter = 'LONG_DISTANCE_AND_COMMUTER',
}

export type MultiTicketUsage = {
  __typename?: 'MultiTicketUsage';
  arrivalStation: Scalars['String']['output'];
  arrivalTime: Scalars['String']['output'];
  departureStation: Scalars['String']['output'];
  departureTime: Scalars['String']['output'];
  /** If details if empty, probably multi ticket has been redeemed by someone else */
  details?: Maybe<MultiTicketUsageDetails>;
  orderNumber: Scalars['ID']['output'];
  passengerJourneyId: Scalars['String']['output'];
};

export type MultiTicketUsageDetails = {
  __typename?: 'MultiTicketUsageDetails';
  additionalProducts: Array<AdditionalProduct>;
  passengerType: PassengerType;
  paymentId?: Maybe<Scalars['String']['output']>;
};

export type MultiTicketUsagesResponse = {
  __typename?: 'MultiTicketUsagesResponse';
  items: Array<MultiTicketUsage>;
  total: Scalars['Int']['output'];
};

export type MultiTickets = {
  __typename?: 'MultiTickets';
  items: Array<MultiTicket>;
};

export type MultiTicketsResponse = FailedToFetchResponse | MultiTickets;

export type Mutation = {
  __typename?: 'Mutation';
  addBicycles: SalesSession;
  addCabinOption?: Maybe<SalesSession>;
  addCancellationInsuranceToOrder: SalesSession;
  addExternalAvecraService?: Maybe<SalesSession>;
  addExternalMatkahuoltoService?: Maybe<SalesSession>;
  addReturnToSale: SalesSession;
  addVehicle: SalesSession;
  addVehicleAndDriverDetails?: Maybe<SalesSession>;
  applyDiscountCodes?: Maybe<SalesSession>;
  cancelJourneys: CancelJourneysResponse;
  changePlaceReservations: SalesSession;
  changeSeats: ChangeSeatsResponse;
  ciamInitLogin: Scalars['String']['output'];
  ciamResetPassword: CiamResetPasswordResponse;
  ciamTokens: CiamCodeExchangeResponse;
  claimOrder?: Maybe<ClaimOrderResponse>;
  claimSale: Scalars['Boolean']['output'];
  codeExchange: CodeExchangeResponse;
  createCompany: CrmCompanyCreate;
  createNewBundleSale: SalesSession;
  /** Sales session mutations */
  createNewSale: SalesSession;
  createTravelCodes: Array<Scalars['String']['output']>;
  deleteCompanyUser: DeleteCompanyUserStatus;
  /** @deprecated This will be removed when finalizePayment_v2 has been released */
  finalizePayment: FinalizePaymentResponse;
  finalizePayment_v2: FinalizePaymentResponse_V2;
  getUploadUrlForAttachment: PresignedUrlResponse;
  inviteCompanyUser: Array<CrmUser>;
  invoicePayment: InvoicePaymentResponse;
  /** Log in the user. If salesSessionId is provided, the sales session ownership is transferred to the user. */
  loginV2: LoginResponseV2;
  logoutV2: Scalars['String']['output'];
  preparePayment: PreparePaymentResponse;
  reCreateSalesSession?: Maybe<SalesSession>;
  releaseSale: Scalars['Boolean']['output'];
  removeCabinOption?: Maybe<SalesSession>;
  removeCancellationInsuranceFromOrder: SalesSession;
  removeExternalService?: Maybe<SalesSession>;
  removeTravelCodes?: Maybe<Scalars['Boolean']['output']>;
  removeVehicleCancellationInsuranceFromOrder: SalesSession;
  replaceJourney: SalesSession;
  submitForm: FormUploadResponse;
  testLogin: LoginResponse;
  updateCabins: UpdateCabinsResponse;
  updateCompany: CrmCompany;
  updateInvoice: UpdateInvoiceResponse;
  updateUserDetails: User;
};

export type MutationAddBicyclesArgs = {
  passengerJourneyIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type MutationAddCabinOptionArgs = {
  cabinUpgradeOptionId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
};

export type MutationAddCancellationInsuranceToOrderArgs = {
  cancellationInsuranceOfferId: Scalars['ID']['input'];
  salesSessionId: Scalars['ID']['input'];
};

export type MutationAddExternalAvecraServiceArgs = {
  groupJourneyIdWithDepartureStation: Scalars['ID']['input'];
  legKey?: InputMaybe<Scalars['String']['input']>;
  passengersWithAvecraProducts: Array<PassengerWithAvecraProductsInput>;
  salesSessionId: Scalars['ID']['input'];
};

export type MutationAddExternalMatkahuoltoServiceArgs = {
  externalServiceOfferOpt: Array<Scalars['String']['input']>;
  offersToRemove?: InputMaybe<Array<Scalars['String']['input']>>;
  salesSessionId: Scalars['ID']['input'];
};

export type MutationAddReturnToSaleArgs = {
  journeyOptionId: Scalars['String']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['String']['input'];
};

export type MutationAddVehicleArgs = {
  saleId: Scalars['ID']['input'];
  vehicles: Array<VehicleInput>;
};

export type MutationAddVehicleAndDriverDetailsArgs = {
  saleId: Scalars['ID']['input'];
  vehicles: Array<VehicleUpdateInput>;
};

export type MutationApplyDiscountCodesArgs = {
  passengerDiscountCodes: Array<PassengerDiscountCodeInput>;
  saleId: Scalars['ID']['input'];
};

export type MutationCancelJourneysArgs = {
  language?: InputMaybe<LanguageCode>;
  orderNumber: Scalars['ID']['input'];
  passengers: Array<Scalars['String']['input']>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  vehicles: Array<Scalars['String']['input']>;
};

export type MutationChangePlaceReservationsArgs = {
  passengerLegs: Array<PassengerLegsInput>;
  saleId: Scalars['ID']['input'];
};

export type MutationChangeSeatsArgs = {
  changeSeatInputs: Array<ChangeSeatInput>;
  legInput: LegInput;
  saleId: Scalars['ID']['input'];
};

export type MutationCiamResetPasswordArgs = {
  language?: InputMaybe<LanguageCode>;
  redirectUri?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCiamTokensArgs = {
  sessionKey: Scalars['String']['input'];
};

export type MutationClaimOrderArgs = {
  orderNumber: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
};

export type MutationClaimSaleArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationCodeExchangeArgs = {
  code: Scalars['String']['input'];
};

export type MutationCreateCompanyArgs = {
  input: CreateCrmCompanyInput;
  reCaptchaResponse: Scalars['String']['input'];
};

export type MutationCreateNewBundleSaleArgs = {
  bundleOfferId: Scalars['String']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateNewSaleArgs = {
  journeyOptionId: Scalars['String']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
};

export type MutationCreateTravelCodesArgs = {
  numberOfCodes: Scalars['Int']['input'];
  travelEndDate: Scalars['Date']['input'];
  travelStartDate: Scalars['Date']['input'];
  usageType: TravelCodeType;
};

export type MutationDeleteCompanyUserArgs = {
  removeSiblings?: InputMaybe<Array<JwtCompanyRole>>;
  userId: Scalars['String']['input'];
};

export type MutationFinalizePaymentArgs = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  language: LanguageCode;
  orderNumber: Scalars['String']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentParameters?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['ID']['input'];
};

export type MutationFinalizePayment_V2Args = {
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  language: LanguageCode;
  orderNumber: Scalars['String']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  paymentParameters?: InputMaybe<Scalars['String']['input']>;
  saleId: Scalars['ID']['input'];
};

export type MutationGetUploadUrlForAttachmentArgs = {
  contentType: Scalars['String']['input'];
  fileName: Scalars['String']['input'];
};

export type MutationInviteCompanyUserArgs = {
  input: UserInvitationInput;
};

export type MutationInvoicePaymentArgs = {
  emails: Array<Scalars['String']['input']>;
  language: LanguageCode;
  passengers?: InputMaybe<Array<InvoicePassengerInput>>;
  saleId: Scalars['ID']['input'];
  scope?: InputMaybe<Array<PriceElementScope>>;
  singleInvoice: Scalars['Boolean']['input'];
};

export type MutationLoginV2Args = {
  password: Scalars['String']['input'];
  salesSessionId?: InputMaybe<Scalars['ID']['input']>;
  username: Scalars['String']['input'];
};

export type MutationLogoutV2Args = {
  redirectUrl: Scalars['String']['input'];
};

export type MutationPreparePaymentArgs = {
  cancelURL: Scalars['String']['input'];
  emails?: InputMaybe<Array<Scalars['String']['input']>>;
  enablePaytrail?: InputMaybe<Scalars['Boolean']['input']>;
  failureURL: Scalars['String']['input'];
  language?: InputMaybe<LanguageCode>;
  paymentMethod: PaymentMethodName;
  returnURL: Scalars['String']['input'];
  saleId: Scalars['ID']['input'];
  scope?: InputMaybe<Array<PriceElementScope>>;
};

export type MutationReCreateSalesSessionArgs = {
  orderNumber: Scalars['ID']['input'];
};

export type MutationReleaseSaleArgs = {
  saleId: Scalars['String']['input'];
};

export type MutationRemoveCabinOptionArgs = {
  cabinIds: Array<Scalars['ID']['input']>;
  legIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type MutationRemoveCancellationInsuranceFromOrderArgs = {
  salesSessionId: Scalars['ID']['input'];
};

export type MutationRemoveExternalServiceArgs = {
  externalServiceIds: Array<Scalars['String']['input']>;
  salesSessionId: Scalars['ID']['input'];
};

export type MutationRemoveTravelCodesArgs = {
  discountCodes: Array<Scalars['String']['input']>;
};

export type MutationRemoveVehicleCancellationInsuranceFromOrderArgs = {
  salesSessionId: Scalars['ID']['input'];
  vehicleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type MutationReplaceJourneyArgs = {
  groupJourneyId?: InputMaybe<Scalars['ID']['input']>;
  journeyOptionId: Scalars['ID']['input'];
  optionKey?: InputMaybe<Scalars['String']['input']>;
  passengerJourneyScope: Array<Scalars['ID']['input']>;
  salesSessionId: Scalars['ID']['input'];
};

export type MutationSubmitFormArgs = {
  form?: InputMaybe<Scalars['JSON']['input']>;
  formSubmitRequestId: Scalars['String']['input'];
  reCaptchaResponse: Scalars['String']['input'];
};

export type MutationTestLoginArgs = {
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};

export type MutationUpdateCabinsArgs = {
  legIds: Array<Scalars['ID']['input']>;
  operations: Array<CabinOperationInput>;
  saleId: Scalars['ID']['input'];
};

export type MutationUpdateCompanyArgs = {
  input: CrmCompanyInput;
};

export type MutationUpdateInvoiceArgs = {
  orderNumber: Scalars['ID']['input'];
  passengers: Array<InvoicePassengerInput>;
};

export type MutationUpdateUserDetailsArgs = {
  input: UpdateUserDetailsInput;
};

export type NoConnections = {
  __typename?: 'NoConnections';
  noConnectionsReason?: Maybe<NoConnectionsReason>;
};

/** Possible reasons causing no journey status */
export enum NoConnectionsReason {
  /** Both stations are situated within the HSL area */
  Hsl = 'HSL',
  NotFound = 'NOT_FOUND',
  /** Multi-ticket does not allow the requested travel */
  WrongMultiTicket = 'WRONG_MULTI_TICKET',
}

export type NoOffer = {
  __typename?: 'NoOffer';
  noOfferReason: NoOfferReason;
};

/** Possible reasons causing a no offer status */
export enum NoOfferReason {
  /** No bicycle spot available */
  BicycleUnavailable = 'BICYCLE_UNAVAILABLE',
  /** Not available for employee */
  NotAvailableForEmployee = 'NOT_AVAILABLE_FOR_EMPLOYEE',
  /** Connection is in the past */
  Past = 'PAST',
  /** No pet seats available */
  PetSeatUnavailable = 'PET_SEAT_UNAVAILABLE',
  /** Offers are sold out */
  SoldOut = 'SOLD_OUT',
  /** No offers available */
  Unavailable = 'UNAVAILABLE',
  /** No wheelchair spot available */
  WheelchairUnavailable = 'WHEELCHAIR_UNAVAILABLE',
}

export enum NoTicketReason {
  MultiTicketDoesNotHaveJourneyTickets = 'MULTI_TICKET_DOES_NOT_HAVE_JOURNEY_TICKETS',
}

export type NotLoggedIn = {
  __typename?: 'NotLoggedIn';
  notLoggedIn: Scalars['Boolean']['output'];
};

export type Offer = {
  __typename?: 'Offer';
  id: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
};

export type OfferOrNot = NoOffer | Offer;

export type OptionAvailability = {
  __typename?: 'OptionAvailability';
  accessibleCabinAvailability: AvailabilityStatus;
  accessibleSeatAvailability: AvailabilityStatus;
  cabinAvailability: AvailabilityStatus;
  petCabinAvailability: AvailabilityStatus;
  petSeatAvailability: AvailabilityStatus;
  seatAvailability_v2: SeatAvailabilityStatus_V2;
};

export type Order = {
  __typename?: 'Order';
  advanceFeePaidUntil?: Maybe<Scalars['String']['output']>;
  /** This has all payment ids including refunded ones */
  allPaymentIds?: Maybe<Array<Scalars['String']['output']>>;
  cancellationSummary: CancellationSummary;
  creator?: Maybe<Creator>;
  finalFeePaidUntil?: Maybe<Scalars['String']['output']>;
  group?: Maybe<Group>;
  groupJourneys: Array<GroupJourney>;
  orderNumber: Scalars['String']['output'];
  owner?: Maybe<Owner>;
  paymentDue?: Maybe<Scalars['Boolean']['output']>;
  paymentIds: Array<Scalars['String']['output']>;
  payments: Array<Payment>;
  price?: Maybe<Price>;
  /** This is available ONLY if the order has been fetched using orderNumber and referenceNumber */
  refundEligibility?: Maybe<RefundEligibility>;
  seriesTickets: Array<SeriesTicket>;
  state?: Maybe<Scalars['String']['output']>;
  validUntil: Scalars['String']['output'];
};

export type OrderCancellationSummaryArgs = {
  input?: InputMaybe<CancellationSummaryInput>;
};

export type OrderHistoryEvent = {
  __typename?: 'OrderHistoryEvent';
  changes: Array<OrderHistoryEventChange>;
  id: Scalars['ID']['output'];
  timestamp?: Maybe<Scalars['DateTime']['output']>;
  touchpoint: Touchpoint;
  travelAgentId?: Maybe<Scalars['String']['output']>;
};

export type OrderHistoryEventChange = {
  __typename?: 'OrderHistoryEventChange';
  count?: Maybe<Scalars['Int']['output']>;
  type: OrderHistoryEventChangeType;
};

export enum OrderHistoryEventChangeType {
  AddedServicesChange = 'ADDED_SERVICES_CHANGE',
  AdvanceFeePaid = 'ADVANCE_FEE_PAID',
  DepartureChange = 'DEPARTURE_CHANGE',
  FinalPaymentPaid = 'FINAL_PAYMENT_PAID',
  PassengerAddition = 'PASSENGER_ADDITION',
  PassengerRemoval = 'PASSENGER_REMOVAL',
  ReservationChanged = 'RESERVATION_CHANGED',
  SeatChange = 'SEAT_CHANGE',
  TicketBought = 'TICKET_BOUGHT',
  TripCancelled = 'TRIP_CANCELLED',
  WholePaymentPaid = 'WHOLE_PAYMENT_PAID',
}

export type OrderInput = {
  groupJourneyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  includeCancelled?: InputMaybe<Scalars['Boolean']['input']>;
  orderNumber: Scalars['String']['input'];
  /** deprecated, not supported anymore */
  passengerJourneyId?: InputMaybe<Array<Scalars['String']['input']>>;
  /** deprecated, not supported anymore */
  passengerJourneyIds?: InputMaybe<Array<Scalars['String']['input']>>;
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
};

export type OrderSummaries = {
  __typename?: 'OrderSummaries';
  nextToken?: Maybe<Scalars['String']['output']>;
  orderSummaries: Array<OrderSummary>;
};

export type OrderSummary = {
  __typename?: 'OrderSummary';
  additionalServices: Array<OrderSummaryService>;
  bundleTicketDetails?: Maybe<BundleDetails>;
  journeys: Array<OrderSummaryJourney>;
  journeysV2: Array<OrderSummaryJourneyV2>;
  lastUsageFrom?: Maybe<Scalars['String']['output']>;
  oboCompany?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  state?: Maybe<JourneyState>;
  ticketType: TicketType;
};

export type OrderSummaryJourney = {
  __typename?: 'OrderSummaryJourney';
  arrivalStation?: Maybe<Scalars['String']['output']>;
  arrivalTime?: Maybe<Scalars['String']['output']>;
  departureStation?: Maybe<Scalars['String']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  firstPassengerJourneyId?: Maybe<Scalars['String']['output']>;
  numberOfPassengers: Scalars['Int']['output'];
  passengerJourneyId?: Maybe<Scalars['String']['output']>;
  passengerType?: Maybe<PassengerType>;
  state?: Maybe<OrderSummaryJourneyState>;
  trafficType?: Maybe<ProductCategory>;
};

export enum OrderSummaryJourneyState {
  Cancelled = 'CANCELLED',
  NotCancelled = 'NOT_CANCELLED',
}

export type OrderSummaryJourneyV2 = {
  __typename?: 'OrderSummaryJourneyV2';
  arrivalStation?: Maybe<Scalars['String']['output']>;
  arrivalTime?: Maybe<Scalars['String']['output']>;
  departureStation?: Maybe<Scalars['String']['output']>;
  departureTime?: Maybe<Scalars['String']['output']>;
  passengerJourneyId?: Maybe<Scalars['String']['output']>;
  passengerType?: Maybe<PassengerType>;
  state?: Maybe<Scalars['String']['output']>;
  trafficType?: Maybe<ProductCategory>;
};

export type OrderSummaryService = {
  __typename?: 'OrderSummaryService';
  amount: Scalars['Int']['output'];
  passengerJourneyId: Scalars['String']['output'];
  serviceType: OrderSummaryServiceType;
  state: Scalars['String']['output'];
};

export enum OrderSummaryServiceType {
  Bicycle = 'BICYCLE',
  Bike = 'BIKE',
  Motorcycle = 'MOTORCYCLE',
  Pet = 'PET',
  Unknown = 'UNKNOWN',
  Vehicle = 'VEHICLE',
  Wheelchair = 'WHEELCHAIR',
}

export type Owner = {
  __typename?: 'Owner';
  companyId?: Maybe<Scalars['String']['output']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  limit: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type PaginationInput = {
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
};

export type PassengerDiscountCodeInput = {
  discountCode: Scalars['String']['input'];
  passengerJourneyId: Scalars['ID']['input'];
};

export type PassengerInput = {
  /** Optional ID to link assistant with their main passenger */
  assistantLink?: InputMaybe<Scalars['ID']['input']>;
  bicycle?: InputMaybe<BicycleInput>;
  key?: InputMaybe<Scalars['ID']['input']>;
  pet?: InputMaybe<PetInput>;
  type: PassengerType;
  vehicles?: InputMaybe<Array<InputMaybe<VehicleInput>>>;
  wheelchair?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PassengerJourney = {
  __typename?: 'PassengerJourney';
  bicycleFees?: Maybe<Array<JourneyFee>>;
  cancellationInsurance?: Maybe<CancellationInsurance>;
  cancellationInsuranceOffer?: Maybe<CancellationInsurance>;
  discountCodes?: Maybe<Array<DiscountCode>>;
  durationMinutes?: Maybe<Scalars['Int']['output']>;
  /** Only sales sessions have these */
  externalServiceOffers?: Maybe<Array<ExternalService>>;
  /** Only orders have these */
  externalServices?: Maybe<Array<ExternalService>>;
  id: Scalars['ID']['output'];
  legs: Array<JourneyLeg>;
  otherFees?: Maybe<Array<JourneyFee>>;
  passenger?: Maybe<JourneyPassenger>;
  petFees?: Maybe<Array<JourneyFee>>;
  seasonTicketId?: Maybe<Scalars['String']['output']>;
  seriesTicketId?: Maybe<Scalars['String']['output']>;
  /** State is available in orders */
  state?: Maybe<JourneyState>;
  travelPassTicketId?: Maybe<Scalars['String']['output']>;
};

export type PassengerLegsInput = {
  legId: Scalars['String']['input'];
  offerId: Scalars['String']['input'];
};

export type PassengerOffer = {
  __typename?: 'PassengerOffer';
  discountCategory?: Maybe<Scalars['String']['output']>;
  legId?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  product: Scalars['String']['output'];
};

export type PassengerService = {
  __typename?: 'PassengerService';
  attribute?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  placePrice?: Maybe<Scalars['Int']['output']>;
  placeType?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  ticketClass?: Maybe<Scalars['String']['output']>;
  totalPrice?: Maybe<Scalars['Int']['output']>;
  validUntil?: Maybe<Scalars['String']['output']>;
};

/** The possible passenger types. */
export enum PassengerType {
  Adult = 'ADULT',
  Assistant = 'ASSISTANT',
  Child = 'CHILD',
  Conscript = 'CONSCRIPT',
  /** @deprecated This value is used only in matkalla, use FDF_CONTRACT */
  ConscriptContract = 'CONSCRIPT_CONTRACT',
  FdfContract = 'FDF_CONTRACT',
  Fip_50Card = 'FIP_50_CARD',
  FipFreeCoupon = 'FIP_FREE_COUPON',
  InterrailEurail_1St = 'INTERRAIL_EURAIL_1ST',
  InterrailEurail_2Nd = 'INTERRAIL_EURAIL_2ND',
  Pensioner = 'PENSIONER',
  PlEmployee = 'PL_EMPLOYEE',
  Replacement = 'REPLACEMENT',
  SpecialFreePass = 'SPECIAL_FREE_PASS',
  Student = 'STUDENT',
  TramEmployee = 'TRAM_EMPLOYEE',
  VrEmployee = 'VR_EMPLOYEE',
  VrEmployeeFamilyMember = 'VR_EMPLOYEE_FAMILY_MEMBER',
  VrPensioner = 'VR_PENSIONER',
}

export type PassengerWithAvecraProductsInput = {
  passengerLegId: Scalars['ID']['input'];
  products: Array<AvecraProductInput>;
};

export type Payment = {
  __typename?: 'Payment';
  invoice?: Maybe<Invoice>;
  orderNumber?: Maybe<Scalars['String']['output']>;
  paymentId: Scalars['ID']['output'];
  state: PurchaseState;
};

export type PaymentFinalized = {
  __typename?: 'PaymentFinalized';
  success: Scalars['Boolean']['output'];
};

export type PaymentFinalizedWithInfo = {
  __typename?: 'PaymentFinalizedWithInfo';
  emails?: Maybe<Array<Scalars['String']['output']>>;
  orderNumber: Scalars['String']['output'];
  paymentId?: Maybe<Scalars['String']['output']>;
  paymentMethod: PaymentMethodName;
  paymentRefNum?: Maybe<Scalars['String']['output']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID']['output'];
  status: PaymentMethodStatus;
  type: PaymentMethodType;
};

export enum PaymentMethodName {
  Aktia = 'AKTIA',
  Alandsbanken = 'ALANDSBANKEN',
  Card = 'CARD',
  Danske = 'DANSKE',
  Mobilepay = 'MOBILEPAY',
  None = 'NONE',
  Nordea = 'NORDEA',
  Omasaasto = 'OMASAASTO',
  Osuus = 'OSUUS',
  Pop = 'POP',
  Saasto = 'SAASTO',
  Siirto = 'SIIRTO',
  Spankki = 'SPANKKI',
  Vipps = 'VIPPS',
}

export enum PaymentMethodStatus {
  Critical = 'CRITICAL',
  Stable = 'STABLE',
  Unknown = 'UNKNOWN',
  Warning = 'WARNING',
}

export enum PaymentMethodType {
  Cards = 'CARDS',
  Invoice = 'INVOICE',
  MobilePay = 'MOBILE_PAY',
  NetBank = 'NET_BANK',
  Unknown = 'UNKNOWN',
}

export type PaymentStatus = {
  __typename?: 'PaymentStatus';
  name: PaymentMethodName;
  status: PaymentMethodStatus;
};

export type PaymentStatusesResponse = {
  __typename?: 'PaymentStatusesResponse';
  invoicingAllowed: Scalars['Boolean']['output'];
  statuses: Array<PaymentStatus>;
};

export enum PaymentTerm {
  Z00N = 'Z00N',
  Z07N = 'Z07N',
  Z14N = 'Z14N',
  Z21N = 'Z21N',
  Z30N = 'Z30N',
  Z35N = 'Z35N',
  Z45N = 'Z45N',
  Z60N = 'Z60N',
  Z90N = 'Z90N',
}

export enum PaymentVerifyState {
  Authorized = 'AUTHORIZED',
  Cancelled = 'CANCELLED',
  NotFound = 'NOT_FOUND',
  NotPaid = 'NOT_PAID',
  Paid = 'PAID',
  Processed = 'PROCESSED',
  Reserved = 'RESERVED',
  Unclear = 'UNCLEAR',
}

export type PetInput = {
  amount: Scalars['NaturalNumber']['input'];
  /** Indicates if pets require large space */
  large?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum PlaceType {
  Bed = 'BED',
  CabinBed = 'CABIN_BED',
  CabinSeat = 'CABIN_SEAT',
  Seat = 'SEAT',
}

export type PreparePaymentResponse = {
  __typename?: 'PreparePaymentResponse';
  paymentId?: Maybe<Scalars['String']['output']>;
  redirectBody?: Maybe<Scalars['JSONObject']['output']>;
  redirectMethod?: Maybe<RedirectMethod>;
  redirectURL: Scalars['String']['output'];
};

export type PresignedUrlResponse = {
  __typename?: 'PresignedUrlResponse';
  headers: Scalars['JSON']['output'];
  key: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export type Price = {
  __typename?: 'Price';
  amount: Scalars['Int']['output'];
  elements?: Maybe<Array<Maybe<PriceElement>>>;
};

export type PriceCalendarResponse = Calendar | NoOffer;

export type PriceElement = {
  __typename?: 'PriceElement';
  amount: Scalars['Int']['output'];
  payUntil?: Maybe<Scalars['Date']['output']>;
  status: PriceElementPaymentStatus;
  type?: Maybe<PriceElementScope>;
  unpaidAmount: Scalars['Int']['output'];
};

export enum PriceElementPaymentStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID',
}

export enum PriceElementScope {
  AdvanceFee = 'ADVANCE_FEE',
  Avecra = 'AVECRA',
  Journey = 'JOURNEY',
}

export type Product = {
  __typename?: 'Product';
  amount?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Scalars['String']['output']>;
};

export type ProductAttribute = {
  __typename?: 'ProductAttribute';
  attribute?: Maybe<Scalars['String']['output']>;
  availability: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum ProductCategory {
  Commuter = 'COMMUTER',
  International = 'INTERNATIONAL',
  LongDistance = 'LONG_DISTANCE',
}

export enum ProductName {
  AdjacentSeatEco = 'ADJACENT_SEAT_ECO',
  AdjacentSeatExtra = 'ADJACENT_SEAT_EXTRA',
  AdjacentSeatRestaurantWagon = 'ADJACENT_SEAT_RESTAURANT_WAGON',
  Bed = 'BED',
  BerthCabin = 'BERTH_CABIN',
  BerthCabinCemt = 'BERTH_CABIN_CEMT',
  BerthCabinEdm = 'BERTH_CABIN_EDM',
  BerthCabinWithShower = 'BERTH_CABIN_WITH_SHOWER',
  BerthInCabinfor3Trainsales = 'BERTH_IN_CABINFOR3_TRAINSALES',
  BerthWithoutShowerTrainsales = 'BERTH_WITHOUT_SHOWER_TRAINSALES',
  BerthWithShower = 'BERTH_WITH_SHOWER',
  BerthWithShowerTrainsales = 'BERTH_WITH_SHOWER_TRAINSALES',
  Bicycle = 'BICYCLE',
  BicycleAllotment = 'BICYCLE_ALLOTMENT',
  BrioToyTrain = 'BRIO_TOY_TRAIN',
  BrioToyTrainPersonnel = 'BRIO_TOY_TRAIN_PERSONNEL',
  CancellationInsuranceJourney = 'CANCELLATION_INSURANCE_JOURNEY',
  CancellationInsuranceVehicle = 'CANCELLATION_INSURANCE_VEHICLE',
  ChangeAndCancellation = 'CHANGE_AND_CANCELLATION',
  ChangeFeeJourney = 'CHANGE_FEE_JOURNEY',
  ChangeFeeVehicle = 'CHANGE_FEE_VEHICLE',
  CleaningFee = 'CLEANING_FEE',
  Coffee = 'COFFEE',
  EcoClassSeat = 'ECO_CLASS_SEAT',
  Extralongvehicle = 'EXTRALONGVEHICLE',
  ExtraClassSeat = 'EXTRA_CLASS_SEAT',
  FaceMask = 'FACE_MASK',
  FaceMaskPersonnel = 'FACE_MASK_PERSONNEL',
  HandSanitizer = 'HAND_SANITIZER',
  HandSanitizerPersonnel = 'HAND_SANITIZER_PERSONNEL',
  Longvehicle = 'LONGVEHICLE',
  LuggageFee = 'LUGGAGE_FEE',
  MatkahuoltoServiceFee = 'MATKAHUOLTO_SERVICE_FEE',
  MatkahuoltoTicket = 'MATKAHUOLTO_TICKET',
  Motorcycle = 'MOTORCYCLE',
  PersonalAssistant = 'PERSONAL_ASSISTANT',
  Pet = 'PET',
  SeatCabin = 'SEAT_CABIN',
  SeatInFamilyCabin = 'SEAT_IN_FAMILY_CABIN',
  SeatInNegotiationCabin = 'SEAT_IN_NEGOTIATION_CABIN',
  SeatInWorkingCabin = 'SEAT_IN_WORKING_CABIN',
  SeatUpstairsRestaurantWagon = 'SEAT_UPSTAIRS_RESTAURANT_WAGON',
  SingleSeatInFamilyCabin = 'SINGLE_SEAT_IN_FAMILY_CABIN',
  SingleSeatInWorkingCabin = 'SINGLE_SEAT_IN_WORKING_CABIN',
  TakeAway = 'TAKE_AWAY',
  TrainFee = 'TRAIN_FEE',
  Vehicle = 'VEHICLE',
  WagonMap = 'WAGON_MAP',
  Wheelchair = 'WHEELCHAIR',
}

/** Additional service product names. */
export enum ProductType {
  Bicycle = 'BICYCLE',
  CancellationInsuranceJourney = 'CANCELLATION_INSURANCE_JOURNEY',
  CancellationInsuranceVehicle = 'CANCELLATION_INSURANCE_VEHICLE',
  ChangeFeeJourney = 'CHANGE_FEE_JOURNEY',
  FlexibilityFee = 'FLEXIBILITY_FEE',
  Pet = 'PET',
  Wheelchair = 'WHEELCHAIR',
}

export type Profile = {
  __typename?: 'Profile';
  companyRoles?: Maybe<Array<CompanyWithRole>>;
};

export enum PurchaseState {
  Cancelled = 'CANCELLED',
  /** when PAID purchase contains CHANGE_FEE_JOURNEY item */
  Changed = 'CHANGED',
  Created = 'CREATED',
  Moved = 'MOVED',
  Paid = 'PAID',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Unknown = 'UNKNOWN',
}

export enum PurchaseType {
  ChangeFee = 'CHANGE_FEE',
  FoodPreOrder = 'FOOD_PRE_ORDER',
  MultiTicket = 'MULTI_TICKET',
  OneWayTrip = 'ONE_WAY_TRIP',
  RefundTransaction = 'REFUND_TRANSACTION',
  RoundTrip = 'ROUND_TRIP',
  SeasonTicket = 'SEASON_TICKET',
  TravelPassTicket = 'TRAVEL_PASS_TICKET',
}

export type Query = {
  __typename?: 'Query';
  avecraProductAvailability: Array<AvecraProductsPerLeg>;
  businessInfo: BusinessInfo;
  /** Fetch upcoming journeys which have some cancellation insurance */
  cancellableJourneys: Array<GroupJourney>;
  cancellationInsuranceOffer: CancellationInsuranceOfferResponse;
  cancellationSummary_v2: CancellationSummary;
  commercialTrains: Array<CommercialTrain>;
  company?: Maybe<Company>;
  createBusJourneyOffers: Array<BusJourneyOfferGroup>;
  createCabinOptions: Array<BedCabin>;
  createCabinWagonmapOffers: Array<WagonMapNightCabinOffer>;
  /** @deprecated Use createCorporateProductOffers_v2 instead. This can be removed when createCorporateProductOffers_v2 has been published to the production */
  createCorporateProductOffers: Array<Maybe<CorporateProductOption>>;
  createCorporateProductOffers_v2: Array<Maybe<CorporateProductOption>>;
  createSeatChangeOffers: Array<SeatChangeOffer>;
  createSeriesBundleOffers: CreateSeriesBundleOffersResponse;
  crmCompany: CrmCompany;
  crmUsers: Array<CrmUser>;
  generalIncidents: Array<IncidentMessage>;
  health: Health;
  legExtraInfo: LegExtraInfo;
  /** Returns multiticket matching to orderNumber */
  multiTicket: MultiTicket;
  multiTicketUsages: MultiTicketUsagesResponse;
  order?: Maybe<Order>;
  orderHistory: Array<OrderHistoryEvent>;
  orders?: Maybe<OrderSummaries>;
  paymentStatuses: PaymentStatusesResponse;
  /** Get best daily prices for a given time period and journey criteria. */
  priceCalendar?: Maybe<PriceCalendarResponse>;
  profiles: Profile;
  reportEmbedInfo: ReportEmbedInfo;
  reports: Array<ReportInfo>;
  salesSession?: Maybe<SalesSession>;
  /** single ticket search */
  searchJourney: Array<JourneyOption>;
  /** group sales search */
  searchJourneyForGroup: Array<GroupSalesJourneyOption>;
  /** Returns a list of connections that can replace the current */
  searchReplacementJourney: Array<JourneyOption>;
  station: Station;
  stations: Array<Station>;
  tempPaymentState: PaymentVerifyState;
  trafficDisruptions_v2: Array<TrafficDisruption>;
  trainIncidents: Array<TrainIncidentMessage>;
  unpaidGroupOrders?: Maybe<UnpaidGroupOrders>;
  user: User;
  userStatus: UserResponse;
  vehicleCancellationInsuranceOffer: CancellationInsuranceOfferResponse;
  wagonMapDataV3?: Maybe<WagonMapDataV2>;
};

export type QueryAvecraProductAvailabilityArgs = {
  passengerLegIds: Array<Scalars['String']['input']>;
};

export type QueryBusinessInfoArgs = {
  businessId: Scalars['String']['input'];
};

export type QueryCancellableJourneysArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryCancellationInsuranceOfferArgs = {
  salesSessionId: Scalars['ID']['input'];
};

export type QueryCancellationSummary_V2Args = {
  groupId: Scalars['ID']['input'];
  orderNumber: Scalars['ID']['input'];
  passengerJourneyIds: Array<Scalars['String']['input']>;
  vehicleIds: Array<Scalars['String']['input']>;
};

export type QueryCommercialTrainsArgs = {
  fromStation: Scalars['String']['input'];
  toStation: Scalars['String']['input'];
  trainNumber: Scalars['String']['input'];
  travelDate: Scalars['String']['input'];
};

export type QueryCompanyArgs = {
  businessId: Scalars['String']['input'];
};

export type QueryCreateBusJourneyOffersArgs = {
  minTransferTime?: InputMaybe<Scalars['Int']['input']>;
  passengerJourneyIds: Array<Scalars['ID']['input']>;
  placeId: Scalars['ID']['input'];
};

export type QueryCreateCabinOptionsArgs = {
  legIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type QueryCreateCabinWagonmapOffersArgs = {
  legIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type QueryCreateCorporateProductOffersArgs = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  passengerJourneyIds: Array<Scalars['ID']['input']>;
  passengerLegIds: Array<Scalars['ID']['input']>;
  saleId: Scalars['ID']['input'];
};

export type QueryCreateCorporateProductOffers_V2Args = {
  groupJourneyId: Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
};

export type QueryCreateSeatChangeOffersArgs = {
  attributes?: InputMaybe<Array<Scalars['String']['input']>>;
  includeAllSingleTypes?: InputMaybe<Scalars['Boolean']['input']>;
  legIds: Array<Scalars['ID']['input']>;
  respectProductAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  returnAllOptions?: InputMaybe<Scalars['Boolean']['input']>;
  saleId: Scalars['ID']['input'];
};

export type QueryCreateSeriesBundleOffersArgs = {
  arrivalStation: Scalars['String']['input'];
  departureStation: Scalars['String']['input'];
  passengerType: PassengerType;
};

export type QueryCrmUsersArgs = {
  input: CrmUsersInput;
};

export type QueryGeneralIncidentsArgs = {
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryLegExtraInfoArgs = {
  departureTime: Scalars['String']['input'];
  fromStation: Scalars['String']['input'];
  toStation: Scalars['String']['input'];
  trainNumber: Scalars['String']['input'];
};

export type QueryMultiTicketArgs = {
  bookingId?: InputMaybe<Scalars['ID']['input']>;
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
  orderNumber?: InputMaybe<Scalars['ID']['input']>;
};

export type QueryMultiTicketUsagesArgs = {
  bookingId: Scalars['String']['input'];
  page?: InputMaybe<Scalars['Int']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryOrderArgs = {
  input: OrderInput;
};

export type QueryOrderHistoryArgs = {
  journeyKey?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['String']['input'];
};

export type QueryOrdersArgs = {
  bookingStates?: InputMaybe<Array<BookingState>>;
  journeyArrivalTimeEnd?: InputMaybe<Scalars['String']['input']>;
  journeyArrivalTimeStart?: InputMaybe<Scalars['String']['input']>;
  journeyDepartureTimeEnd?: InputMaybe<Scalars['String']['input']>;
  journeyDepartureTimeStart?: InputMaybe<Scalars['String']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  numberOfResults?: InputMaybe<Scalars['Int']['input']>;
  oboCompany?: InputMaybe<Scalars['String']['input']>;
  sortingDirection?: InputMaybe<Sorting>;
  ticketTypes?: InputMaybe<Array<TicketInputType>>;
  travelAgentId?: InputMaybe<Scalars['String']['input']>;
};

export type QueryPriceCalendarArgs = {
  arrivalStation: Scalars['String']['input'];
  departureStation: Scalars['String']['input'];
  endDate: Scalars['Date']['input'];
  passengers: Array<PassengerInput>;
  placeTypes: Array<PlaceType>;
  startDate: Scalars['Date']['input'];
};

export type QueryProfilesArgs = {
  customerId: Scalars['ID']['input'];
};

export type QueryReportEmbedInfoArgs = {
  groupId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
};

export type QuerySalesSessionArgs = {
  id: Scalars['ID']['input'];
};

export type QuerySearchJourneyArgs = {
  arrivalStation: Scalars['String']['input'];
  bundleId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
  departureDateTime: Scalars['DateTime']['input'];
  departureStation: Scalars['String']['input'];
  filters?: Array<InputMaybe<ConnectionFilter>>;
  passengers: Array<PassengerInput>;
  placeTypes: Array<PlaceType>;
};

export type QuerySearchJourneyForGroupArgs = {
  arrivalStation: Scalars['String']['input'];
  departureDateTime: Scalars['DateTime']['input'];
  departureStation: Scalars['String']['input'];
  passengers: Array<GroupSalesPassengerInput>;
  placeType: PlaceType;
};

export type QuerySearchReplacementJourneyArgs = {
  departureDateTime: Scalars['DateTime']['input'];
  filters?: Array<InputMaybe<ConnectionFilter>>;
  passengers: Array<ExistingPassengerInput>;
  saleId: Scalars['ID']['input'];
};

export type QueryStationArgs = {
  abbreviation: Scalars['String']['input'];
};

export type QueryTempPaymentStateArgs = {
  paymentId: Scalars['String']['input'];
};

export type QueryTrafficDisruptions_V2Args = {
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryTrainIncidentsArgs = {
  departureDate: Scalars['Date']['input'];
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
};

export type QueryUnpaidGroupOrdersArgs = {
  pagination: PaginationInput;
};

export type QueryVehicleCancellationInsuranceOfferArgs = {
  salesSessionId: Scalars['ID']['input'];
  vehicleIds?: InputMaybe<Array<Scalars['ID']['input']>>;
};

export type QueryWagonMapDataV3Args = {
  legKey: Scalars['String']['input'];
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  saleId?: InputMaybe<Scalars['String']['input']>;
};

export type Receipt = {
  __typename?: 'Receipt';
  grossAmount: Scalars['Int']['output'];
  taxAmount: Scalars['Int']['output'];
};

export enum RedirectMethod {
  Get = 'GET',
  Post = 'POST',
}

export type RefundEligibility = {
  __typename?: 'RefundEligibility';
  eligibleForRefundWithCancellationInsurance: Scalars['Boolean']['output'];
  nonEligibilityReason?: Maybe<Scalars['String']['output']>;
};

export type ReportEmbedInfo = {
  __typename?: 'ReportEmbedInfo';
  accessToken: Scalars['String']['output'];
  embedUrl: Scalars['String']['output'];
  expiration: Scalars['DateTime']['output'];
};

export type ReportInfo = {
  __typename?: 'ReportInfo';
  groupId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type Reservation = {
  __typename?: 'Reservation';
  attribute?: Maybe<Scalars['String']['output']>;
  cabinId?: Maybe<Scalars['ID']['output']>;
  compartmentNumber?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  floor?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  placeNumber?: Maybe<Scalars['String']['output']>;
  /** Place reservation price in cents, when place reservation is needed */
  placePrice?: Maybe<Scalars['Int']['output']>;
  position?: Maybe<SeatPosition>;
  /** Travel right price in cents */
  price?: Maybe<Scalars['Int']['output']>;
  product?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  services: Array<Service>;
  ticketClass?: Maybe<SeatClass>;
  wagonNumber?: Maybe<Scalars['String']['output']>;
};

export enum Role {
  FortePlEmployee = 'FORTE_PL_EMPLOYEE',
  ForteTramEmployee = 'FORTE_TRAM_EMPLOYEE',
  ForteVrEmployee = 'FORTE_VR_EMPLOYEE',
}

export type S3BucketWriteCredentials = {
  __typename?: 'S3BucketWriteCredentials';
  s3: S3Response;
  sessionId: Scalars['ID']['output'];
};

export type S3Credentials = {
  __typename?: 'S3Credentials';
  AccessKeyId: Scalars['String']['output'];
  Expiration: Scalars['String']['output'];
  SecretAccessKey: Scalars['String']['output'];
  SessionToken: Scalars['String']['output'];
};

export type S3Response = {
  __typename?: 'S3Response';
  bucketName: Scalars['String']['output'];
  credentials: S3Credentials;
  keyPrefix: Scalars['String']['output'];
  kmsKeyId: Scalars['ID']['output'];
};

export type SalesSession = {
  __typename?: 'SalesSession';
  amountToPay?: Maybe<Scalars['Int']['output']>;
  externalServicePaymentStartDeadline?: Maybe<Scalars['String']['output']>;
  groupJourneys: Array<GroupJourney>;
  id: Scalars['ID']['output'];
  /** This is defined if travel agent has bought this trip for some other company. Value is the business id. */
  onBehalfOf?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  price?: Maybe<SalesSessionPrice>;
  seriesTicket?: Maybe<SeriesTicket>;
  sessionSummary?: Maybe<Summary>;
  validUntil?: Maybe<Scalars['String']['output']>;
};

export type SalesSessionPrice = {
  __typename?: 'SalesSessionPrice';
  amount: Scalars['Int']['output'];
  elements?: Maybe<Array<Maybe<PriceElement>>>;
};

export enum SeatAvailabilityStatus_V2 {
  EcoClassSeat = 'ECO_CLASS_SEAT',
  /** Seats are not booked in commuter trains */
  NotBookable = 'NOT_BOOKABLE',
  /** Train has no seat places */
  NoSeats = 'NO_SEATS',
  NoSeatsLeft = 'NO_SEATS_LEFT',
  OnlyEkstraSeatLeft = 'ONLY_EKSTRA_SEAT_LEFT',
  OnlySeatsWithAdditionalFeeLeft = 'ONLY_SEATS_WITH_ADDITIONAL_FEE_LEFT',
  OnlyUpstairsRestaurantWagonSeatLeft = 'ONLY_UPSTAIRS_RESTAURANT_WAGON_SEAT_LEFT',
}

export type SeatChangeOffer = {
  __typename?: 'SeatChangeOffer';
  additionalReservationOffers: Array<AdditionalReservationOffer>;
  offers: Array<SeatReservationOffer>;
  passengerLegId: Scalars['ID']['output'];
};

export enum SeatClass {
  BerthCabin = 'BERTH_CABIN',
  BerthCabinCemt = 'BERTH_CABIN_CEMT',
  BerthCabinEdm = 'BERTH_CABIN_EDM',
  BerthCabinWithShower = 'BERTH_CABIN_WITH_SHOWER',
  Eco = 'ECO',
  Extra = 'EXTRA',
  SeatCabin = 'SEAT_CABIN',
  SeatInFamilyCabin = 'SEAT_IN_FAMILY_CABIN',
  SeatInNegotiationCabin = 'SEAT_IN_NEGOTIATION_CABIN',
  SeatInWorkingCabin = 'SEAT_IN_WORKING_CABIN',
  UpstairsRestaurantWagon = 'UPSTAIRS_RESTAURANT_WAGON',
}

export enum SeatPosition {
  Aisle = 'AISLE',
  Bed = 'BED',
  Middle = 'MIDDLE',
  Window = 'WINDOW',
}

export enum SeatProduct {
  EcoClassSeat = 'ECO_CLASS_SEAT',
  ExtraClassSeat = 'EXTRA_CLASS_SEAT',
  SeatUpstairsRestaurantWagon = 'SEAT_UPSTAIRS_RESTAURANT_WAGON',
  SingleExtraClassSeat = 'SINGLE_EXTRA_CLASS_SEAT',
  SingleSeatEco = 'SINGLE_SEAT_ECO',
  SingleSeatUpstairsRestaurantWagon = 'SINGLE_SEAT_UPSTAIRS_RESTAURANT_WAGON',
}

export type SeatReservationOffer = {
  __typename?: 'SeatReservationOffer';
  attribute?: Maybe<Scalars['String']['output']>;
  commercialTrainNumber?: Maybe<Scalars['String']['output']>;
  discount?: Maybe<Discount>;
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  product: SeatProduct;
  totalPrice?: Maybe<Scalars['Int']['output']>;
};

export enum SeatType {
  Bed = 'BED',
  Seat = 'SEAT',
}

export type SeriesBundleOffer = {
  __typename?: 'SeriesBundleOffer';
  discountPercentage?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  originalPrice?: Maybe<Scalars['Int']['output']>;
  periodLengthDays: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  seatClass: SeatClass;
  taxRatePercentage?: Maybe<Scalars['Int']['output']>;
  ticketCount: Scalars['Int']['output'];
  type: MultiTicketType;
  usageEndTime?: Maybe<Scalars['Date']['output']>;
  usageStartTime?: Maybe<Scalars['Date']['output']>;
};

export type SeriesBundleOffers = {
  __typename?: 'SeriesBundleOffers';
  offers: Array<SeriesBundleOffer>;
};

export type SeriesTicket = {
  __typename?: 'SeriesTicket';
  arrivalStation?: Maybe<Scalars['String']['output']>;
  bookingId: Scalars['ID']['output'];
  departureStation?: Maybe<Scalars['String']['output']>;
  orderNumber: Scalars['String']['output'];
  passengerJourneyId: Scalars['String']['output'];
  passengerType: PassengerType;
  periodLengthDays: Scalars['Int']['output'];
  price: Scalars['Int']['output'];
  /** @deprecated not available under orders use departureStation/arrivalStation instead. */
  stations: Array<Scalars['String']['output']>;
  ticketClass: Scalars['String']['output'];
  ticketCount: Scalars['Int']['output'];
  trafficType: Scalars['String']['output'];
  type: Scalars['String']['output'];
  usageEndTime: Scalars['Date']['output'];
  usageStartTime: Scalars['Date']['output'];
  validUntil: Scalars['DateTime']['output'];
};

export enum Service {
  Accessible = 'ACCESSIBLE',
  Allergy = 'ALLERGY',
  Bicycle = 'BICYCLE',
  ConferenceCompartment = 'CONFERENCE_COMPARTMENT',
  DuettoPlus = 'DUETTO_PLUS',
  Ekstra = 'EKSTRA',
  FamilyCompartment = 'FAMILY_COMPARTMENT',
  LargePet = 'LARGE_PET',
  LimitedReservability = 'LIMITED_RESERVABILITY',
  MinibistroTrolley = 'MINIBISTRO_TROLLEY',
  Opposite = 'OPPOSITE',
  Pet = 'PET',
  PetCoach = 'PET_COACH',
  PlayArea = 'PLAY_AREA',
  PowerSocket = 'POWER_SOCKET',
  Restaurant = 'RESTAURANT',
  SeatCompartment = 'SEAT_COMPARTMENT',
  Shower = 'SHOWER',
  SingleSeat = 'SINGLE_SEAT',
  SleepingCabin = 'SLEEPING_CABIN',
  WheelchairPlace = 'WHEELCHAIR_PLACE',
  WorkingCompartment = 'WORKING_COMPARTMENT',
}

export enum Sorting {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Station = {
  __typename?: 'Station';
  abbreviation: Scalars['String']['output'];
  countryCodeUic: Scalars['Int']['output'];
  hslStation: Scalars['Boolean']['output'];
  id: Scalars['Int']['output'];
  matkahuoltoDestinations?: Maybe<Array<MatakahuoltoDestination>>;
  matkahuoltoPlaceId?: Maybe<Scalars['String']['output']>;
  names: StationNames;
  timetableRows: Array<Timetable>;
  type?: Maybe<StationType>;
  vehicleConnections: Array<Scalars['String']['output']>;
  vehicleLoadingStation: Scalars['Boolean']['output'];
};

export type StationTimetableRowsArgs = {
  direction: Direction;
};

export type StationNames = {
  __typename?: 'StationNames';
  en: Scalars['String']['output'];
  fi: Scalars['String']['output'];
  sv: Scalars['String']['output'];
};

export type StationTimePair = {
  __typename?: 'StationTimePair';
  station: Scalars['String']['output'];
  time: Scalars['DateTime']['output'];
};

export enum StationType {
  BusStation = 'BUS_STATION',
  Platform = 'PLATFORM',
  ServiceStation = 'SERVICE_STATION',
  Station = 'STATION',
}

export type Summary = {
  __typename?: 'Summary';
  breakdown: Array<LineItem>;
  total: SummaryGrossPrice;
};

export type SummaryGrossPrice = {
  __typename?: 'SummaryGrossPrice';
  /** Price with VAT */
  grossAmount: Scalars['Int']['output'];
};

export type TicketHistory = {
  __typename?: 'TicketHistory';
  journeys: Array<GroupJourney>;
  orderNumber: Scalars['String']['output'];
};

export enum TicketInputType {
  Group = 'GROUP',
  Season = 'SEASON',
  Series = 'SERIES',
  Single = 'SINGLE',
  TravelPass = 'TRAVEL_PASS',
}

export enum TicketType {
  Corporate = 'CORPORATE',
  Group = 'GROUP',
  Season = 'SEASON',
  Series = 'SERIES',
  Single = 'SINGLE',
  TravelPass = 'TRAVEL_PASS',
}

export type Timetable = {
  __typename?: 'Timetable';
  /** actual time is either liveEstimateTime if available or actualTime */
  actualTime?: Maybe<Scalars['DateTime']['output']>;
  cancelled?: Maybe<Scalars['Boolean']['output']>;
  commercialTrack?: Maybe<Scalars['String']['output']>;
  commuterLineID?: Maybe<Scalars['String']['output']>;
  departureDate: Scalars['Date']['output'];
  differenceInMinutes?: Maybe<Scalars['Int']['output']>;
  runningCurrently: Scalars['Boolean']['output'];
  scheduledTime: Scalars['DateTime']['output'];
  stationShortCode: Scalars['String']['output'];
  trainCategory: Scalars['String']['output'];
  trainNumber?: Maybe<Scalars['String']['output']>;
  trainType?: Maybe<Scalars['String']['output']>;
};

export enum Touchpoint {
  Callcenter = 'CALLCENTER',
  Mobile = 'MOBILE',
  Unknown = 'UNKNOWN',
  Vr = 'VR',
  VrWeb = 'VR_WEB',
}

export type TrafficDisruption = {
  __typename?: 'TrafficDisruption';
  body?: Maybe<Scalars['String']['output']>;
  created: Scalars['DateTime']['output'];
  dates: Array<Scalars['Date']['output']>;
  id: Scalars['ID']['output'];
  incidentId: Scalars['String']['output'];
  language: Scalars['String']['output'];
  modified: Scalars['DateTime']['output'];
  subject?: Maybe<Scalars['String']['output']>;
  tag?: Maybe<Scalars['String']['output']>;
};

export type Train = {
  __typename?: 'Train';
  id?: Maybe<Scalars['String']['output']>;
  /** trainNumber or commercialLineId, or undefined if neither is available */
  label?: Maybe<Scalars['String']['output']>;
  type: TrainType;
};

export type TrainIncidentMessage = {
  __typename?: 'TrainIncidentMessage';
  incidentMessages: Array<IncidentMessage>;
  trainNumber: Scalars['String']['output'];
};

export type TrainInfo = {
  __typename?: 'TrainInfo';
  availability: OptionAvailability;
  commercialLineId?: Maybe<Scalars['String']['output']>;
  productAvailability: Array<ProductAttribute>;
  rampServiceAvailable: Scalars['Boolean']['output'];
  route: Array<TrainStop>;
  services: Array<Scalars['String']['output']>;
  trainNumber: Scalars['String']['output'];
  trainType: TrainType;
  wheelchairAccessible: Scalars['Boolean']['output'];
};

export type TrainStop = {
  __typename?: 'TrainStop';
  arrivalTime: Scalars['String']['output'];
  departureTime: Scalars['String']['output'];
  station: Scalars['String']['output'];
};

/** Train types as listed in [Timetable API Docs] */
export enum TrainType {
  /** Allegro */
  Ae = 'AE',
  Bhs = 'BHS',
  /** Commuter replacement buses */
  Blv = 'BLV',
  /** Bus connection (Bussiyhteys) */
  Bus = 'BUS',
  /** Pohjolanliikenne Express */
  E = 'E',
  /** Pohjolanliikenne Instant (Pika) */
  F = 'F',
  /** Compact Train (Taajamajuna) */
  H = 'H',
  /** Regional train. replaces H trains after 17.3.2024. */
  Hdm = 'HDM',
  /** HLA busses are additional capacity to HDM trains. These are sold once HDM is sold out. */
  Hla = 'HLA',
  /** InterCity */
  Ic = 'IC',
  /** InterCity2 */
  Ic2 = 'IC2',
  /** JLA trains are replacement busses similar to KLA. */
  Jla = 'JLA',
  /** Bus connection (Bussiyhteys) */
  Kla = 'KLA',
  /** Walk (Kävely) */
  Kvl = 'KVL',
  /** Shuttle bus (Lentokenttäbussi) */
  Lkb = 'LKB',
  /** Local train (Paikallisjuna) LOC */
  Loc = 'LOC',
  /** Local train (Paikallisjuna) LOL */
  Lol = 'LOL',
  /** Through train (Pikajuna) */
  P = 'P',
  /** Pohjolanliikenne */
  Pl = 'PL',
  /** Long-distance train, similar to P train */
  Pyo = 'PYO',
  /** Pendolino */
  S = 'S',
  /** Taxi [ unused ] */
  Tax = 'TAX',
  /** Tolstoi */
  Tp = 'TP',
  /** Unknown */
  Unknown = 'UNKNOWN',
  /** Pohjolanliikenne Standard */
  V = 'V',
}

export enum TravelCodeType {
  MultiUse = 'MULTI_USE',
  SingleUse = 'SINGLE_USE',
}

export type UnpaidGroupOrders = {
  __typename?: 'UnpaidGroupOrders';
  items: Array<Order>;
  pagination: PaginationInfo;
};

export type UnpaidGroupOrdersResponse = FailedToFetchResponse | UnpaidGroupOrders;

export enum UpdateCabinsErrorCode {
  /** Fetching of Sales session data or offers or bookings failed at Forte. */
  ServiceError = 'SERVICE_ERROR',
  /** Actual primary update operation failed. */
  UpdateFailed = 'UPDATE_FAILED',
}

export type UpdateCabinsErrorResponse = {
  __typename?: 'UpdateCabinsErrorResponse';
  code: UpdateCabinsErrorCode;
};

export type UpdateCabinsResponse = SalesSession | UpdateCabinsErrorResponse;

export type UpdateInvoiceResponse = InvoiceNotEditable | Order;

export type UpdateUserDetailsInput = {
  carHeight?: InputMaybe<Scalars['Int']['input']>;
  carLength?: InputMaybe<Scalars['Int']['input']>;
  carModel?: InputMaybe<Scalars['String']['input']>;
  carRegisterNumber?: InputMaybe<Scalars['String']['input']>;
  carWeight?: InputMaybe<Scalars['Int']['input']>;
  carWidth?: InputMaybe<Scalars['Int']['input']>;
  cityName?: InputMaybe<Scalars['String']['input']>;
  consentB2BMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  consentCustomerResearch?: InputMaybe<Scalars['Boolean']['input']>;
  consentMarketing?: InputMaybe<Scalars['Boolean']['input']>;
  countryCode?: InputMaybe<Scalars['String']['input']>;
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['Int']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  mobilePhoneNumber?: InputMaybe<Scalars['String']['input']>;
  otherPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  serviceLanguage?: InputMaybe<LanguageCode>;
  streetName?: InputMaybe<Scalars['String']['input']>;
};

/** All the seat upgrades that can be done when upgrading all the passengers */
export enum UpgradeChangeSeatType {
  EcoSeat = 'ECO_SEAT',
  EcoSeatAssistant = 'ECO_SEAT_ASSISTANT',
  ExtraSeat = 'EXTRA_SEAT',
  ExtraSeatAssistant = 'EXTRA_SEAT_ASSISTANT',
  OppositeEcoSeat = 'OPPOSITE_ECO_SEAT',
  OppositeExtraSeat = 'OPPOSITE_EXTRA_SEAT',
  SeatCabin = 'SEAT_CABIN',
  SeatInFamilyCabin = 'SEAT_IN_FAMILY_CABIN',
  SeatInNegotiationCabin = 'SEAT_IN_NEGOTIATION_CABIN',
  SeatInWorkingCabin = 'SEAT_IN_WORKING_CABIN',
  UpstairsRestaurantWagon = 'UPSTAIRS_RESTAURANT_WAGON',
  UpstairsRestaurantWagonAssistant = 'UPSTAIRS_RESTAURANT_WAGON_ASSISTANT',
}

export type User = {
  __typename?: 'User';
  companyIds?: Maybe<Array<Scalars['String']['output']>>;
  companyRoles?: Maybe<Array<CompanyWithRole>>;
  customerId: Scalars['ID']['output'];
  customerRoles: Array<ForteJwtRole>;
  details?: Maybe<UserDetails>;
  firstName?: Maybe<Scalars['String']['output']>;
  info: UserInfo;
  isAuthenticated: Scalars['Boolean']['output'];
  isCiamUser: Scalars['Boolean']['output'];
  isConsumer: Scalars['Boolean']['output'];
  isCorporate: Scalars['Boolean']['output'];
  isEmployee: Scalars['Boolean']['output'];
  isPlEmployee: Scalars['Boolean']['output'];
  isTramEmployee: Scalars['Boolean']['output'];
  isTravelAgent: Scalars['Boolean']['output'];
  isVrEmployee: Scalars['Boolean']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  /** @deprecated use companyRoles/customerRoles instead  */
  roles: Array<Role>;
  /** Fault resistant query fields */
  ticketHistory: Array<TicketHistory>;
};

export type UserDetails = ConsumerUserDetails | CorporateUserDetails | EmployeeUserDetails;

export type UserInfo = {
  activeCompanyId?: Maybe<Scalars['String']['output']>;
  activeCompanyName?: Maybe<Scalars['String']['output']>;
  activeEmail?: Maybe<Scalars['String']['output']>;
  customerId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  mobilePhone?: Maybe<Scalars['String']['output']>;
};

export type UserInvitationInput = {
  emailAddress: Scalars['String']['input'];
  invitationLanguage?: InputMaybe<InvitationLanguage>;
  roles: Array<JwtCompanyRole>;
};

export type UserResponse = NotLoggedIn | User;

export enum UserStatus {
  Active = 'active',
  Pending = 'pending',
}

export type Values = {
  __typename?: 'Values';
  foodSelection1?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  hslZones?: Maybe<Scalars['String']['output']>;
  passengerType?: Maybe<PassengerType>;
  periodLengthDays?: Maybe<Scalars['Int']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  supplementalService?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  usagesCount?: Maybe<Scalars['Int']['output']>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  cancellationInsurance?: Maybe<CancellationInsurance>;
  cancellationInsuranceOffer?: Maybe<CancellationInsurance>;
  id: Scalars['ID']['output'];
  journeyId: Scalars['ID']['output'];
  passengerJourneyId: Scalars['ID']['output'];
  price: Scalars['Int']['output'];
  vehicleDetails: VehicleDetails;
  vehicleLoadingDetails: VehicleLoadingDetails;
};

export type VehicleCancellationSummary = {
  __typename?: 'VehicleCancellationSummary';
  refundAmount: Scalars['Int']['output'];
  status: CancelVehicleStatus;
};

export type VehicleDetails = {
  __typename?: 'VehicleDetails';
  height?: Maybe<Scalars['NaturalNumber']['output']>;
  length?: Maybe<Scalars['NaturalNumber']['output']>;
  manufacturer?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  registerNumber?: Maybe<Scalars['String']['output']>;
  vehicleType: VehicleType;
};

export type VehicleInput = {
  height?: InputMaybe<Scalars['NaturalNumber']['input']>;
  insuranceRequired?: InputMaybe<Scalars['Boolean']['input']>;
  length?: InputMaybe<Scalars['NaturalNumber']['input']>;
  passengerJourneyId?: InputMaybe<Scalars['ID']['input']>;
  vehicleType: VehicleType;
};

export type VehicleLoadingDetails = {
  __typename?: 'VehicleLoadingDetails';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  loadedByVR: Scalars['Boolean']['output'];
  phoneNumber?: Maybe<Scalars['String']['output']>;
  vehicleLoadingEnd?: Maybe<Scalars['String']['output']>;
  vehicleLoadingStart?: Maybe<Scalars['String']['output']>;
};

export enum VehicleType {
  Car = 'CAR',
  Motorcycle = 'MOTORCYCLE',
}

export type VehicleUpdateInput = {
  firstName: Scalars['String']['input'];
  id: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  loadedByVR: Scalars['Boolean']['input'];
  manufacturer: Scalars['String']['input'];
  model: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  registerNumber: Scalars['String']['input'];
};

export type WagonMapDataV2 = {
  __typename?: 'WagonMapDataV2';
  coaches?: Maybe<Scalars['JSON']['output']>;
  commercialTrainInfo?: Maybe<Scalars['JSON']['output']>;
};

export type WagonMapNightCabinOffer = {
  __typename?: 'WagonMapNightCabinOffer';
  attribute?: Maybe<Scalars['String']['output']>;
  commercialTrainNumber?: Maybe<Scalars['String']['output']>;
  discountCategory?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  type: CabinType;
};

export type OptionAvailabilityFragment = {
  __typename?: 'OptionAvailability';
  seatAvailability_v2: SeatAvailabilityStatus_V2;
  accessibleSeatAvailability: AvailabilityStatus;
  petSeatAvailability: AvailabilityStatus;
  cabinAvailability: AvailabilityStatus;
  petCabinAvailability: AvailabilityStatus;
  accessibleCabinAvailability: AvailabilityStatus;
};

export type DiscountCodeFragment = {
  __typename?: 'DiscountCode';
  type: string;
  usageType: string;
  travelStartDate: string;
  travelEndDate: string;
};

export type CorporateOfferFragment = {
  __typename?: 'CorporateOffer';
  type?: string | null;
  placeType?: string | null;
  totalPrice?: number | null;
  nonRefundableAmount?: number | null;
  canCancelBeforeDeparture?: boolean | null;
  changeFreeOfCharge?: boolean | null;
};

export type ExternalJourneyLegFragment = {
  __typename?: 'ExternalJourneyLeg';
  fromPlaceId: string;
  fromPlaceName?: string | null;
  fromStopName?: string | null;
  fromStopAreaName?: string | null;
  toPlaceId: string;
  toPlaceName?: string | null;
  toStopName?: string | null;
  toStopAreaName?: string | null;
  departureTime?: string | null;
  arrivalTime?: string | null;
  lineName: string;
  lineOperator: string;
  facilities?: Array<string> | null;
};

export type ExternalServiceDetailsFragment = {
  __typename?: 'ExternalServiceDetails';
  bicycle: boolean;
  externalJourneyLegs?: Array<
    { __typename?: 'ExternalJourneyLeg' } & ExternalJourneyLegFragment
  > | null;
};

export type ExternalServiceFragment = {
  __typename?: 'ExternalService';
  id: string;
  isExternalBusService: boolean;
  busConnectionKey?: string | null;
  price: number;
  product: string;
  fee?: { __typename?: 'JourneyFee'; price: number } | null;
  details: { __typename?: 'ExternalServiceDetails' } & ExternalServiceDetailsFragment;
};

type UserDetails_ConsumerUserDetails_Fragment = {
  __typename?: 'ConsumerUserDetails';
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  gender?: number | null;
  postalCode?: string | null;
  countryCode?: string | null;
  mobilePhoneNumber?: string | null;
  otherPhoneNumber?: string | null;
  emailAddress: string;
  consentMarketing?: boolean | null;
  consentB2BMarketing?: boolean | null;
  consentCustomerResearch?: boolean | null;
  serviceLanguage: LanguageCode;
  car: {
    __typename?: 'Car';
    model?: string | null;
    registerNumber?: string | null;
    height?: number | null;
    length?: number | null;
    width?: number | null;
  };
};

type UserDetails_CorporateUserDetails_Fragment = {
  __typename?: 'CorporateUserDetails';
  firstName: string;
  lastName: string;
  emailAddress: string;
};

type UserDetails_EmployeeUserDetails_Fragment = {
  __typename?: 'EmployeeUserDetails';
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone?: string | null;
  employeeId: string;
  department: string;
  alias: string;
};

export type UserDetailsFragment =
  | UserDetails_ConsumerUserDetails_Fragment
  | UserDetails_CorporateUserDetails_Fragment
  | UserDetails_EmployeeUserDetails_Fragment;

export type UserFragment = {
  __typename?: 'User';
  customerId: string;
  isConsumer: boolean;
  isCorporate: boolean;
  isEmployee: boolean;
  isTravelAgent: boolean;
  isCiamUser: boolean;
  roles: Array<Role>;
  details?:
    | ({ __typename?: 'ConsumerUserDetails' } & UserDetails_ConsumerUserDetails_Fragment)
    | ({ __typename?: 'CorporateUserDetails' } & UserDetails_CorporateUserDetails_Fragment)
    | ({ __typename?: 'EmployeeUserDetails' } & UserDetails_EmployeeUserDetails_Fragment)
    | null;
};

export type UserIdentityFragment = {
  __typename?: 'User';
  customerId: string;
  isAuthenticated: boolean;
  isConsumer: boolean;
  isCorporate: boolean;
  isEmployee: boolean;
  isVrEmployee: boolean;
  isCiamUser: boolean;
  firstName?: string | null;
  lastName?: string | null;
  customerRoles: Array<ForteJwtRole>;
  isTramEmployee: boolean;
  isPlEmployee: boolean;
  companyRoles?: Array<{
    __typename?: 'CompanyWithRole';
    name: string;
    name2?: string | null;
    roles: Array<{
      __typename?: 'CompanyRole';
      id: string;
      role: JwtCompanyRole;
      canPurchase?: boolean | null;
    }>;
  }> | null;
};

type CancellationInsuranceOffer_CancellationInsuranceOffer_Fragment = {
  __typename?: 'CancellationInsuranceOffer';
  id: string;
  price: number;
  coverage?: {
    __typename?: 'CancellationInsuranceCoverage';
    outbound: CancellationInsuranceCoverageEnum;
    return?: CancellationInsuranceCoverageEnum | null;
  } | null;
};

type CancellationInsuranceOffer_NoOffer_Fragment = {
  __typename?: 'NoOffer';
  noOfferReason: NoOfferReason;
};

export type CancellationInsuranceOfferFragment =
  | CancellationInsuranceOffer_CancellationInsuranceOffer_Fragment
  | CancellationInsuranceOffer_NoOffer_Fragment;

export type CancellationInsuranceFragment = {
  __typename?: 'CancellationInsurance';
  id: string;
  price?: number | null;
  totalPrice?: number | null;
};

export type VehicleFragment = {
  __typename?: 'Vehicle';
  id: string;
  passengerJourneyId: string;
  vehicleDetails: {
    __typename?: 'VehicleDetails';
    height?: number | null;
    length?: number | null;
    vehicleType: VehicleType;
    registerNumber?: string | null;
    manufacturer?: string | null;
    model?: string | null;
  };
  vehicleLoadingDetails: {
    __typename?: 'VehicleLoadingDetails';
    loadedByVR: boolean;
    vehicleLoadingStart?: string | null;
    vehicleLoadingEnd?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    phoneNumber?: string | null;
  };
  cancellationInsuranceOffer?:
    | ({ __typename?: 'CancellationInsurance' } & CancellationInsuranceFragment)
    | null;
};

export type AvecraServicesFragment = {
  __typename?: 'AvecraService';
  avecraGroupId: string;
  purchaseType: string;
  services: Array<{
    __typename?: 'AvecraExternalServiceLeg';
    id: string;
    product?: string | null;
    supplementalServices?: Array<string> | null;
    foodSelection1?: Array<string> | null;
    price?: number | null;
    state?: string | null;
  }>;
};

export type AvecraOrdersFragment = {
  __typename?: 'LegWithMeals';
  departureStation: string;
  arrivalStation: string;
  meals: Array<{
    __typename?: 'Meal';
    count: number;
    drink?: string | null;
    product: string;
    foodOption?: string | null;
    passengerLegIds: Array<string>;
  }>;
};

export type LineItemProductSubItemFragment = {
  __typename?: 'LineItemProductSubItem';
  count: number;
  productType: string;
  unitPrice?: { __typename?: 'SummaryGrossPrice'; grossAmount: number } | null;
  values?: { __typename?: 'Values'; passengerType?: PassengerType | null } | null;
};

export type LineItemFragment = {
  __typename?: 'LineItem';
  productType: string;
  productAttribute?: string | null;
  product?: string | null;
  serviceProvider?: string | null;
  count: number;
  details?: LineItemDetails | null;
  unitPrice: { __typename?: 'SummaryGrossPrice'; grossAmount: number };
  totalPrice: { __typename?: 'SummaryGrossPrice'; grossAmount: number };
  subItems?: Array<{
    __typename?: 'LineItemSubItem';
    type: LineItemSubItemTypes;
    count: number;
    key: string;
  }> | null;
  productSubItems?: Array<
    { __typename?: 'LineItemProductSubItem' } & LineItemProductSubItemFragment
  > | null;
  values?: {
    __typename?: 'Values';
    supplementalService?: string | null;
    foodSelection1?: string | null;
    passengerType?: PassengerType | null;
    usagesCount?: number | null;
    productName?: string | null;
    from?: string | null;
    to?: string | null;
  } | null;
};

export type SummaryFragment = {
  __typename?: 'Summary';
  total: { __typename?: 'SummaryGrossPrice'; grossAmount: number };
  breakdown: Array<{ __typename?: 'LineItem' } & LineItemFragment>;
};

export type JourneyFeeFragment = {
  __typename?: 'JourneyFee';
  id: string;
  price: number;
  product?: ProductType | null;
};

export type TrainInfoFragment = {
  __typename?: 'TrainInfo';
  wheelchairAccessible: boolean;
  rampServiceAvailable: boolean;
  services: Array<string>;
  trainNumber: string;
  trainType: TrainType;
  commercialLineId?: string | null;
  availability: { __typename?: 'OptionAvailability' } & OptionAvailabilityFragment;
  route: Array<{
    __typename?: 'TrainStop';
    station: string;
    arrivalTime: string;
    departureTime: string;
  }>;
  productAvailability: Array<{
    __typename?: 'ProductAttribute';
    name: string;
    attribute?: string | null;
    availability: number;
  }>;
};

export type LegReservationFragment = {
  __typename?: 'Reservation';
  id: string;
  placeNumber?: string | null;
  wagonNumber?: string | null;
  compartmentNumber?: string | null;
  position?: SeatPosition | null;
  attribute?: string | null;
  floor?: number | null;
  cabinId?: string | null;
  services: Array<Service>;
  ticketClass?: SeatClass | null;
  product?: string | null;
  price?: number | null;
  reference?: string | null;
  placePrice?: number | null;
  discount?: {
    __typename?: 'Discount';
    discountPercentage: number;
    ids?: Array<string> | null;
    codes?: Array<{
      __typename?: 'DiscountCode';
      type: string;
      usageType: string;
      travelStartDate: string;
      travelEndDate: string;
    }> | null;
  } | null;
};

export type AvecraProductFragment = {
  __typename?: 'AvecraProduct';
  product: string;
  price: number;
};

export type AvecraOffersFragment = {
  __typename?: 'AvecraOffers';
  count: number;
  products: Array<{ __typename?: 'AvecraProduct' } & AvecraProductFragment>;
};

export type AvecraProductsPerLegFragment = {
  __typename?: 'AvecraProductsPerLeg';
  legId: string;
  productAvailability: { __typename?: 'AvecraOffers' } & AvecraOffersFragment;
};

export type OrderJourneyLegFragment = {
  __typename?: 'JourneyLeg';
  id: string;
  legKey: string;
  arrivalStation: string;
  arrivalTime: string;
  departureStation: string;
  departureTime: string;
  trainNumber?: string | null;
  trainType?: TrainType | null;
  ticketViewUrl?: string | null;
  commercialTrainNumber?: string | null;
  reservation?: ({ __typename?: 'Reservation' } & LegReservationFragment) | null;
  additionalReservations?: Array<
    ({ __typename?: 'Reservation' } & LegReservationFragment) | null
  > | null;
  avecraServices?: Array<{ __typename?: 'AvecraService' } & AvecraServicesFragment> | null;
  bicycles: Array<{ __typename?: 'Bicycle'; wagonNumber?: number | null }>;
  trainInfo?: ({ __typename?: 'TrainInfo' } & TrainInfoFragment) | null;
};

export type OrderPassengerJourneyFragment = {
  __typename?: 'PassengerJourney';
  id: string;
  seriesTicketId?: string | null;
  seasonTicketId?: string | null;
  travelPassTicketId?: string | null;
  state?: JourneyState | null;
  passenger?: {
    __typename?: 'JourneyPassenger';
    type: PassengerType;
    eligibleForCancellationInsurance: boolean;
    linkedToPassenger?: string | null;
  } | null;
  otherFees?: Array<{
    __typename?: 'JourneyFee';
    id: string;
    product?: ProductType | null;
    price: number;
  }> | null;
  petFees?: Array<{ __typename?: 'JourneyFee'; id: string; price: number }> | null;
  bicycleFees?: Array<{ __typename?: 'JourneyFee'; id: string; price: number }> | null;
  cancellationInsurance?: {
    __typename?: 'CancellationInsurance';
    id: string;
    validUntil?: string | null;
  } | null;
  legs: Array<{ __typename?: 'JourneyLeg' } & OrderJourneyLegFragment>;
  externalServices?: Array<{ __typename?: 'ExternalService' } & ExternalServiceFragment> | null;
  discountCodes?: Array<{ __typename?: 'DiscountCode' } & DiscountCodeFragment> | null;
};

export type OrderGroupJourneyFragment = {
  __typename?: 'GroupJourney';
  groupId: string;
  idWithDepartureStation: string;
  departureStation: string;
  departureTime: string;
  arrivalStation: string;
  arrivalTime: string;
  usageEndTime: string;
  usageStartTime: string;
  trafficType?: string | null;
  hasJourneyStarted: boolean;
  cancelStatus: GroupJourneyCancelStatus;
  masterTicketViewUrl?: string | null;
  corporate?: ({ __typename?: 'CorporateOffer' } & CorporateOfferFragment) | null;
  cabins: Array<{
    __typename?: 'GroupJourneyCabin';
    id: string;
    legKey: string;
    type: CabinType;
    attribute?: string | null;
    size?: number | null;
    totalPrice: number;
    passengers?: Array<{
      __typename?: 'CabinPassenger';
      passengerLegId: string;
      type: string;
      reservation?: {
        __typename?: 'CabinReservation';
        id: string;
        placeNumber: string;
        wagonNumber: string;
        compartmentNumber: string;
        position: string;
        attribute?: string | null;
        floor: number;
        validUntil?: string | null;
        cabinId: string;
      } | null;
    }> | null;
  }>;
  vehicles?: Array<{
    __typename?: 'Vehicle';
    id: string;
    passengerJourneyId: string;
    vehicleDetails: {
      __typename?: 'VehicleDetails';
      height?: number | null;
      length?: number | null;
      registerNumber?: string | null;
      manufacturer?: string | null;
      model?: string | null;
      vehicleType: VehicleType;
    };
    cancellationInsurance?: {
      __typename?: 'CancellationInsurance';
      id: string;
      validUntil?: string | null;
    } | null;
    vehicleLoadingDetails: {
      __typename?: 'VehicleLoadingDetails';
      firstName?: string | null;
      lastName?: string | null;
      phoneNumber?: string | null;
      loadedByVR: boolean;
      vehicleLoadingStart?: string | null;
      vehicleLoadingEnd?: string | null;
    };
  }> | null;
  passengerJourneys?: Array<
    { __typename?: 'PassengerJourney' } & OrderPassengerJourneyFragment
  > | null;
  summary?: {
    __typename?: 'Summary';
    breakdown: Array<{
      __typename?: 'LineItem';
      product?: string | null;
      productType: string;
      count: number;
    }>;
  } | null;
  additionalProducts?: Array<{
    __typename?: 'AdditionalProduct';
    type?: ProductType | null;
    count: number;
  }> | null;
  avecraOrders?: ({ __typename?: 'LegWithMeals' } & AvecraOrdersFragment) | null;
  avecraOrdersPerLeg?: Array<{ __typename?: 'LegWithMeals' } & AvecraOrdersFragment> | null;
};

export type PaymentFragment = {
  __typename?: 'Payment';
  paymentId: string;
  state: PurchaseState;
  invoice?: {
    __typename?: 'Invoice';
    singleInvoice?: boolean | null;
    editable?: boolean | null;
    passengers?: Array<{
      __typename?: 'InvoicePassenger';
      journeyId: string;
      reference?: string | null;
    }> | null;
  } | null;
};

export type PriceElementFragment = {
  __typename?: 'PriceElement';
  type?: PriceElementScope | null;
  amount: number;
  unpaidAmount: number;
  status: PriceElementPaymentStatus;
  payUntil?: string | null;
};

export type GroupFragment = { __typename?: 'Group'; state?: string | null };

export type OrderSeriesTicketFragment = { __typename?: 'SeriesTicket'; bookingId: string };

export type CancellationSummaryFragment = {
  __typename?: 'CancellationSummary';
  status: CancelStatus;
  refundAmount: number;
  nonRefundableAmount?: number | null;
  bookingCancellationSummaryList?: Array<{
    __typename?: 'CancellationSummaryListItem';
    amount?: number | null;
    cost?: number | null;
    amountToRefund?: number | null;
    name?: string | null;
  }> | null;
};

export type OrderFragment = {
  __typename?: 'Order';
  orderNumber: string;
  paymentIds: Array<string>;
  groupJourneys: Array<{ __typename?: 'GroupJourney' } & OrderGroupJourneyFragment>;
  cancellationSummary: { __typename?: 'CancellationSummary' } & CancellationSummaryFragment;
  refundEligibility?: {
    __typename?: 'RefundEligibility';
    eligibleForRefundWithCancellationInsurance: boolean;
    nonEligibilityReason?: string | null;
  } | null;
  payments: Array<{ __typename?: 'Payment' } & PaymentFragment>;
  price?: {
    __typename?: 'Price';
    amount: number;
    elements?: Array<({ __typename?: 'PriceElement' } & PriceElementFragment) | null> | null;
  } | null;
  group?: ({ __typename?: 'Group' } & GroupFragment) | null;
  seriesTickets: Array<{ __typename?: 'SeriesTicket' } & OrderSeriesTicketFragment>;
  owner?: { __typename?: 'Owner'; companyId?: string | null } | null;
  creator?: { __typename?: 'Creator'; companyType?: string | null } | null;
};

export type TrafficDisruptionFragment = {
  __typename?: 'TrafficDisruption';
  id: string;
  incidentId: string;
  subject?: string | null;
  body?: string | null;
  dates: Array<string>;
  created: string;
  modified: string;
  tag?: string | null;
};

export type IncidentMessageFragment = {
  __typename?: 'IncidentMessage';
  id: string;
  incidentId: string;
  subject: string;
  body: string;
  start: string;
  created: string;
  tag?: string | null;
};

export type DiscountFragment = {
  __typename?: 'Discount';
  discountPercentage: number;
  ids?: Array<string> | null;
};

export type SeatReservationOfferFragment = {
  __typename?: 'SeatReservationOffer';
  id: string;
  price: number;
  product: SeatProduct;
  commercialTrainNumber?: string | null;
  totalPrice?: number | null;
  attribute?: string | null;
  discount?: ({ __typename?: 'Discount' } & DiscountFragment) | null;
};

export type AdditionalReservationOfferFragment = {
  __typename?: 'AdditionalReservationOffer';
  id: string;
  price: number;
  product: AdditionalReservationProduct;
  commercialTrainNumber?: string | null;
  totalPrice?: number | null;
  discount?: ({ __typename?: 'Discount' } & DiscountFragment) | null;
};

export type SeatChangeOfferFragment = {
  __typename?: 'SeatChangeOffer';
  passengerLegId: string;
  offers: Array<{ __typename?: 'SeatReservationOffer' } & SeatReservationOfferFragment>;
  additionalReservationOffers: Array<
    { __typename?: 'AdditionalReservationOffer' } & AdditionalReservationOfferFragment
  >;
};

export type UnpaidGroupOrderFragment = {
  __typename?: 'Order';
  orderNumber: string;
  paymentDue?: boolean | null;
  state?: string | null;
  advanceFeePaidUntil?: string | null;
  finalFeePaidUntil?: string | null;
  groupJourneys: Array<{
    __typename?: 'GroupJourney';
    departureStation: string;
    departureTime: string;
    arrivalStation: string;
    arrivalTime: string;
    passengerJourneys?: Array<{
      __typename?: 'PassengerJourney';
      id: string;
      state?: JourneyState | null;
      passenger?: { __typename?: 'JourneyPassenger'; type: PassengerType } | null;
    }> | null;
  }>;
};

export type OboCompanyFragment = { __typename?: 'Company'; name: string; id: string };

export type ProductAttributeFragment = {
  __typename?: 'ProductAttribute';
  name: string;
  attribute?: string | null;
  availability: number;
};

export type JourneyOptionLegFragment = {
  __typename?: 'JourneyOptionLeg';
  legKey?: string | null;
  trainNumber: string;
  trainType: TrainType;
  trainAttributes: Array<string>;
  type: string;
  commercialLineIdentifier?: string | null;
  departureStation: string;
  departureTime: string;
  arrivalStation: string;
  arrivalTime: string;
  trainFillPercentage?: number | null;
  productAttributes: Array<{ __typename?: 'ProductAttribute' } & ProductAttributeFragment>;
};

export type CrmCompanyFragment = {
  __typename?: 'CrmCompany';
  addressCity?: string | null;
  addressCountry?: string | null;
  addressPOBox?: string | null;
  addressPostalCode?: string | null;
  addressStreet?: string | null;
  businessId?: string | null;
  companyName: string;
  companyNameRow2?: string | null;
  companyNumber: string;
  department?: string | null;
  departmentRow2?: string | null;
  discount?: number | null;
  eInvoiceAddress?: string | null;
  invoicedCompanyName?: string | null;
  invoicedCompanyNameRow2?: string | null;
  invoiceReference?: string | null;
  invoicingAddressCity?: string | null;
  invoicingAddressCountry?: string | null;
  invoicingAddressPOBox?: string | null;
  invoicingAddressPostalCode?: string | null;
  invoicingAddressStreet?: string | null;
  invoicingAllowed: boolean;
  invoicingEmailAddress?: string | null;
  invoicingInterval?: InvoicingInterval | null;
  invoicingLanguage?: InvoicingLanguage | null;
  invoicingMethod?: InvoicingMethod | null;
  paymentTerm?: PaymentTerm | null;
  taxNumber?: string | null;
  discountCodesInUse?: boolean | null;
};

export type CrmUserFragment = {
  __typename?: 'CrmUser';
  b2bTravellerId: string;
  status: CrmTravellerStatus;
  emailAddress: string;
  role: JwtCompanyRole;
  customer?: {
    __typename?: 'CrmCustomer';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
};

export type CreateTravelCodesMutationVariables = Exact<{
  usageType: TravelCodeType;
  numberOfCodes: Scalars['Int']['input'];
  travelStartDate: Scalars['Date']['input'];
  travelEndDate: Scalars['Date']['input'];
}>;

export type CreateTravelCodesMutation = {
  __typename?: 'Mutation';
  createTravelCodes: Array<string>;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  removeSiblings?: InputMaybe<Array<JwtCompanyRole> | JwtCompanyRole>;
}>;

export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteCompanyUser: { __typename?: 'DeleteCompanyUserStatus'; success: boolean };
};

export type GetCrmCompanyQueryVariables = Exact<{ [key: string]: never }>;

export type GetCrmCompanyQuery = {
  __typename?: 'Query';
  crmCompany: { __typename?: 'CrmCompany' } & CrmCompanyFragment;
};

export type GetCrmUsersQueryVariables = Exact<{
  input: CrmUsersInput;
}>;

export type GetCrmUsersQuery = {
  __typename?: 'Query';
  crmUsers: Array<{ __typename?: 'CrmUser' } & CrmUserFragment>;
};

export type GetCrmUserRolesQueryVariables = Exact<{
  input: CrmUsersInput;
}>;

export type GetCrmUserRolesQuery = {
  __typename?: 'Query';
  crmUsers: Array<{ __typename?: 'CrmUser'; role: JwtCompanyRole }>;
};

export type InviteUserMutationVariables = Exact<{
  input: UserInvitationInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteCompanyUser: Array<{ __typename?: 'CrmUser'; b2bTravellerId: string }>;
};

export type RemoveTravelCodesMutationVariables = Exact<{
  discountCodes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveTravelCodesMutation = {
  __typename?: 'Mutation';
  removeTravelCodes?: boolean | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  input: CrmCompanyInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: 'Mutation';
  updateCompany: { __typename?: 'CrmCompany' } & CrmCompanyFragment;
};

export type AddBicyclesMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  passengerJourneyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type AddBicyclesMutation = {
  __typename?: 'Mutation';
  addBicycles: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type AddCabinOptionMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  cabinUpgradeOptionId: Scalars['ID']['input'];
}>;

export type AddCabinOptionMutation = {
  __typename?: 'Mutation';
  addCabinOption?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type AddCancellationInsuranceToOrderMutationVariables = Exact<{
  salesSessionId: Scalars['ID']['input'];
  cancellationInsuranceOfferId: Scalars['ID']['input'];
}>;

export type AddCancellationInsuranceToOrderMutation = {
  __typename?: 'Mutation';
  addCancellationInsuranceToOrder: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type AddExternalAvecraServiceMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  groupJourneyIdWithDepartureStation: Scalars['ID']['input'];
  input: Array<PassengerWithAvecraProductsInput> | PassengerWithAvecraProductsInput;
  legKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type AddExternalAvecraServiceMutation = {
  __typename?: 'Mutation';
  addExternalAvecraService?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type AddExternalMatkahuoltoServiceMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  externalServiceOfferOpt: Array<Scalars['String']['input']> | Scalars['String']['input'];
  offersToRemove?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type AddExternalMatkahuoltoServiceMutation = {
  __typename?: 'Mutation';
  addExternalMatkahuoltoService?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type AddReturnToSaleMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
  journeyOptionId: Scalars['String']['input'];
  optionKey: Scalars['String']['input'];
}>;

export type AddReturnToSaleMutation = {
  __typename?: 'Mutation';
  addReturnToSale: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type AddVehicleMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  vehicles: Array<VehicleInput> | VehicleInput;
}>;

export type AddVehicleMutation = {
  __typename?: 'Mutation';
  addVehicle: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type AddVehicleAndDriverDetailsMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  vehicles: Array<VehicleUpdateInput> | VehicleUpdateInput;
}>;

export type AddVehicleAndDriverDetailsMutation = {
  __typename?: 'Mutation';
  addVehicleAndDriverDetails?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type AllPaymentIdsQueryVariables = Exact<{
  orderNumber: Scalars['String']['input'];
}>;

export type AllPaymentIdsQuery = {
  __typename?: 'Query';
  order?: {
    __typename?: 'Order';
    orderNumber: string;
    allPaymentIds?: Array<string> | null;
  } | null;
};

export type ApplyDiscountCodesMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  passengerDiscountCodes: Array<PassengerDiscountCodeInput> | PassengerDiscountCodeInput;
}>;

export type ApplyDiscountCodesMutation = {
  __typename?: 'Mutation';
  applyDiscountCodes?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type AvecraProductAvailabilityQueryVariables = Exact<{
  passengerLegIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type AvecraProductAvailabilityQuery = {
  __typename?: 'Query';
  avecraProductAvailability: Array<
    { __typename?: 'AvecraProductsPerLeg' } & AvecraProductsPerLegFragment
  >;
};

export type GetBusinessInfoQueryVariables = Exact<{
  businessId: Scalars['String']['input'];
}>;

export type GetBusinessInfoQuery = {
  __typename?: 'Query';
  businessInfo: {
    __typename?: 'BusinessInfo';
    businessId: string;
    name: string;
    street: string;
    postalCode: string;
    city: string;
  };
};

export type CancelJourneysMutationVariables = Exact<{
  orderNumber: Scalars['ID']['input'];
  passengers: Array<Scalars['String']['input']> | Scalars['String']['input'];
  vehicles: Array<Scalars['String']['input']> | Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<LanguageCode>;
}>;

export type CancelJourneysMutation = {
  __typename?: 'Mutation';
  cancelJourneys: {
    __typename?: 'CancelJourneysResponse';
    status: CancelStatus;
    needsManualRefunding?: boolean | null;
  };
};

export type SimpleCancellationSummaryFragment = {
  __typename?: 'CancellationSummary';
  status: CancelStatus;
  refundAmount: number;
};

export type GetCancellationSummaryQueryVariables = Exact<{
  orderNumber: Scalars['ID']['input'];
  groupId: Scalars['ID']['input'];
  passengerJourneyIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  vehicleIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type GetCancellationSummaryQuery = {
  __typename?: 'Query';
  cancellationSummary: { __typename?: 'CancellationSummary' } & SimpleCancellationSummaryFragment;
};

export type GetCancellableJourneysQueryVariables = Exact<{ [key: string]: never }>;

export type GetCancellableJourneysQuery = {
  __typename?: 'Query';
  cancellableJourneys: Array<{
    __typename?: 'GroupJourney';
    orderNumber?: string | null;
    idWithDepartureStation: string;
    departureStation: string;
    departureTime: string;
    arrivalStation: string;
    arrivalTime: string;
    passengerJourneys?: Array<{ __typename?: 'PassengerJourney'; id: string }> | null;
  }>;
};

export type ChangePlaceReservationsMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  passengerLegs: Array<PassengerLegsInput> | PassengerLegsInput;
}>;

export type ChangePlaceReservationsMutation = {
  __typename?: 'Mutation';
  changePlaceReservations: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type ChangeSeatsMutationVariables = Exact<{
  changeSeatInputs: Array<ChangeSeatInput> | ChangeSeatInput;
  legInput: LegInput;
  saleId: Scalars['ID']['input'];
}>;

export type ChangeSeatsMutation = {
  __typename?: 'Mutation';
  changeSeats:
    | { __typename?: 'ChangeSeatsError'; code?: ChangeSeatsErrorCode | null }
    | ({ __typename?: 'SalesSession' } & SalesSessionFragment);
};

export type CiamTokensMutationVariables = Exact<{
  sessionKey: Scalars['String']['input'];
}>;

export type CiamTokensMutation = {
  __typename?: 'Mutation';
  ciamTokens: {
    __typename?: 'CiamCodeExchangeResponse';
    user: { __typename?: 'User' } & UserIdentityFragment;
  };
};

export type CiamResetPasswordMutationVariables = Exact<{
  language: LanguageCode;
  redirectUri: Scalars['String']['input'];
}>;

export type CiamResetPasswordMutation = {
  __typename?: 'Mutation';
  ciamResetPassword: { __typename?: 'CiamResetPasswordResponse'; success: boolean };
};

export type ClaimOrderMutationVariables = Exact<{
  orderNumber: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
}>;

export type ClaimOrderMutation = {
  __typename?: 'Mutation';
  claimOrder?: { __typename?: 'ClaimOrderResponse'; status: ClaimOrderStatus } | null;
};

export type ClaimSaleMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ClaimSaleMutation = { __typename?: 'Mutation'; claimSale: boolean };

export type CodeExchangeMutationVariables = Exact<{
  code: Scalars['String']['input'];
}>;

export type CodeExchangeMutation = {
  __typename?: 'Mutation';
  codeExchange: {
    __typename: 'CodeExchangeResponse';
    user: { __typename?: 'User' } & UserIdentityFragment;
  };
};

export type CommercialTrainFragment = {
  __typename?: 'CommercialTrain';
  trainType: TrainType;
  trainNumber: string;
  fromStation: string;
  toStation: string;
  type: JoinType;
};

export type GetCommercialTrainsQueryVariables = Exact<{
  fromStation: Scalars['String']['input'];
  toStation: Scalars['String']['input'];
  trainNumber: Scalars['String']['input'];
  travelDate: Scalars['String']['input'];
}>;

export type GetCommercialTrainsQuery = {
  __typename?: 'Query';
  commercialTrains: Array<{ __typename?: 'CommercialTrain' } & CommercialTrainFragment>;
};

export type BusJourneyOfferGroupFragment = {
  __typename?: 'BusJourneyOfferGroup';
  key: string;
  destination?: string | null;
  departureTime?: string | null;
  arrivalTime?: string | null;
  notAvailableReasons?: Array<number> | null;
  availableForSale?: boolean | null;
  price: number;
  serviceFeePrice?: number | null;
  legs?: Array<{ __typename?: 'BusJourneyOfferLeg' } & BusJourneyOfferLegFragment> | null;
  offers: Array<{ __typename?: 'BusJourneyOffer' } & BusJourneyOfferFragment>;
};

export type BusJourneyOfferFragment = {
  __typename?: 'BusJourneyOffer';
  id: string;
  price: number;
  destination?: string | null;
  departureTime?: string | null;
  arrivalTime?: string | null;
  notAvailableReasons?: Array<number> | null;
  availableForSale?: boolean | null;
};

export type BusJourneyOfferLegFragment = {
  __typename?: 'BusJourneyOfferLeg';
  fromPlaceId: string;
  fromPlaceName?: string | null;
  fromStopAreaName?: string | null;
  fromStopName?: string | null;
  toPlaceId: string;
  toPlaceName?: string | null;
  toStopAreaName?: string | null;
  toStopName?: string | null;
  departureTime?: string | null;
  arrivalTime?: string | null;
  lineName?: string | null;
  lineOperator?: string | null;
};

export type CreateBusJourneyOffersQueryVariables = Exact<{
  placeId: Scalars['ID']['input'];
  passengerJourneyIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  minTransferTime?: InputMaybe<Scalars['Int']['input']>;
}>;

export type CreateBusJourneyOffersQuery = {
  __typename?: 'Query';
  offers: Array<{ __typename?: 'BusJourneyOfferGroup' } & BusJourneyOfferGroupFragment>;
};

export type CreateCabinWagonmapOffersQueryVariables = Exact<{
  legIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  saleId: Scalars['ID']['input'];
}>;

export type CreateCabinWagonmapOffersQuery = {
  __typename?: 'Query';
  createCabinWagonmapOffers: Array<{
    __typename?: 'WagonMapNightCabinOffer';
    id: string;
    type: CabinType;
    size: number;
    price: number;
    discountCategory?: string | null;
    attribute?: string | null;
    commercialTrainNumber?: string | null;
  }>;
};

export type CreateCabinOptionsQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  legIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type CreateCabinOptionsQuery = {
  __typename?: 'Query';
  createCabinOptions: Array<{
    __typename?: 'BedCabin';
    id: string;
    placeType: string;
    price: number;
    priceDifference: number;
    validUntil: string;
    cabins: Array<{
      __typename?: 'CabinDetails';
      type: CabinType;
      attribute?: string | null;
      size: number;
      price: number;
      discountCategory?: string | null;
    }>;
    discount?: {
      __typename?: 'Discount';
      discountPercentage: number;
      ids?: Array<string> | null;
    } | null;
  }>;
};

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCrmCompanyInput;
  reCaptchaResponse: Scalars['String']['input'];
}>;

export type CreateCompanyMutation = {
  __typename?: 'Mutation';
  createCompany: {
    __typename?: 'CrmCompanyCreate';
    businessId?: string | null;
    companyName: string;
    companyNumber: string;
    status: CompanyCreateStatus;
  };
};

export type CreateCorporateProductOffersQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  groupJourneyId: Scalars['ID']['input'];
}>;

export type CreateCorporateProductOffersQuery = {
  __typename?: 'Query';
  offers: Array<{
    __typename?: 'CorporateProductOption';
    id?: string | null;
    type?: string | null;
    price?: number | null;
  } | null>;
};

export type CreateNewBundleSaleMutationVariables = Exact<{
  bundleOfferId: Scalars['String']['input'];
  optionKey: Scalars['String']['input'];
}>;

export type CreateNewBundleSaleMutation = {
  __typename?: 'Mutation';
  createNewBundleSale: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type CreateNewSaleMutationVariables = Exact<{
  journeyOptionId: Scalars['String']['input'];
  optionKey: Scalars['String']['input'];
}>;

export type CreateNewSaleMutation = {
  __typename?: 'Mutation';
  createNewSale: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type CreateSeatChangeOffersQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  legIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  returnAllOptions?: InputMaybe<Scalars['Boolean']['input']>;
  respectProductAvailability?: InputMaybe<Scalars['Boolean']['input']>;
  attributes?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;

export type CreateSeatChangeOffersQuery = {
  __typename?: 'Query';
  createSeatChangeOffers: Array<{ __typename?: 'SeatChangeOffer' } & SeatChangeOfferFragment>;
};

export type SeriesBundleOffersQueryVariables = Exact<{
  departure: Scalars['String']['input'];
  arrival: Scalars['String']['input'];
  passengerType: PassengerType;
}>;

export type SeriesBundleOffersQuery = {
  __typename?: 'Query';
  createSeriesBundleOffers:
    | { __typename?: 'NoConnections'; noConnectionsReason?: NoConnectionsReason | null }
    | {
        __typename?: 'SeriesBundleOffers';
        offers: Array<{
          __typename?: 'SeriesBundleOffer';
          id: string;
          type: MultiTicketType;
          seatClass: SeatClass;
          price: number;
          originalPrice?: number | null;
          taxRatePercentage?: number | null;
          discountPercentage?: number | null;
          ticketCount: number;
          usageStartTime?: string | null;
          usageEndTime?: string | null;
          periodLengthDays: number;
        }>;
      };
};

export type FinalizePaymentMutationVariables = Exact<{
  language: LanguageCode;
  saleId: Scalars['ID']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  paymentParameters?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['String']['input'];
}>;

export type FinalizePaymentMutation = {
  __typename?: 'Mutation';
  finalizePayment: {
    __typename?: 'FinalizePaymentResponse';
    orderNumber: string;
    paymentRefNum?: string | null;
    paymentId?: string | null;
    paymentMethod: PaymentMethodName;
  };
};

export type GetUploadUrlForAttachmentMutationVariables = Exact<{
  fileName: Scalars['String']['input'];
  contentType: Scalars['String']['input'];
}>;

export type GetUploadUrlForAttachmentMutation = {
  __typename?: 'Mutation';
  getUploadUrlForAttachment: {
    __typename?: 'PresignedUrlResponse';
    url: string;
    key: string;
    headers: any;
  };
};

export type GetCancellationInsuranceOfferQueryVariables = Exact<{
  salesSessionId: Scalars['ID']['input'];
}>;

export type GetCancellationInsuranceOfferQuery = {
  __typename?: 'Query';
  cancellationInsuranceOffer:
    | ({
        __typename?: 'CancellationInsuranceOffer';
      } & CancellationInsuranceOffer_CancellationInsuranceOffer_Fragment)
    | ({ __typename?: 'NoOffer' } & CancellationInsuranceOffer_NoOffer_Fragment);
};

export type GetCompanyQueryVariables = Exact<{
  businessId: Scalars['String']['input'];
}>;

export type GetCompanyQuery = {
  __typename?: 'Query';
  company?: ({ __typename?: 'Company' } & OboCompanyFragment) | null;
};

export type GetDeliveryEmailQueryVariables = Exact<{ [key: string]: never }>;

export type GetDeliveryEmailQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    info:
      | { __typename?: 'CiamUserInfo'; activeEmail?: string | null }
      | { __typename?: 'ConsumerUserInfo'; activeEmail?: string | null }
      | { __typename?: 'CorporateUserInfo'; activeEmail?: string | null }
      | { __typename?: 'EmployeeUserInfo'; activeEmail?: string | null };
  };
};

export type GetGeneralIncidentsQueryVariables = Exact<{
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetGeneralIncidentsQuery = {
  __typename?: 'Query';
  generalIncidents: Array<{ __typename?: 'IncidentMessage' } & IncidentMessageFragment>;
};

export type SeriesTicketDetailsFragment = {
  __typename?: 'MultiTicket';
  bookingId: string;
  orderNumber: string;
  usagesLeft: number;
  arrivalStation: string;
  departureStation: string;
  type: MultiTicketType;
  seatClass: SeatClass;
  passengerType: PassengerType;
  price: number;
  ticketCount: number;
  usageStartTime: string;
  usageEndTime: string;
  payments: Array<{ __typename?: 'Payment' } & PaymentFragment>;
};

export type GetUserMultiTicketQueryVariables = Exact<{
  orderNumber?: InputMaybe<Scalars['ID']['input']>;
  bookingId?: InputMaybe<Scalars['ID']['input']>;
  includeExpired?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetUserMultiTicketQuery = {
  __typename?: 'Query';
  multiTicket: { __typename?: 'MultiTicket' } & SeriesTicketDetailsFragment;
};

export type GetMultiTicketUsagesQueryVariables = Exact<{
  bookingId: Scalars['String']['input'];
  size?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetMultiTicketUsagesQuery = {
  __typename?: 'Query';
  multiTicketUsages: {
    __typename?: 'MultiTicketUsagesResponse';
    total: number;
    items: Array<{
      __typename?: 'MultiTicketUsage';
      orderNumber: string;
      passengerJourneyId: string;
      arrivalTime: string;
      arrivalStation: string;
      departureTime: string;
      departureStation: string;
      details?: {
        __typename?: 'MultiTicketUsageDetails';
        paymentId?: string | null;
        passengerType: PassengerType;
        additionalProducts: Array<{
          __typename?: 'AdditionalProduct';
          type?: ProductType | null;
          count: number;
        }>;
      } | null;
    }>;
  };
};

export type GetOrderQueryVariables = Exact<{
  orderNumber: Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  cancellationSummary?: InputMaybe<CancellationSummaryInput>;
  groupJourneyIds?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetOrderQuery = {
  __typename?: 'Query';
  order?: ({ __typename?: 'Order' } & OrderFragment) | null;
};

export type OrderHistoryEventFragment = {
  __typename?: 'OrderHistoryEvent';
  id: string;
  touchpoint: Touchpoint;
  timestamp?: string | null;
  travelAgentId?: string | null;
  changes: Array<{
    __typename?: 'OrderHistoryEventChange';
    type: OrderHistoryEventChangeType;
    count?: number | null;
  }>;
};

export type GetOrderHistoryQueryVariables = Exact<{
  orderNumber: Scalars['String']['input'];
  journeyKey?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetOrderHistoryQuery = {
  __typename?: 'Query';
  orderHistory: Array<{ __typename?: 'OrderHistoryEvent' } & OrderHistoryEventFragment>;
};

export type OrderSummaryFragment = {
  __typename?: 'OrderSummary';
  orderNumber: string;
  ticketType: TicketType;
  journeysV2: Array<{ __typename?: 'OrderSummaryJourneyV2' } & UserOrderJourneyFragment>;
  additionalServices: Array<{ __typename?: 'OrderSummaryService' } & OrderSummaryServiceFragment>;
  bundleTicketDetails?: ({ __typename?: 'BundleDetails' } & BundleTicketDetailsFragment) | null;
};

export type OrderSummariesFragment = {
  __typename?: 'OrderSummaries';
  nextToken?: string | null;
  orderSummaries: Array<{ __typename?: 'OrderSummary' } & OrderSummaryFragment>;
};

export type GetOrdersQueryVariables = Exact<{
  journeyDepartureTimeStart?: InputMaybe<Scalars['String']['input']>;
  journeyDepartureTimeEnd?: InputMaybe<Scalars['String']['input']>;
  journeyArrivalTimeStart?: InputMaybe<Scalars['String']['input']>;
  journeyArrivalTimeEnd?: InputMaybe<Scalars['String']['input']>;
  bookingStates?: InputMaybe<Array<BookingState> | BookingState>;
  numberOfResults?: InputMaybe<Scalars['Int']['input']>;
  travelAgentId?: InputMaybe<Scalars['String']['input']>;
  oboCompany?: InputMaybe<Scalars['String']['input']>;
  ticketTypes?: InputMaybe<Array<TicketInputType> | TicketInputType>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortingDirection?: InputMaybe<Sorting>;
}>;

export type GetOrdersQuery = {
  __typename?: 'Query';
  orders?: ({ __typename?: 'OrderSummaries' } & OrderSummariesFragment) | null;
};

export type GetPriceCalendarQueryVariables = Exact<{
  departureStation: Scalars['String']['input'];
  arrivalStation: Scalars['String']['input'];
  startDate: Scalars['Date']['input'];
  endDate: Scalars['Date']['input'];
  passengers: Array<PassengerInput> | PassengerInput;
  placeTypes: Array<PlaceType> | PlaceType;
}>;

export type GetPriceCalendarQuery = {
  __typename?: 'Query';
  priceCalendar?:
    | {
        __typename?: 'Calendar';
        days: Array<{
          __typename?: 'Day';
          date: string;
          id?: string | null;
          price?: number | null;
          error?: {
            __typename?: 'Error';
            name?: string | null;
            description?: string | null;
          } | null;
        }>;
      }
    | { __typename?: 'NoOffer'; noOfferReason: NoOfferReason }
    | null;
};

export type GetReportEmbedInfoQueryVariables = Exact<{
  groupId: Scalars['String']['input'];
  reportId: Scalars['String']['input'];
}>;

export type GetReportEmbedInfoQuery = {
  __typename?: 'Query';
  reportEmbedInfo: {
    __typename?: 'ReportEmbedInfo';
    accessToken: string;
    embedUrl: string;
    expiration: string;
  };
};

export type GetReportsQueryVariables = Exact<{ [key: string]: never }>;

export type GetReportsQuery = {
  __typename?: 'Query';
  reports: Array<{ __typename?: 'ReportInfo'; groupId: string; id: string; name: string }>;
};

export type GetIncidentsQueryVariables = Exact<{
  language: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
}>;

export type GetIncidentsQuery = {
  __typename?: 'Query';
  trafficDisruptions_v2: Array<{ __typename?: 'TrafficDisruption' } & TrafficDisruptionFragment>;
};

export type GetUnpaidGroupOrdersQueryVariables = Exact<{
  offset: Scalars['Int']['input'];
  limit: Scalars['Int']['input'];
}>;

export type GetUnpaidGroupOrdersQuery = {
  __typename?: 'Query';
  unpaidGroupOrders?: {
    __typename?: 'UnpaidGroupOrders';
    items: Array<{ __typename?: 'Order' } & UnpaidGroupOrderFragment>;
    pagination: { __typename?: 'PaginationInfo'; total: number };
  } | null;
};

export type GetUserDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserDetailsQuery = {
  __typename?: 'Query';
  userStatus:
    | { __typename?: 'NotLoggedIn'; notLoggedIn: boolean }
    | ({ __typename?: 'User' } & UserFragment);
};

export type GetUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserInfoQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    info:
      | {
          __typename?: 'CiamUserInfo';
          activeEmail?: string | null;
          activeCompanyName: string;
          activeCompanyId: string;
          customerId: string;
          firstName: string;
          lastName: string;
          mobilePhone?: string | null;
        }
      | {
          __typename?: 'ConsumerUserInfo';
          activeEmail?: string | null;
          activeCompanyName?: string | null;
          activeCompanyId?: string | null;
          customerId: string;
          firstName: string;
          lastName: string;
          mobilePhone?: string | null;
        }
      | {
          __typename?: 'CorporateUserInfo';
          activeEmail?: string | null;
          activeCompanyName: string;
          activeCompanyId: string;
          customerId: string;
          firstName: string;
          lastName: string;
          mobilePhone?: string | null;
        }
      | {
          __typename?: 'EmployeeUserInfo';
          activeEmail?: string | null;
          activeCompanyName?: string | null;
          activeCompanyId?: string | null;
          customerId: string;
          firstName: string;
          lastName: string;
          mobilePhone?: string | null;
        };
  };
};

export type UserOrderJourneyFragment = {
  __typename?: 'OrderSummaryJourneyV2';
  passengerJourneyId?: string | null;
  departureTime?: string | null;
  arrivalTime?: string | null;
  departureStation?: string | null;
  arrivalStation?: string | null;
  state?: string | null;
};

export type OrderSummaryServiceFragment = {
  __typename?: 'OrderSummaryService';
  serviceType: OrderSummaryServiceType;
  amount: number;
  passengerJourneyId: string;
};

export type UserOrderSummaryFragment = {
  __typename?: 'OrderSummary';
  orderNumber: string;
  journeysV2: Array<{ __typename?: 'OrderSummaryJourneyV2' } & UserOrderJourneyFragment>;
  additionalServices: Array<{ __typename?: 'OrderSummaryService' } & OrderSummaryServiceFragment>;
};

export type BundleTicketDetailsFragment = {
  __typename?: 'BundleDetails';
  usageStartTime?: string | null;
  usageEndTime?: string | null;
  usagesCount?: number | null;
  usagesLeft?: number | null;
  bookingId?: string | null;
  productName?: string | null;
};

export type UserOrderSummariesFragment = {
  __typename?: 'OrderSummaries';
  nextToken?: string | null;
  orderSummaries: Array<{ __typename?: 'OrderSummary' } & UserOrderSummaryFragment>;
};

export type SeriesOrderSummaryFragment = {
  __typename?: 'OrderSummary';
  orderNumber: string;
  ticketType: TicketType;
  lastUsageFrom?: string | null;
  journeysV2: Array<
    {
      __typename?: 'OrderSummaryJourneyV2';
      passengerType?: PassengerType | null;
      trafficType?: ProductCategory | null;
    } & UserOrderJourneyFragment
  >;
  bundleTicketDetails?: ({ __typename?: 'BundleDetails' } & BundleTicketDetailsFragment) | null;
};

export type SeriesOrdersFragment = {
  __typename?: 'OrderSummaries';
  orderSummaries: Array<{ __typename?: 'OrderSummary' } & SeriesOrderSummaryFragment>;
};

export type UnpaidGroupOrdersFragment = {
  __typename?: 'UnpaidGroupOrders';
  items: Array<{ __typename?: 'Order' } & UnpaidGroupOrderFragment>;
};

export type GetUserOrdersQueryVariables = Exact<{
  now?: InputMaybe<Scalars['String']['input']>;
  sixMonthsFromNow?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserOrdersQuery = {
  __typename?: 'Query';
  upcomingOrders?: ({ __typename?: 'OrderSummaries' } & UserOrderSummariesFragment) | null;
  pastOrders?: ({ __typename?: 'OrderSummaries' } & UserOrderSummariesFragment) | null;
  seriesOrders?: ({ __typename?: 'OrderSummaries' } & SeriesOrdersFragment) | null;
  unpaidGroupOrders?: ({ __typename?: 'UnpaidGroupOrders' } & UnpaidGroupOrdersFragment) | null;
};

export type GetUserSeriesOrdersQueryVariables = Exact<{
  journeyArrivalTimeEnd?: InputMaybe<Scalars['String']['input']>;
}>;

export type GetUserSeriesOrdersQuery = {
  __typename?: 'Query';
  seriesOrders?: ({ __typename?: 'OrderSummaries' } & SeriesOrdersFragment) | null;
};

export type GetUserTicketHistoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserTicketHistoryQuery = {
  __typename?: 'Query';
  user: {
    __typename?: 'User';
    ticketHistory: Array<{
      __typename?: 'TicketHistory';
      orderNumber: string;
      journeys: Array<{
        __typename: 'GroupJourney';
        departureTime: string;
        departureStation: string;
        arrivalTime: string;
        arrivalStation: string;
        passengerJourneys?: Array<{
          __typename?: 'PassengerJourney';
          id: string;
          legs: Array<{ __typename?: 'JourneyLeg'; trainNumber?: string | null }>;
          passenger?: { __typename?: 'JourneyPassenger'; type: PassengerType } | null;
        }> | null;
        vehicles?: Array<{ __typename?: 'Vehicle'; id: string }> | null;
        additionalProducts?: Array<{
          __typename?: 'AdditionalProduct';
          type?: ProductType | null;
          count: number;
        }> | null;
      }>;
    }>;
  };
};

export type GetVehicleCancellationInsuranceOfferQueryVariables = Exact<{
  salesSessionId: Scalars['ID']['input'];
  vehicleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type GetVehicleCancellationInsuranceOfferQuery = {
  __typename?: 'Query';
  vehicleCancellationInsuranceOffer:
    | ({
        __typename?: 'CancellationInsuranceOffer';
      } & CancellationInsuranceOffer_CancellationInsuranceOffer_Fragment)
    | ({ __typename?: 'NoOffer' } & CancellationInsuranceOffer_NoOffer_Fragment);
};

export type InvoicePaymentMutationVariables = Exact<{
  language: LanguageCode;
  saleId: Scalars['ID']['input'];
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  singleInvoice: Scalars['Boolean']['input'];
  passengers?: InputMaybe<Array<InvoicePassengerInput> | InvoicePassengerInput>;
  scope?: InputMaybe<Array<PriceElementScope> | PriceElementScope>;
}>;

export type InvoicePaymentMutation = {
  __typename?: 'Mutation';
  invoicePayment: {
    __typename?: 'InvoicePaymentResponse';
    payment?: {
      __typename?: 'Payment';
      paymentId: string;
      state: PurchaseState;
      orderNumber?: string | null;
    } | null;
  };
};

export type WagonFragmentFragment = { __typename?: 'AssemblyWagon'; coachType?: string | null };

export type GetLegExtraInfoQueryVariables = Exact<{
  fromStation: Scalars['String']['input'];
  toStation: Scalars['String']['input'];
  departureTime: Scalars['String']['input'];
  trainNumber: Scalars['String']['input'];
}>;

export type GetLegExtraInfoQuery = {
  __typename?: 'Query';
  legExtraInfo: {
    __typename?: 'LegExtraInfo';
    wagons: Array<{ __typename?: 'AssemblyWagon' } & WagonFragmentFragment>;
    trainInfo?: ({ __typename?: 'TrainInfo' } & TrainInfoFragment) | null;
  };
};

export type LogoutV2MutationVariables = Exact<{
  redirectUrl: Scalars['String']['input'];
}>;

export type LogoutV2Mutation = { __typename?: 'Mutation'; logoutV2: string };

export type PaymentMethodStatusesQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodStatusesQuery = {
  __typename?: 'Query';
  paymentStatuses: {
    __typename?: 'PaymentStatusesResponse';
    invoicingAllowed: boolean;
    statuses: Array<{ __typename?: 'PaymentStatus' } & PaymentStatusFragment>;
  };
};

export type PaymentStatusFragment = {
  __typename?: 'PaymentStatus';
  name: PaymentMethodName;
  status: PaymentMethodStatus;
};

export type PreparePaymentMutationVariables = Exact<{
  paymentMethod: PaymentMethodName;
  returnURL: Scalars['String']['input'];
  cancelURL: Scalars['String']['input'];
  failureURL: Scalars['String']['input'];
  language: LanguageCode;
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  saleId: Scalars['ID']['input'];
  scope?: InputMaybe<Array<PriceElementScope> | PriceElementScope>;
}>;

export type PreparePaymentMutation = {
  __typename?: 'Mutation';
  preparePayment: {
    __typename?: 'PreparePaymentResponse';
    paymentId?: string | null;
    redirectURL: string;
    redirectMethod?: RedirectMethod | null;
    redirectBody?: any | null;
  };
};

export type GetWagonMapDataQueryVariables = Exact<{
  saleId?: InputMaybe<Scalars['String']['input']>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
  legKey: Scalars['String']['input'];
}>;

export type GetWagonMapDataQuery = {
  __typename?: 'Query';
  wagonMapData?: {
    __typename?: 'WagonMapDataV2';
    coaches?: any | null;
    commercialTrainInfo?: any | null;
  } | null;
};

export type ReleaseSaleMutationVariables = Exact<{
  saleId: Scalars['String']['input'];
}>;

export type ReleaseSaleMutation = { __typename?: 'Mutation'; releaseSale: boolean };

export type RemoveCabinOptionMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  cabinIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  legIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
}>;

export type RemoveCabinOptionMutation = {
  __typename?: 'Mutation';
  removeCabinOption?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type RemoveCancellationInsuranceFromOrderMutationVariables = Exact<{
  salesSessionId: Scalars['ID']['input'];
}>;

export type RemoveCancellationInsuranceFromOrderMutation = {
  __typename?: 'Mutation';
  removeCancellationInsuranceFromOrder: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type RemoveExternalServiceMutationVariables = Exact<{
  sessionId: Scalars['ID']['input'];
  externalServiceIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;

export type RemoveExternalServiceMutation = {
  __typename?: 'Mutation';
  removeExternalService?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type RemoveVehicleCancellationInsuranceMutationVariables = Exact<{
  salesSessionId: Scalars['ID']['input'];
  vehicleIds?: InputMaybe<Array<Scalars['ID']['input']> | Scalars['ID']['input']>;
}>;

export type RemoveVehicleCancellationInsuranceMutation = {
  __typename?: 'Mutation';
  removeVehicleCancellationInsuranceFromOrder: {
    __typename?: 'SalesSession';
  } & SalesSessionFragment;
};

export type ReplaceJourneyMutationVariables = Exact<{
  journeyOptionId: Scalars['ID']['input'];
  salesSessionId: Scalars['ID']['input'];
  groupJourneyId: Scalars['ID']['input'];
  passengerJourneyScope: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  optionKey: Scalars['String']['input'];
}>;

export type ReplaceJourneyMutation = {
  __typename?: 'Mutation';
  replaceJourney: { __typename?: 'SalesSession' } & SalesSessionFragment;
};

export type SeriesTicketFragment = {
  __typename?: 'SeriesTicket';
  type: string;
  passengerJourneyId: string;
  stations: Array<string>;
  usageStartTime: string;
  usageEndTime: string;
  ticketCount: number;
  periodLengthDays: number;
  price: number;
  ticketClass: string;
};

export type SessionJourneyLegFragment = {
  __typename?: 'JourneyLeg';
  id: string;
  arrivalTime: string;
  arrivalStation: string;
  departureTime: string;
  departureStation: string;
  legKey: string;
  trafficType?: string | null;
  trainType?: TrainType | null;
  commercialTrainNumber?: string | null;
  reservation?: ({ __typename?: 'Reservation' } & LegReservationFragment) | null;
  additionalReservations?: Array<
    ({ __typename?: 'Reservation' } & LegReservationFragment) | null
  > | null;
  avecraServices?: Array<{ __typename?: 'AvecraService' } & AvecraServicesFragment> | null;
  bicycles: Array<{ __typename?: 'Bicycle'; wagonNumber?: number | null }>;
  trainInfo?: ({ __typename?: 'TrainInfo' } & TrainInfoFragment) | null;
};

export type SessionPassengerJourneyFragment = {
  __typename?: 'PassengerJourney';
  id: string;
  seriesTicketId?: string | null;
  passenger?: {
    __typename?: 'JourneyPassenger';
    type: PassengerType;
    eligibleForCancellationInsurance: boolean;
    linkedToPassenger?: string | null;
  } | null;
  bicycleFees?: Array<{ __typename?: 'JourneyFee' } & JourneyFeeFragment> | null;
  petFees?: Array<{ __typename?: 'JourneyFee' } & JourneyFeeFragment> | null;
  otherFees?: Array<{ __typename?: 'JourneyFee' } & JourneyFeeFragment> | null;
  legs: Array<{ __typename?: 'JourneyLeg' } & SessionJourneyLegFragment>;
  cancellationInsuranceOffer?:
    | ({ __typename?: 'CancellationInsurance' } & CancellationInsuranceFragment)
    | null;
  externalServiceOffers?: Array<
    { __typename?: 'ExternalService' } & ExternalServiceFragment
  > | null;
  discountCodes?: Array<{ __typename?: 'DiscountCode' } & DiscountCodeFragment> | null;
};

export type SessionGroupJourneyFragment = {
  __typename?: 'GroupJourney';
  groupId: string;
  idWithDepartureStation: string;
  departureStation: string;
  departureTime: string;
  arrivalStation: string;
  arrivalTime: string;
  usageEndTime: string;
  usageStartTime: string;
  trafficType?: string | null;
  corporate?: ({ __typename?: 'CorporateOffer' } & CorporateOfferFragment) | null;
  vehicles?: Array<{ __typename?: 'Vehicle' } & VehicleFragment> | null;
  cabins: Array<{
    __typename?: 'GroupJourneyCabin';
    id: string;
    legKey: string;
    type: CabinType;
    attribute?: string | null;
    size?: number | null;
    totalPrice: number;
    passengers?: Array<{
      __typename?: 'CabinPassenger';
      passengerLegId: string;
      type: string;
      reservation?: {
        __typename?: 'CabinReservation';
        id: string;
        placeNumber: string;
        wagonNumber: string;
        compartmentNumber: string;
        position: string;
        attribute?: string | null;
        floor: number;
        validUntil?: string | null;
        cabinId: string;
      } | null;
    }> | null;
  }>;
  additionalProducts?: Array<{
    __typename?: 'AdditionalProduct';
    type?: ProductType | null;
    count: number;
  }> | null;
  passengerJourneys?: Array<
    { __typename?: 'PassengerJourney' } & SessionPassengerJourneyFragment
  > | null;
  summary?: ({ __typename?: 'Summary' } & SummaryFragment) | null;
};

export type SalesSessionFragment = {
  __typename?: 'SalesSession';
  id: string;
  orderNumber: string;
  validUntil?: string | null;
  amountToPay?: number | null;
  onBehalfOf?: string | null;
  externalServicePaymentStartDeadline?: string | null;
  groupJourneys: Array<{ __typename?: 'GroupJourney' } & SessionGroupJourneyFragment>;
  sessionSummary?: ({ __typename?: 'Summary' } & SummaryFragment) | null;
  seriesTicket?: ({ __typename?: 'SeriesTicket' } & SeriesTicketFragment) | null;
  price?: {
    __typename?: 'SalesSessionPrice';
    amount: number;
    elements?: Array<({ __typename?: 'PriceElement' } & PriceElementFragment) | null> | null;
  } | null;
};

export type ReCreateSalesSessionMutationVariables = Exact<{
  orderNumber: Scalars['ID']['input'];
}>;

export type ReCreateSalesSessionMutation = {
  __typename?: 'Mutation';
  reCreateSalesSession?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type GetSalesSessionQueryVariables = Exact<{
  sessionId: Scalars['ID']['input'];
}>;

export type GetSalesSessionQuery = {
  __typename?: 'Query';
  salesSession?: ({ __typename?: 'SalesSession' } & SalesSessionFragment) | null;
};

export type JourneyOptionPassengerFragment = {
  __typename?: 'JourneyOptionPassenger';
  type: PassengerType;
  offers: Array<{
    __typename?: 'PassengerOffer';
    legId?: string | null;
    product: string;
    price?: number | null;
    discountCategory?: string | null;
  }>;
};

export type JourneyOptionFragment = {
  __typename?: 'JourneyOption';
  id: string;
  departureTime: string;
  departureStation: string;
  arrivalStation: string;
  arrivalTime: string;
  totalPrice: number;
  error?: NoOfferReason | null;
  highestLegTrainFill?: number | null;
  legs: Array<{ __typename?: 'JourneyOptionLeg' } & JourneyOptionLegFragment>;
  discount?: {
    __typename?: 'Discount';
    discountPercentage: number;
    ids?: Array<string> | null;
  } | null;
  passengers: Array<{ __typename?: 'JourneyOptionPassenger' } & JourneyOptionPassengerFragment>;
  availability: { __typename?: 'OptionAvailability' } & OptionAvailabilityFragment;
};

export type SearchJourneyQueryVariables = Exact<{
  departureStation: Scalars['String']['input'];
  arrivalStation: Scalars['String']['input'];
  departureDateTime: Scalars['DateTime']['input'];
  passengers: Array<PassengerInput> | PassengerInput;
  filters?: Array<InputMaybe<ConnectionFilter>> | InputMaybe<ConnectionFilter>;
  placeTypes: Array<PlaceType> | PlaceType;
  bundleId?: InputMaybe<Scalars['String']['input']>;
  companyId?: InputMaybe<Scalars['String']['input']>;
}>;

export type SearchJourneyQuery = {
  __typename?: 'Query';
  searchJourney: Array<{ __typename?: 'JourneyOption' } & JourneyOptionFragment>;
};

export type GroupSalesJourneyOptionFragment = {
  __typename?: 'GroupSalesJourneyOption';
  id: string;
  totalPrice: number;
  departureStation: string;
  departureTime: string;
  arrivalStation: string;
  arrivalTime: string;
  error?: NoOfferReason | null;
  highestLegTrainFill?: number | null;
  availability: { __typename?: 'OptionAvailability' } & OptionAvailabilityFragment;
  legs: Array<{ __typename?: 'JourneyOptionLeg' } & JourneyOptionLegFragment>;
};

export type SearchJourneyForGroupQueryVariables = Exact<{
  departureStation: Scalars['String']['input'];
  arrivalStation: Scalars['String']['input'];
  departureDateTime: Scalars['DateTime']['input'];
  passengers: Array<GroupSalesPassengerInput> | GroupSalesPassengerInput;
  placeType: PlaceType;
}>;

export type SearchJourneyForGroupQuery = {
  __typename?: 'Query';
  searchJourneyForGroup: Array<
    { __typename?: 'GroupSalesJourneyOption' } & GroupSalesJourneyOptionFragment
  >;
};

export type SearchReplacementJourneyQueryVariables = Exact<{
  saleId: Scalars['ID']['input'];
  departureDateTime: Scalars['DateTime']['input'];
  passengers: Array<ExistingPassengerInput> | ExistingPassengerInput;
  filters?: Array<InputMaybe<ConnectionFilter>> | InputMaybe<ConnectionFilter>;
}>;

export type SearchReplacementJourneyQuery = {
  __typename?: 'Query';
  searchReplacementJourney: Array<{ __typename?: 'JourneyOption' } & JourneyOptionFragment>;
};

export type GetStationQueryVariables = Exact<{
  abbreviation: Scalars['String']['input'];
  direction: Direction;
}>;

export type GetStationQuery = {
  __typename?: 'Query';
  station: {
    __typename?: 'Station';
    id: number;
    names: { __typename?: 'StationNames'; en: string; fi: string; sv: string };
    timetableRows: Array<{
      __typename?: 'Timetable';
      trainCategory: string;
      trainNumber?: string | null;
      trainType?: string | null;
      stationShortCode: string;
      actualTime?: string | null;
      scheduledTime: string;
      differenceInMinutes?: number | null;
      commercialTrack?: string | null;
      runningCurrently: boolean;
      cancelled?: boolean | null;
      departureDate: string;
      commuterLineId?: string | null;
    }>;
  };
};

export type SubmitFormMutationVariables = Exact<{
  form?: InputMaybe<Scalars['JSON']['input']>;
  reCaptchaResponse: Scalars['String']['input'];
  formSubmitRequestId: Scalars['String']['input'];
}>;

export type SubmitFormMutation = {
  __typename?: 'Mutation';
  submitForm: { __typename?: 'FormUploadResponse'; status?: string | null };
};

export type GetTrainIncidentsQueryVariables = Exact<{
  language: Scalars['String']['input'];
  departureDate: Scalars['Date']['input'];
}>;

export type GetTrainIncidentsQuery = {
  __typename?: 'Query';
  trainIncidents: Array<{
    __typename?: 'TrainIncidentMessage';
    trainNumber: string;
    incidentMessages: Array<{ __typename?: 'IncidentMessage' } & IncidentMessageFragment>;
  }>;
};

export type UpdateCabinsMutationVariables = Exact<{
  saleId: Scalars['ID']['input'];
  legIds: Array<Scalars['ID']['input']> | Scalars['ID']['input'];
  operations: Array<CabinOperationInput> | CabinOperationInput;
}>;

export type UpdateCabinsMutation = {
  __typename?: 'Mutation';
  updateCabins:
    | ({ __typename?: 'SalesSession' } & SalesSessionFragment)
    | { __typename?: 'UpdateCabinsErrorResponse'; code: UpdateCabinsErrorCode };
};

export type UpdateInvoiceDetailsMutationVariables = Exact<{
  orderNumber: Scalars['ID']['input'];
  passengers: Array<InvoicePassengerInput> | InvoicePassengerInput;
}>;

export type UpdateInvoiceDetailsMutation = {
  __typename?: 'Mutation';
  updateInvoice:
    | { __typename?: 'InvoiceNotEditable' }
    | {
        __typename?: 'Order';
        orderNumber: string;
        payments: Array<{ __typename?: 'Payment' } & PaymentFragment>;
      };
};

export type UpdateUserDetailsMutationVariables = Exact<{
  input: UpdateUserDetailsInput;
}>;

export type UpdateUserDetailsMutation = {
  __typename?: 'Mutation';
  updateUserDetails: { __typename?: 'User' } & UserFragment;
};

export type FinalizePayment_V2MutationVariables = Exact<{
  language: LanguageCode;
  saleId: Scalars['ID']['input'];
  paymentId?: InputMaybe<Scalars['String']['input']>;
  emails?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  paymentParameters?: InputMaybe<Scalars['String']['input']>;
  orderNumber: Scalars['String']['input'];
}>;

export type FinalizePayment_V2Mutation = {
  __typename?: 'Mutation';
  finalizePayment:
    | { __typename?: 'PaymentFinalized'; success: boolean }
    | {
        __typename?: 'PaymentFinalizedWithInfo';
        orderNumber: string;
        paymentRefNum?: string | null;
        paymentId?: string | null;
        paymentMethod: PaymentMethodName;
        emails?: Array<string> | null;
      };
};

export type GetTempPaymentStateQueryVariables = Exact<{
  paymentId: Scalars['String']['input'];
}>;

export type GetTempPaymentStateQuery = {
  __typename?: 'Query';
  tempPaymentState: PaymentVerifyState;
};

export type AdditionalProductKeySpecifier = ('count' | 'type' | AdditionalProductKeySpecifier)[];
export type AdditionalProductFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AdditionalReservationOfferKeySpecifier = (
  | 'commercialTrainNumber'
  | 'discount'
  | 'id'
  | 'price'
  | 'product'
  | 'totalPrice'
  | AdditionalReservationOfferKeySpecifier
)[];
export type AdditionalReservationOfferFieldPolicy = {
  commercialTrainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AssemblyWagonKeySpecifier = (
  | 'attributes'
  | 'cabinSizes'
  | 'classes'
  | 'coachConnection'
  | 'coachNumber'
  | 'coachPriority'
  | 'coachType'
  | 'commercialTrainArrivalStation'
  | 'commercialTrainDepartureDate'
  | 'commercialTrainDepartureStation'
  | 'commercialTrainNumber'
  | 'floorCount'
  | 'placeType'
  | 'reservable'
  | 'salesWagonName'
  | AssemblyWagonKeySpecifier
)[];
export type AssemblyWagonFieldPolicy = {
  attributes?: FieldPolicy<any> | FieldReadFunction<any>;
  cabinSizes?: FieldPolicy<any> | FieldReadFunction<any>;
  classes?: FieldPolicy<any> | FieldReadFunction<any>;
  coachConnection?: FieldPolicy<any> | FieldReadFunction<any>;
  coachNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  coachPriority?: FieldPolicy<any> | FieldReadFunction<any>;
  coachType?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainArrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainDepartureDate?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainDepartureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  floorCount?: FieldPolicy<any> | FieldReadFunction<any>;
  placeType?: FieldPolicy<any> | FieldReadFunction<any>;
  reservable?: FieldPolicy<any> | FieldReadFunction<any>;
  salesWagonName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvecraExternalServiceLegKeySpecifier = (
  | 'foodSelection1'
  | 'id'
  | 'price'
  | 'product'
  | 'serviceProvider'
  | 'state'
  | 'supplementalServices'
  | AvecraExternalServiceLegKeySpecifier
)[];
export type AvecraExternalServiceLegFieldPolicy = {
  foodSelection1?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  supplementalServices?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvecraOffersKeySpecifier = ('count' | 'products' | AvecraOffersKeySpecifier)[];
export type AvecraOffersFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  products?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvecraProductKeySpecifier = (
  | 'available'
  | 'foodSelection1'
  | 'foodSelection2'
  | 'name'
  | 'price'
  | 'product'
  | 'productId'
  | 'purchaseType'
  | 'supplementalServices'
  | 'unavailabilityReasons'
  | AvecraProductKeySpecifier
)[];
export type AvecraProductFieldPolicy = {
  available?: FieldPolicy<any> | FieldReadFunction<any>;
  foodSelection1?: FieldPolicy<any> | FieldReadFunction<any>;
  foodSelection2?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  productId?: FieldPolicy<any> | FieldReadFunction<any>;
  purchaseType?: FieldPolicy<any> | FieldReadFunction<any>;
  supplementalServices?: FieldPolicy<any> | FieldReadFunction<any>;
  unavailabilityReasons?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvecraProductsPerLegKeySpecifier = (
  | 'legId'
  | 'productAvailability'
  | AvecraProductsPerLegKeySpecifier
)[];
export type AvecraProductsPerLegFieldPolicy = {
  legId?: FieldPolicy<any> | FieldReadFunction<any>;
  productAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type AvecraServiceKeySpecifier = (
  | 'avecraGroupId'
  | 'deliveryState'
  | 'deliveryTime'
  | 'purchaseType'
  | 'services'
  | AvecraServiceKeySpecifier
)[];
export type AvecraServiceFieldPolicy = {
  avecraGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryState?: FieldPolicy<any> | FieldReadFunction<any>;
  deliveryTime?: FieldPolicy<any> | FieldReadFunction<any>;
  purchaseType?: FieldPolicy<any> | FieldReadFunction<any>;
  services?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BedCabinKeySpecifier = (
  | 'cabins'
  | 'discount'
  | 'id'
  | 'placeType'
  | 'price'
  | 'priceDifference'
  | 'validUntil'
  | BedCabinKeySpecifier
)[];
export type BedCabinFieldPolicy = {
  cabins?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  placeType?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  priceDifference?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BicycleKeySpecifier = ('wagonNumber' | BicycleKeySpecifier)[];
export type BicycleFieldPolicy = {
  wagonNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BreakfastOffersKeySpecifier = ('count' | 'products' | BreakfastOffersKeySpecifier)[];
export type BreakfastOffersFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  products?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BundleDetailsKeySpecifier = (
  | 'bookingId'
  | 'productName'
  | 'usageEndTime'
  | 'usageStartTime'
  | 'usagesCount'
  | 'usagesLeft'
  | BundleDetailsKeySpecifier
)[];
export type BundleDetailsFieldPolicy = {
  bookingId?: FieldPolicy<any> | FieldReadFunction<any>;
  productName?: FieldPolicy<any> | FieldReadFunction<any>;
  usageEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usageStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
  usagesLeft?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusJourneyOfferKeySpecifier = (
  | 'arrivalTime'
  | 'availableForSale'
  | 'bicycle'
  | 'departureTime'
  | 'destination'
  | 'id'
  | 'matkahuoltoDestinationPlaceId'
  | 'matkahuoltoDestinationPlaceName'
  | 'notAvailableReasons'
  | 'offerGroupId'
  | 'passengerJourneyId'
  | 'passengerType'
  | 'price'
  | 'product'
  | 'serviceFeePrice'
  | 'stationAbbreviation'
  | 'validUntil'
  | BusJourneyOfferKeySpecifier
)[];
export type BusJourneyOfferFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  availableForSale?: FieldPolicy<any> | FieldReadFunction<any>;
  bicycle?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  destination?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  matkahuoltoDestinationPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  matkahuoltoDestinationPlaceName?: FieldPolicy<any> | FieldReadFunction<any>;
  notAvailableReasons?: FieldPolicy<any> | FieldReadFunction<any>;
  offerGroupId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceFeePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  stationAbbreviation?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusJourneyOfferGroupKeySpecifier = (
  | 'arrivalTime'
  | 'availableForSale'
  | 'departureTime'
  | 'destination'
  | 'key'
  | 'legs'
  | 'notAvailableReasons'
  | 'offers'
  | 'price'
  | 'serviceFeePrice'
  | BusJourneyOfferGroupKeySpecifier
)[];
export type BusJourneyOfferGroupFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  availableForSale?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  destination?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  legs?: FieldPolicy<any> | FieldReadFunction<any>;
  notAvailableReasons?: FieldPolicy<any> | FieldReadFunction<any>;
  offers?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceFeePrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusJourneyOfferLegKeySpecifier = (
  | 'arrivalTime'
  | 'departureTime'
  | 'fromPlaceId'
  | 'fromPlaceName'
  | 'fromStopAreaName'
  | 'fromStopName'
  | 'lineName'
  | 'lineOperator'
  | 'toPlaceId'
  | 'toPlaceName'
  | 'toStopAreaName'
  | 'toStopName'
  | BusJourneyOfferLegKeySpecifier
)[];
export type BusJourneyOfferLegFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPlaceName?: FieldPolicy<any> | FieldReadFunction<any>;
  fromStopAreaName?: FieldPolicy<any> | FieldReadFunction<any>;
  fromStopName?: FieldPolicy<any> | FieldReadFunction<any>;
  lineName?: FieldPolicy<any> | FieldReadFunction<any>;
  lineOperator?: FieldPolicy<any> | FieldReadFunction<any>;
  toPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  toPlaceName?: FieldPolicy<any> | FieldReadFunction<any>;
  toStopAreaName?: FieldPolicy<any> | FieldReadFunction<any>;
  toStopName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type BusinessInfoKeySpecifier = (
  | 'businessId'
  | 'city'
  | 'name'
  | 'postalCode'
  | 'street'
  | BusinessInfoKeySpecifier
)[];
export type BusinessInfoFieldPolicy = {
  businessId?: FieldPolicy<any> | FieldReadFunction<any>;
  city?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  street?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CabinDetailsKeySpecifier = (
  | 'attribute'
  | 'discountCategory'
  | 'price'
  | 'size'
  | 'type'
  | CabinDetailsKeySpecifier
)[];
export type CabinDetailsFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  discountCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CabinPassengerKeySpecifier = (
  | 'passengerLegId'
  | 'reservation'
  | 'type'
  | CabinPassengerKeySpecifier
)[];
export type CabinPassengerFieldPolicy = {
  passengerLegId?: FieldPolicy<any> | FieldReadFunction<any>;
  reservation?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CabinReservationKeySpecifier = (
  | 'attribute'
  | 'cabinId'
  | 'compartmentNumber'
  | 'floor'
  | 'id'
  | 'placeNumber'
  | 'position'
  | 'validUntil'
  | 'wagonNumber'
  | CabinReservationKeySpecifier
)[];
export type CabinReservationFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  cabinId?: FieldPolicy<any> | FieldReadFunction<any>;
  compartmentNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  floor?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  placeNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
  wagonNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CalendarKeySpecifier = ('days' | CalendarKeySpecifier)[];
export type CalendarFieldPolicy = {
  days?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelJourneyResponseKeySpecifier = (
  | 'reason'
  | 'status'
  | CancelJourneyResponseKeySpecifier
)[];
export type CancelJourneyResponseFieldPolicy = {
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelJourneysResponseKeySpecifier = (
  | 'needsManualRefunding'
  | 'reason'
  | 'status'
  | CancelJourneysResponseKeySpecifier
)[];
export type CancelJourneysResponseFieldPolicy = {
  needsManualRefunding?: FieldPolicy<any> | FieldReadFunction<any>;
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelResponseKeySpecifier = ('reason' | 'status' | CancelResponseKeySpecifier)[];
export type CancelResponseFieldPolicy = {
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancelVehicleResponseKeySpecifier = (
  | 'reason'
  | 'status'
  | CancelVehicleResponseKeySpecifier
)[];
export type CancelVehicleResponseFieldPolicy = {
  reason?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancellationInsuranceKeySpecifier = (
  | 'id'
  | 'placePrice'
  | 'price'
  | 'product'
  | 'ticketClass'
  | 'totalPrice'
  | 'validUntil'
  | CancellationInsuranceKeySpecifier
)[];
export type CancellationInsuranceFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  placePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketClass?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancellationInsuranceCoverageKeySpecifier = (
  | 'outbound'
  | 'return'
  | CancellationInsuranceCoverageKeySpecifier
)[];
export type CancellationInsuranceCoverageFieldPolicy = {
  outbound?: FieldPolicy<any> | FieldReadFunction<any>;
  return?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancellationInsuranceOfferKeySpecifier = (
  | 'coverage'
  | 'expiresAt'
  | 'id'
  | 'price'
  | CancellationInsuranceOfferKeySpecifier
)[];
export type CancellationInsuranceOfferFieldPolicy = {
  coverage?: FieldPolicy<any> | FieldReadFunction<any>;
  expiresAt?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancellationSummaryKeySpecifier = (
  | 'bookingCancellationSummaryList'
  | 'nonRefundableAmount'
  | 'refundAmount'
  | 'status'
  | CancellationSummaryKeySpecifier
)[];
export type CancellationSummaryFieldPolicy = {
  bookingCancellationSummaryList?: FieldPolicy<any> | FieldReadFunction<any>;
  nonRefundableAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  refundAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CancellationSummaryListItemKeySpecifier = (
  | 'amount'
  | 'amountToRefund'
  | 'cost'
  | 'name'
  | CancellationSummaryListItemKeySpecifier
)[];
export type CancellationSummaryListItemFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  amountToRefund?: FieldPolicy<any> | FieldReadFunction<any>;
  cost?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CarKeySpecifier = (
  | 'height'
  | 'length'
  | 'model'
  | 'registerNumber'
  | 'width'
  | CarKeySpecifier
)[];
export type CarFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>;
  length?: FieldPolicy<any> | FieldReadFunction<any>;
  model?: FieldPolicy<any> | FieldReadFunction<any>;
  registerNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  width?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ChangeSeatsErrorKeySpecifier = ('code' | ChangeSeatsErrorKeySpecifier)[];
export type ChangeSeatsErrorFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CiamCodeExchangeResponseKeySpecifier = (
  | 'user'
  | CiamCodeExchangeResponseKeySpecifier
)[];
export type CiamCodeExchangeResponseFieldPolicy = {
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CiamCodeExchangeUserKeySpecifier = (
  | 'companyIds'
  | 'customerId'
  | CiamCodeExchangeUserKeySpecifier
)[];
export type CiamCodeExchangeUserFieldPolicy = {
  companyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CiamResetPasswordResponseKeySpecifier = (
  | 'success'
  | CiamResetPasswordResponseKeySpecifier
)[];
export type CiamResetPasswordResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CiamUserInfoKeySpecifier = (
  | 'activeCompanyId'
  | 'activeCompanyName'
  | 'activeEmail'
  | 'customerId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | CiamUserInfoKeySpecifier
)[];
export type CiamUserInfoFieldPolicy = {
  activeCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ClaimOrderResponseKeySpecifier = ('status' | ClaimOrderResponseKeySpecifier)[];
export type ClaimOrderResponseFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CodeExchangeResponseKeySpecifier = (
  | 'success'
  | 'user'
  | CodeExchangeResponseKeySpecifier
)[];
export type CodeExchangeResponseFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CommercialTrainKeySpecifier = (
  | 'departureTime'
  | 'fromStation'
  | 'toStation'
  | 'trainNumber'
  | 'trainType'
  | 'type'
  | CommercialTrainKeySpecifier
)[];
export type CommercialTrainFieldPolicy = {
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  fromStation?: FieldPolicy<any> | FieldReadFunction<any>;
  toStation?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  trainType?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyKeySpecifier = ('id' | 'name' | CompanyKeySpecifier)[];
export type CompanyFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyRoleKeySpecifier = ('canPurchase' | 'id' | 'role' | CompanyRoleKeySpecifier)[];
export type CompanyRoleFieldPolicy = {
  canPurchase?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CompanyWithRoleKeySpecifier = (
  | 'name'
  | 'name2'
  | 'roles'
  | CompanyWithRoleKeySpecifier
)[];
export type CompanyWithRoleFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  name2?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConsumerUserDetailsKeySpecifier = (
  | 'car'
  | 'cityName'
  | 'consentB2BMarketing'
  | 'consentCustomerResearch'
  | 'consentMarketing'
  | 'countryCode'
  | 'dateOfBirth'
  | 'emailAddress'
  | 'firstName'
  | 'gender'
  | 'lastName'
  | 'mobilePhoneNumber'
  | 'otherPhoneNumber'
  | 'postalCode'
  | 'serviceLanguage'
  | 'streetName'
  | ConsumerUserDetailsKeySpecifier
)[];
export type ConsumerUserDetailsFieldPolicy = {
  car?: FieldPolicy<any> | FieldReadFunction<any>;
  cityName?: FieldPolicy<any> | FieldReadFunction<any>;
  consentB2BMarketing?: FieldPolicy<any> | FieldReadFunction<any>;
  consentCustomerResearch?: FieldPolicy<any> | FieldReadFunction<any>;
  consentMarketing?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCode?: FieldPolicy<any> | FieldReadFunction<any>;
  dateOfBirth?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  gender?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  otherPhoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  postalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  streetName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ConsumerUserInfoKeySpecifier = (
  | 'activeCompanyId'
  | 'activeCompanyName'
  | 'activeEmail'
  | 'customerId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | ConsumerUserInfoKeySpecifier
)[];
export type ConsumerUserInfoFieldPolicy = {
  activeCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CorporateOfferKeySpecifier = (
  | 'canCancelBeforeDeparture'
  | 'changeFreeOfCharge'
  | 'includedProducts'
  | 'nonRefundableAmount'
  | 'placeType'
  | 'totalPrice'
  | 'type'
  | CorporateOfferKeySpecifier
)[];
export type CorporateOfferFieldPolicy = {
  canCancelBeforeDeparture?: FieldPolicy<any> | FieldReadFunction<any>;
  changeFreeOfCharge?: FieldPolicy<any> | FieldReadFunction<any>;
  includedProducts?: FieldPolicy<any> | FieldReadFunction<any>;
  nonRefundableAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  placeType?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CorporateProductOptionKeySpecifier = (
  | 'id'
  | 'price'
  | 'type'
  | CorporateProductOptionKeySpecifier
)[];
export type CorporateProductOptionFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CorporateUserDetailsKeySpecifier = (
  | 'emailAddress'
  | 'firstName'
  | 'lastName'
  | CorporateUserDetailsKeySpecifier
)[];
export type CorporateUserDetailsFieldPolicy = {
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CorporateUserInfoKeySpecifier = (
  | 'activeCompanyId'
  | 'activeCompanyName'
  | 'activeEmail'
  | 'customerId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | CorporateUserInfoKeySpecifier
)[];
export type CorporateUserInfoFieldPolicy = {
  activeCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CreatorKeySpecifier = ('companyType' | CreatorKeySpecifier)[];
export type CreatorFieldPolicy = {
  companyType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CrmCompanyKeySpecifier = (
  | 'additionalInformationForCustomerService'
  | 'addressCity'
  | 'addressCountry'
  | 'addressPOBox'
  | 'addressPostalCode'
  | 'addressStreet'
  | 'businessId'
  | 'companyCRMId'
  | 'companyName'
  | 'companyNameRow2'
  | 'companyNumber'
  | 'department'
  | 'departmentRow2'
  | 'discount'
  | 'discountCodesInUse'
  | 'eInvoiceAddress'
  | 'invoiceReference'
  | 'invoicedCompanyName'
  | 'invoicedCompanyNameRow2'
  | 'invoicingAddressCity'
  | 'invoicingAddressCountry'
  | 'invoicingAddressPOBox'
  | 'invoicingAddressPostalCode'
  | 'invoicingAddressStreet'
  | 'invoicingAllowed'
  | 'invoicingEmailAddress'
  | 'invoicingInterval'
  | 'invoicingLanguage'
  | 'invoicingMethod'
  | 'isActive'
  | 'lastUpdated'
  | 'paymentTerm'
  | 'taxNumber'
  | CrmCompanyKeySpecifier
)[];
export type CrmCompanyFieldPolicy = {
  additionalInformationForCustomerService?: FieldPolicy<any> | FieldReadFunction<any>;
  addressCity?: FieldPolicy<any> | FieldReadFunction<any>;
  addressCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  addressPOBox?: FieldPolicy<any> | FieldReadFunction<any>;
  addressPostalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  addressStreet?: FieldPolicy<any> | FieldReadFunction<any>;
  businessId?: FieldPolicy<any> | FieldReadFunction<any>;
  companyCRMId?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  companyNameRow2?: FieldPolicy<any> | FieldReadFunction<any>;
  companyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  department?: FieldPolicy<any> | FieldReadFunction<any>;
  departmentRow2?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  discountCodesInUse?: FieldPolicy<any> | FieldReadFunction<any>;
  eInvoiceAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  invoiceReference?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicedCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicedCompanyNameRow2?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAddressCity?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAddressCountry?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAddressPOBox?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAddressPostalCode?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAddressStreet?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingAllowed?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingEmailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingInterval?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicingMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  isActive?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentTerm?: FieldPolicy<any> | FieldReadFunction<any>;
  taxNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CrmCompanyCreateKeySpecifier = (
  | 'businessId'
  | 'companyName'
  | 'companyNumber'
  | 'status'
  | CrmCompanyCreateKeySpecifier
)[];
export type CrmCompanyCreateFieldPolicy = {
  businessId?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  companyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CrmCustomerKeySpecifier = (
  | 'customerId'
  | 'emailAddress'
  | 'firstName'
  | 'lastName'
  | CrmCustomerKeySpecifier
)[];
export type CrmCustomerFieldPolicy = {
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type CrmUserKeySpecifier = (
  | 'b2bTravellerId'
  | 'companyName'
  | 'companyNumber'
  | 'createdBy'
  | 'createdOn'
  | 'customer'
  | 'emailAddress'
  | 'invitationLanguage'
  | 'invitationTimeToLive'
  | 'lastUpdated'
  | 'role'
  | 'status'
  | CrmUserKeySpecifier
)[];
export type CrmUserFieldPolicy = {
  b2bTravellerId?: FieldPolicy<any> | FieldReadFunction<any>;
  companyName?: FieldPolicy<any> | FieldReadFunction<any>;
  companyNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  createdBy?: FieldPolicy<any> | FieldReadFunction<any>;
  createdOn?: FieldPolicy<any> | FieldReadFunction<any>;
  customer?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  invitationLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
  invitationTimeToLive?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUpdated?: FieldPolicy<any> | FieldReadFunction<any>;
  role?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DayKeySpecifier = ('date' | 'error' | 'id' | 'price' | DayKeySpecifier)[];
export type DayFieldPolicy = {
  date?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DeleteCompanyUserStatusKeySpecifier = (
  | 'success'
  | DeleteCompanyUserStatusKeySpecifier
)[];
export type DeleteCompanyUserStatusFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DiscountKeySpecifier = (
  | 'codes'
  | 'discountPercentage'
  | 'ids'
  | DiscountKeySpecifier
)[];
export type DiscountFieldPolicy = {
  codes?: FieldPolicy<any> | FieldReadFunction<any>;
  discountPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  ids?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type DiscountCodeKeySpecifier = (
  | 'travelEndDate'
  | 'travelStartDate'
  | 'type'
  | 'usageType'
  | DiscountCodeKeySpecifier
)[];
export type DiscountCodeFieldPolicy = {
  travelEndDate?: FieldPolicy<any> | FieldReadFunction<any>;
  travelStartDate?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  usageType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmployeeUserDetailsKeySpecifier = (
  | 'alias'
  | 'department'
  | 'emailAddress'
  | 'employeeId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | EmployeeUserDetailsKeySpecifier
)[];
export type EmployeeUserDetailsFieldPolicy = {
  alias?: FieldPolicy<any> | FieldReadFunction<any>;
  department?: FieldPolicy<any> | FieldReadFunction<any>;
  emailAddress?: FieldPolicy<any> | FieldReadFunction<any>;
  employeeId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type EmployeeUserInfoKeySpecifier = (
  | 'activeCompanyId'
  | 'activeCompanyName'
  | 'activeEmail'
  | 'customerId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | EmployeeUserInfoKeySpecifier
)[];
export type EmployeeUserInfoFieldPolicy = {
  activeCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ErrorKeySpecifier = ('description' | 'name' | ErrorKeySpecifier)[];
export type ErrorFieldPolicy = {
  description?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExternalJourneyLegKeySpecifier = (
  | 'arrivalTime'
  | 'departureTime'
  | 'facilities'
  | 'fromPlaceId'
  | 'fromPlaceName'
  | 'fromStopAreaName'
  | 'fromStopName'
  | 'lineName'
  | 'lineOperator'
  | 'toPlaceId'
  | 'toPlaceName'
  | 'toStopAreaName'
  | 'toStopName'
  | ExternalJourneyLegKeySpecifier
)[];
export type ExternalJourneyLegFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  facilities?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  fromPlaceName?: FieldPolicy<any> | FieldReadFunction<any>;
  fromStopAreaName?: FieldPolicy<any> | FieldReadFunction<any>;
  fromStopName?: FieldPolicy<any> | FieldReadFunction<any>;
  lineName?: FieldPolicy<any> | FieldReadFunction<any>;
  lineOperator?: FieldPolicy<any> | FieldReadFunction<any>;
  toPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  toPlaceName?: FieldPolicy<any> | FieldReadFunction<any>;
  toStopAreaName?: FieldPolicy<any> | FieldReadFunction<any>;
  toStopName?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExternalServiceKeySpecifier = (
  | 'busConnectionKey'
  | 'details'
  | 'fee'
  | 'id'
  | 'isBusConnectionOffer'
  | 'isExternalBusService'
  | 'price'
  | 'product'
  | ExternalServiceKeySpecifier
)[];
export type ExternalServiceFieldPolicy = {
  busConnectionKey?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  fee?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  isBusConnectionOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  isExternalBusService?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ExternalServiceDetailsKeySpecifier = (
  | 'bicycle'
  | 'externalJourneyLegs'
  | 'transferStation'
  | ExternalServiceDetailsKeySpecifier
)[];
export type ExternalServiceDetailsFieldPolicy = {
  bicycle?: FieldPolicy<any> | FieldReadFunction<any>;
  externalJourneyLegs?: FieldPolicy<any> | FieldReadFunction<any>;
  transferStation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FailedToFetchResponseKeySpecifier = ('message' | FailedToFetchResponseKeySpecifier)[];
export type FailedToFetchResponseFieldPolicy = {
  message?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FinalizePaymentResponseKeySpecifier = (
  | 'orderNumber'
  | 'paymentId'
  | 'paymentMethod'
  | 'paymentRefNum'
  | FinalizePaymentResponseKeySpecifier
)[];
export type FinalizePaymentResponseFieldPolicy = {
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentRefNum?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type FormUploadResponseKeySpecifier = ('status' | FormUploadResponseKeySpecifier)[];
export type FormUploadResponseFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupKeySpecifier = ('state' | 'ticketViews' | GroupKeySpecifier)[];
export type GroupFieldPolicy = {
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketViews?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupJourneyKeySpecifier = (
  | 'additionalProducts'
  | 'arrivalStation'
  | 'arrivalTime'
  | 'avecraOrders'
  | 'avecraOrdersPerLeg'
  | 'cabins'
  | 'cancelStatus'
  | 'corporate'
  | 'departureStation'
  | 'departureTime'
  | 'groupId'
  | 'hasJourneyStarted'
  | 'idWithDepartureStation'
  | 'isCoronaTestRecommended'
  | 'masterTicketViewUrl'
  | 'orderNumber'
  | 'passengerJourneys'
  | 'summary'
  | 'trafficType'
  | 'usageEndTime'
  | 'usageStartTime'
  | 'vehicles'
  | GroupJourneyKeySpecifier
)[];
export type GroupJourneyFieldPolicy = {
  additionalProducts?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  avecraOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  avecraOrdersPerLeg?: FieldPolicy<any> | FieldReadFunction<any>;
  cabins?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  corporate?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  hasJourneyStarted?: FieldPolicy<any> | FieldReadFunction<any>;
  idWithDepartureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  isCoronaTestRecommended?: FieldPolicy<any> | FieldReadFunction<any>;
  masterTicketViewUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneys?: FieldPolicy<any> | FieldReadFunction<any>;
  summary?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficType?: FieldPolicy<any> | FieldReadFunction<any>;
  usageEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usageStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupJourneyCabinKeySpecifier = (
  | 'attribute'
  | 'id'
  | 'legKey'
  | 'passengers'
  | 'size'
  | 'totalPrice'
  | 'type'
  | GroupJourneyCabinKeySpecifier
)[];
export type GroupJourneyCabinFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legKey?: FieldPolicy<any> | FieldReadFunction<any>;
  passengers?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupSalesJourneyOptionKeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'availability'
  | 'departureStation'
  | 'departureTime'
  | 'error'
  | 'highestLegTrainFill'
  | 'id'
  | 'legs'
  | 'passengers'
  | 'totalPrice'
  | GroupSalesJourneyOptionKeySpecifier
)[];
export type GroupSalesJourneyOptionFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  highestLegTrainFill?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legs?: FieldPolicy<any> | FieldReadFunction<any>;
  passengers?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupSalesJourneyOptionPassengerKeySpecifier = (
  | 'amount'
  | 'type'
  | GroupSalesJourneyOptionPassengerKeySpecifier
)[];
export type GroupSalesJourneyOptionPassengerFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type GroupTicketViewKeySpecifier = (
  | 'arrivalStation'
  | 'departureStation'
  | 'url'
  | GroupTicketViewKeySpecifier
)[];
export type GroupTicketViewFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type HealthKeySpecifier = ('status' | 'version' | HealthKeySpecifier)[];
export type HealthFieldPolicy = {
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  version?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type IncidentMessageKeySpecifier = (
  | 'body'
  | 'created'
  | 'id'
  | 'incidentId'
  | 'language'
  | 'start'
  | 'subject'
  | 'tag'
  | IncidentMessageKeySpecifier
)[];
export type IncidentMessageFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  start?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceKeySpecifier = (
  | 'editable'
  | 'passengers'
  | 'singleInvoice'
  | InvoiceKeySpecifier
)[];
export type InvoiceFieldPolicy = {
  editable?: FieldPolicy<any> | FieldReadFunction<any>;
  passengers?: FieldPolicy<any> | FieldReadFunction<any>;
  singleInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoiceNotEditableKeySpecifier = (
  | 'invoiceNotEditable'
  | InvoiceNotEditableKeySpecifier
)[];
export type InvoiceNotEditableFieldPolicy = {
  invoiceNotEditable?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoicePassengerKeySpecifier = (
  | 'journeyId'
  | 'reference'
  | InvoicePassengerKeySpecifier
)[];
export type InvoicePassengerFieldPolicy = {
  journeyId?: FieldPolicy<any> | FieldReadFunction<any>;
  reference?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type InvoicePaymentResponseKeySpecifier = ('payment' | InvoicePaymentResponseKeySpecifier)[];
export type InvoicePaymentResponseFieldPolicy = {
  payment?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyCancellationSummaryKeySpecifier = (
  | 'refundAmount'
  | 'status'
  | JourneyCancellationSummaryKeySpecifier
)[];
export type JourneyCancellationSummaryFieldPolicy = {
  refundAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyFeeKeySpecifier = ('id' | 'price' | 'product' | JourneyFeeKeySpecifier)[];
export type JourneyFeeFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyLegKeySpecifier = (
  | 'additionalReservationOffers'
  | 'additionalReservationServices'
  | 'additionalReservations'
  | 'arrivalStation'
  | 'arrivalTime'
  | 'avecraOffers'
  | 'avecraServices'
  | 'bicycles'
  | 'breakfastOffers'
  | 'commercialTrainNumber'
  | 'commuterLineIdentifier'
  | 'departureStation'
  | 'departureTime'
  | 'id'
  | 'legKey'
  | 'offers'
  | 'reservation'
  | 'services'
  | 'ticketViewUrl'
  | 'trafficType'
  | 'trainFillPercentage'
  | 'trainInfo'
  | 'trainNumber'
  | 'trainType'
  | JourneyLegKeySpecifier
)[];
export type JourneyLegFieldPolicy = {
  additionalReservationOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalReservationServices?: FieldPolicy<any> | FieldReadFunction<any>;
  additionalReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  avecraOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  avecraServices?: FieldPolicy<any> | FieldReadFunction<any>;
  bicycles?: FieldPolicy<any> | FieldReadFunction<any>;
  breakfastOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  commuterLineIdentifier?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legKey?: FieldPolicy<any> | FieldReadFunction<any>;
  offers?: FieldPolicy<any> | FieldReadFunction<any>;
  reservation?: FieldPolicy<any> | FieldReadFunction<any>;
  services?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketViewUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficType?: FieldPolicy<any> | FieldReadFunction<any>;
  trainFillPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  trainInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  trainType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyLegOfferKeySpecifier = (
  | 'id'
  | 'price'
  | 'product'
  | 'ticketClass'
  | JourneyLegOfferKeySpecifier
)[];
export type JourneyLegOfferFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketClass?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyOptionKeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'availability'
  | 'departureStation'
  | 'departureTime'
  | 'discount'
  | 'error'
  | 'highestLegTrainFill'
  | 'id'
  | 'legs'
  | 'passengers'
  | 'totalPrice'
  | JourneyOptionKeySpecifier
)[];
export type JourneyOptionFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  error?: FieldPolicy<any> | FieldReadFunction<any>;
  highestLegTrainFill?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legs?: FieldPolicy<any> | FieldReadFunction<any>;
  passengers?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyOptionLegKeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'commercialLineIdentifier'
  | 'departureStation'
  | 'departureTime'
  | 'id'
  | 'legKey'
  | 'productAttributes'
  | 'trainAttributes'
  | 'trainFillPercentage'
  | 'trainNumber'
  | 'trainType'
  | 'type'
  | JourneyOptionLegKeySpecifier
)[];
export type JourneyOptionLegFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLineIdentifier?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legKey?: FieldPolicy<any> | FieldReadFunction<any>;
  productAttributes?: FieldPolicy<any> | FieldReadFunction<any>;
  trainAttributes?: FieldPolicy<any> | FieldReadFunction<any>;
  trainFillPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  trainType?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyOptionPassengerKeySpecifier = (
  | 'offers'
  | 'type'
  | JourneyOptionPassengerKeySpecifier
)[];
export type JourneyOptionPassengerFieldPolicy = {
  offers?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type JourneyPassengerKeySpecifier = (
  | 'eligibleForCancellationInsurance'
  | 'linkedToPassenger'
  | 'passengerLinkType'
  | 'type'
  | JourneyPassengerKeySpecifier
)[];
export type JourneyPassengerFieldPolicy = {
  eligibleForCancellationInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  linkedToPassenger?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerLinkType?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LegExtraInfoKeySpecifier = ('trainInfo' | 'wagons' | LegExtraInfoKeySpecifier)[];
export type LegExtraInfoFieldPolicy = {
  trainInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  wagons?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LegStopKeySpecifier = (
  | 'arrivalTime'
  | 'departureTime'
  | 'station'
  | LegStopKeySpecifier
)[];
export type LegStopFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  station?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LegWithMealsKeySpecifier = (
  | 'arrivalStation'
  | 'departureStation'
  | 'meals'
  | LegWithMealsKeySpecifier
)[];
export type LegWithMealsFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  meals?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LineItemKeySpecifier = (
  | 'count'
  | 'details'
  | 'product'
  | 'productAttribute'
  | 'productSubItems'
  | 'productType'
  | 'serviceProvider'
  | 'subItems'
  | 'totalPrice'
  | 'unitPrice'
  | 'values'
  | LineItemKeySpecifier
)[];
export type LineItemFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  productAttribute?: FieldPolicy<any> | FieldReadFunction<any>;
  productSubItems?: FieldPolicy<any> | FieldReadFunction<any>;
  productType?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceProvider?: FieldPolicy<any> | FieldReadFunction<any>;
  subItems?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  values?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LineItemProductSubItemKeySpecifier = (
  | 'count'
  | 'productType'
  | 'totalPrice'
  | 'type'
  | 'unitPrice'
  | 'values'
  | LineItemProductSubItemKeySpecifier
)[];
export type LineItemProductSubItemFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  productType?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unitPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  values?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LineItemSubItemKeySpecifier = (
  | 'count'
  | 'key'
  | 'type'
  | LineItemSubItemKeySpecifier
)[];
export type LineItemSubItemFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResponseKeySpecifier = ('user' | LoginResponseKeySpecifier)[];
export type LoginResponseFieldPolicy = {
  user?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type LoginResponseV2KeySpecifier = (
  | 'userCarOwner'
  | 'userCity'
  | 'userEmailPermit'
  | 'userId'
  | 'userIdentity'
  | 'userLanguage'
  | LoginResponseV2KeySpecifier
)[];
export type LoginResponseV2FieldPolicy = {
  userCarOwner?: FieldPolicy<any> | FieldReadFunction<any>;
  userCity?: FieldPolicy<any> | FieldReadFunction<any>;
  userEmailPermit?: FieldPolicy<any> | FieldReadFunction<any>;
  userId?: FieldPolicy<any> | FieldReadFunction<any>;
  userIdentity?: FieldPolicy<any> | FieldReadFunction<any>;
  userLanguage?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MatakahuoltoDestinationKeySpecifier = (
  | 'matkahuoltoPlaceId'
  | MatakahuoltoDestinationKeySpecifier
)[];
export type MatakahuoltoDestinationFieldPolicy = {
  matkahuoltoPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MealKeySpecifier = (
  | 'count'
  | 'drink'
  | 'foodOption'
  | 'passengerLegIds'
  | 'product'
  | MealKeySpecifier
)[];
export type MealFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  drink?: FieldPolicy<any> | FieldReadFunction<any>;
  foodOption?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerLegIds?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiTicketKeySpecifier = (
  | 'arrivalStation'
  | 'bookingId'
  | 'departureStation'
  | 'orderNumber'
  | 'passengerType'
  | 'payments'
  | 'price'
  | 'seatClass'
  | 'ticketCount'
  | 'type'
  | 'usageEndTime'
  | 'usageStartTime'
  | 'usagesLeft'
  | MultiTicketKeySpecifier
)[];
export type MultiTicketFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  bookingId?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seatClass?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketCount?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  usageEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usageStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usagesLeft?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiTicketUsageKeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'departureStation'
  | 'departureTime'
  | 'details'
  | 'orderNumber'
  | 'passengerJourneyId'
  | MultiTicketUsageKeySpecifier
)[];
export type MultiTicketUsageFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiTicketUsageDetailsKeySpecifier = (
  | 'additionalProducts'
  | 'passengerType'
  | 'paymentId'
  | MultiTicketUsageDetailsKeySpecifier
)[];
export type MultiTicketUsageDetailsFieldPolicy = {
  additionalProducts?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiTicketUsagesResponseKeySpecifier = (
  | 'items'
  | 'total'
  | MultiTicketUsagesResponseKeySpecifier
)[];
export type MultiTicketUsagesResponseFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MultiTicketsKeySpecifier = ('items' | MultiTicketsKeySpecifier)[];
export type MultiTicketsFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type MutationKeySpecifier = (
  | 'addBicycles'
  | 'addCabinOption'
  | 'addCancellationInsuranceToOrder'
  | 'addExternalAvecraService'
  | 'addExternalMatkahuoltoService'
  | 'addReturnToSale'
  | 'addVehicle'
  | 'addVehicleAndDriverDetails'
  | 'applyDiscountCodes'
  | 'cancelJourneys'
  | 'changePlaceReservations'
  | 'changeSeats'
  | 'ciamInitLogin'
  | 'ciamResetPassword'
  | 'ciamTokens'
  | 'claimOrder'
  | 'claimSale'
  | 'codeExchange'
  | 'createCompany'
  | 'createNewBundleSale'
  | 'createNewSale'
  | 'createTravelCodes'
  | 'deleteCompanyUser'
  | 'finalizePayment'
  | 'finalizePayment_v2'
  | 'getUploadUrlForAttachment'
  | 'inviteCompanyUser'
  | 'invoicePayment'
  | 'loginV2'
  | 'logoutV2'
  | 'preparePayment'
  | 'reCreateSalesSession'
  | 'releaseSale'
  | 'removeCabinOption'
  | 'removeCancellationInsuranceFromOrder'
  | 'removeExternalService'
  | 'removeTravelCodes'
  | 'removeVehicleCancellationInsuranceFromOrder'
  | 'replaceJourney'
  | 'submitForm'
  | 'testLogin'
  | 'updateCabins'
  | 'updateCompany'
  | 'updateInvoice'
  | 'updateUserDetails'
  | MutationKeySpecifier
)[];
export type MutationFieldPolicy = {
  addBicycles?: FieldPolicy<any> | FieldReadFunction<any>;
  addCabinOption?: FieldPolicy<any> | FieldReadFunction<any>;
  addCancellationInsuranceToOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  addExternalAvecraService?: FieldPolicy<any> | FieldReadFunction<any>;
  addExternalMatkahuoltoService?: FieldPolicy<any> | FieldReadFunction<any>;
  addReturnToSale?: FieldPolicy<any> | FieldReadFunction<any>;
  addVehicle?: FieldPolicy<any> | FieldReadFunction<any>;
  addVehicleAndDriverDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  applyDiscountCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelJourneys?: FieldPolicy<any> | FieldReadFunction<any>;
  changePlaceReservations?: FieldPolicy<any> | FieldReadFunction<any>;
  changeSeats?: FieldPolicy<any> | FieldReadFunction<any>;
  ciamInitLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  ciamResetPassword?: FieldPolicy<any> | FieldReadFunction<any>;
  ciamTokens?: FieldPolicy<any> | FieldReadFunction<any>;
  claimOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  claimSale?: FieldPolicy<any> | FieldReadFunction<any>;
  codeExchange?: FieldPolicy<any> | FieldReadFunction<any>;
  createCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  createNewBundleSale?: FieldPolicy<any> | FieldReadFunction<any>;
  createNewSale?: FieldPolicy<any> | FieldReadFunction<any>;
  createTravelCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  deleteCompanyUser?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizePayment?: FieldPolicy<any> | FieldReadFunction<any>;
  finalizePayment_v2?: FieldPolicy<any> | FieldReadFunction<any>;
  getUploadUrlForAttachment?: FieldPolicy<any> | FieldReadFunction<any>;
  inviteCompanyUser?: FieldPolicy<any> | FieldReadFunction<any>;
  invoicePayment?: FieldPolicy<any> | FieldReadFunction<any>;
  loginV2?: FieldPolicy<any> | FieldReadFunction<any>;
  logoutV2?: FieldPolicy<any> | FieldReadFunction<any>;
  preparePayment?: FieldPolicy<any> | FieldReadFunction<any>;
  reCreateSalesSession?: FieldPolicy<any> | FieldReadFunction<any>;
  releaseSale?: FieldPolicy<any> | FieldReadFunction<any>;
  removeCabinOption?: FieldPolicy<any> | FieldReadFunction<any>;
  removeCancellationInsuranceFromOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  removeExternalService?: FieldPolicy<any> | FieldReadFunction<any>;
  removeTravelCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  removeVehicleCancellationInsuranceFromOrder?: FieldPolicy<any> | FieldReadFunction<any>;
  replaceJourney?: FieldPolicy<any> | FieldReadFunction<any>;
  submitForm?: FieldPolicy<any> | FieldReadFunction<any>;
  testLogin?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCabins?: FieldPolicy<any> | FieldReadFunction<any>;
  updateCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  updateInvoice?: FieldPolicy<any> | FieldReadFunction<any>;
  updateUserDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NoConnectionsKeySpecifier = ('noConnectionsReason' | NoConnectionsKeySpecifier)[];
export type NoConnectionsFieldPolicy = {
  noConnectionsReason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NoOfferKeySpecifier = ('noOfferReason' | NoOfferKeySpecifier)[];
export type NoOfferFieldPolicy = {
  noOfferReason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type NotLoggedInKeySpecifier = ('notLoggedIn' | NotLoggedInKeySpecifier)[];
export type NotLoggedInFieldPolicy = {
  notLoggedIn?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OfferKeySpecifier = ('id' | 'price' | OfferKeySpecifier)[];
export type OfferFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OptionAvailabilityKeySpecifier = (
  | 'accessibleCabinAvailability'
  | 'accessibleSeatAvailability'
  | 'cabinAvailability'
  | 'petCabinAvailability'
  | 'petSeatAvailability'
  | 'seatAvailability_v2'
  | OptionAvailabilityKeySpecifier
)[];
export type OptionAvailabilityFieldPolicy = {
  accessibleCabinAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  accessibleSeatAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  cabinAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  petCabinAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  petSeatAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  seatAvailability_v2?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderKeySpecifier = (
  | 'advanceFeePaidUntil'
  | 'allPaymentIds'
  | 'cancellationSummary'
  | 'creator'
  | 'finalFeePaidUntil'
  | 'group'
  | 'groupJourneys'
  | 'orderNumber'
  | 'owner'
  | 'paymentDue'
  | 'paymentIds'
  | 'payments'
  | 'price'
  | 'refundEligibility'
  | 'seriesTickets'
  | 'state'
  | 'validUntil'
  | OrderKeySpecifier
)[];
export type OrderFieldPolicy = {
  advanceFeePaidUntil?: FieldPolicy<any> | FieldReadFunction<any>;
  allPaymentIds?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  creator?: FieldPolicy<any> | FieldReadFunction<any>;
  finalFeePaidUntil?: FieldPolicy<any> | FieldReadFunction<any>;
  group?: FieldPolicy<any> | FieldReadFunction<any>;
  groupJourneys?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  owner?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentDue?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentIds?: FieldPolicy<any> | FieldReadFunction<any>;
  payments?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  refundEligibility?: FieldPolicy<any> | FieldReadFunction<any>;
  seriesTickets?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderHistoryEventKeySpecifier = (
  | 'changes'
  | 'id'
  | 'timestamp'
  | 'touchpoint'
  | 'travelAgentId'
  | OrderHistoryEventKeySpecifier
)[];
export type OrderHistoryEventFieldPolicy = {
  changes?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  timestamp?: FieldPolicy<any> | FieldReadFunction<any>;
  touchpoint?: FieldPolicy<any> | FieldReadFunction<any>;
  travelAgentId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderHistoryEventChangeKeySpecifier = (
  | 'count'
  | 'type'
  | OrderHistoryEventChangeKeySpecifier
)[];
export type OrderHistoryEventChangeFieldPolicy = {
  count?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderSummariesKeySpecifier = (
  | 'nextToken'
  | 'orderSummaries'
  | OrderSummariesKeySpecifier
)[];
export type OrderSummariesFieldPolicy = {
  nextToken?: FieldPolicy<any> | FieldReadFunction<any>;
  orderSummaries?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderSummaryKeySpecifier = (
  | 'additionalServices'
  | 'bundleTicketDetails'
  | 'journeys'
  | 'journeysV2'
  | 'lastUsageFrom'
  | 'oboCompany'
  | 'orderNumber'
  | 'state'
  | 'ticketType'
  | OrderSummaryKeySpecifier
)[];
export type OrderSummaryFieldPolicy = {
  additionalServices?: FieldPolicy<any> | FieldReadFunction<any>;
  bundleTicketDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  journeys?: FieldPolicy<any> | FieldReadFunction<any>;
  journeysV2?: FieldPolicy<any> | FieldReadFunction<any>;
  lastUsageFrom?: FieldPolicy<any> | FieldReadFunction<any>;
  oboCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderSummaryJourneyKeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'departureStation'
  | 'departureTime'
  | 'firstPassengerJourneyId'
  | 'numberOfPassengers'
  | 'passengerJourneyId'
  | 'passengerType'
  | 'state'
  | 'trafficType'
  | OrderSummaryJourneyKeySpecifier
)[];
export type OrderSummaryJourneyFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  firstPassengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  numberOfPassengers?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderSummaryJourneyV2KeySpecifier = (
  | 'arrivalStation'
  | 'arrivalTime'
  | 'departureStation'
  | 'departureTime'
  | 'passengerJourneyId'
  | 'passengerType'
  | 'state'
  | 'trafficType'
  | OrderSummaryJourneyV2KeySpecifier
)[];
export type OrderSummaryJourneyV2FieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OrderSummaryServiceKeySpecifier = (
  | 'amount'
  | 'passengerJourneyId'
  | 'serviceType'
  | 'state'
  | OrderSummaryServiceKeySpecifier
)[];
export type OrderSummaryServiceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  serviceType?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type OwnerKeySpecifier = ('companyId' | OwnerKeySpecifier)[];
export type OwnerFieldPolicy = {
  companyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaginationInfoKeySpecifier = (
  | 'limit'
  | 'offset'
  | 'total'
  | PaginationInfoKeySpecifier
)[];
export type PaginationInfoFieldPolicy = {
  limit?: FieldPolicy<any> | FieldReadFunction<any>;
  offset?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PassengerJourneyKeySpecifier = (
  | 'bicycleFees'
  | 'cancellationInsurance'
  | 'cancellationInsuranceOffer'
  | 'discountCodes'
  | 'durationMinutes'
  | 'externalServiceOffers'
  | 'externalServices'
  | 'id'
  | 'legs'
  | 'otherFees'
  | 'passenger'
  | 'petFees'
  | 'seasonTicketId'
  | 'seriesTicketId'
  | 'state'
  | 'travelPassTicketId'
  | PassengerJourneyKeySpecifier
)[];
export type PassengerJourneyFieldPolicy = {
  bicycleFees?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationInsuranceOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  discountCodes?: FieldPolicy<any> | FieldReadFunction<any>;
  durationMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  externalServiceOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  externalServices?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  legs?: FieldPolicy<any> | FieldReadFunction<any>;
  otherFees?: FieldPolicy<any> | FieldReadFunction<any>;
  passenger?: FieldPolicy<any> | FieldReadFunction<any>;
  petFees?: FieldPolicy<any> | FieldReadFunction<any>;
  seasonTicketId?: FieldPolicy<any> | FieldReadFunction<any>;
  seriesTicketId?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
  travelPassTicketId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PassengerOfferKeySpecifier = (
  | 'discountCategory'
  | 'legId'
  | 'price'
  | 'product'
  | PassengerOfferKeySpecifier
)[];
export type PassengerOfferFieldPolicy = {
  discountCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  legId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PassengerServiceKeySpecifier = (
  | 'attribute'
  | 'id'
  | 'placePrice'
  | 'placeType'
  | 'price'
  | 'product'
  | 'ticketClass'
  | 'totalPrice'
  | 'validUntil'
  | PassengerServiceKeySpecifier
)[];
export type PassengerServiceFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  placePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  placeType?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketClass?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentKeySpecifier = (
  | 'invoice'
  | 'orderNumber'
  | 'paymentId'
  | 'state'
  | PaymentKeySpecifier
)[];
export type PaymentFieldPolicy = {
  invoice?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  state?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentFinalizedKeySpecifier = ('success' | PaymentFinalizedKeySpecifier)[];
export type PaymentFinalizedFieldPolicy = {
  success?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentFinalizedWithInfoKeySpecifier = (
  | 'emails'
  | 'orderNumber'
  | 'paymentId'
  | 'paymentMethod'
  | 'paymentRefNum'
  | PaymentFinalizedWithInfoKeySpecifier
)[];
export type PaymentFinalizedWithInfoFieldPolicy = {
  emails?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentRefNum?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentMethodKeySpecifier = ('id' | 'status' | 'type' | PaymentMethodKeySpecifier)[];
export type PaymentMethodFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentStatusKeySpecifier = ('name' | 'status' | PaymentStatusKeySpecifier)[];
export type PaymentStatusFieldPolicy = {
  name?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PaymentStatusesResponseKeySpecifier = (
  | 'invoicingAllowed'
  | 'statuses'
  | PaymentStatusesResponseKeySpecifier
)[];
export type PaymentStatusesResponseFieldPolicy = {
  invoicingAllowed?: FieldPolicy<any> | FieldReadFunction<any>;
  statuses?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PreparePaymentResponseKeySpecifier = (
  | 'paymentId'
  | 'redirectBody'
  | 'redirectMethod'
  | 'redirectURL'
  | PreparePaymentResponseKeySpecifier
)[];
export type PreparePaymentResponseFieldPolicy = {
  paymentId?: FieldPolicy<any> | FieldReadFunction<any>;
  redirectBody?: FieldPolicy<any> | FieldReadFunction<any>;
  redirectMethod?: FieldPolicy<any> | FieldReadFunction<any>;
  redirectURL?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PresignedUrlResponseKeySpecifier = (
  | 'headers'
  | 'key'
  | 'url'
  | PresignedUrlResponseKeySpecifier
)[];
export type PresignedUrlResponseFieldPolicy = {
  headers?: FieldPolicy<any> | FieldReadFunction<any>;
  key?: FieldPolicy<any> | FieldReadFunction<any>;
  url?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PriceKeySpecifier = ('amount' | 'elements' | PriceKeySpecifier)[];
export type PriceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  elements?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type PriceElementKeySpecifier = (
  | 'amount'
  | 'payUntil'
  | 'status'
  | 'type'
  | 'unpaidAmount'
  | PriceElementKeySpecifier
)[];
export type PriceElementFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  payUntil?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  unpaidAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductKeySpecifier = ('amount' | 'product' | ProductKeySpecifier)[];
export type ProductFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProductAttributeKeySpecifier = (
  | 'attribute'
  | 'availability'
  | 'name'
  | ProductAttributeKeySpecifier
)[];
export type ProductAttributeFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ProfileKeySpecifier = ('companyRoles' | ProfileKeySpecifier)[];
export type ProfileFieldPolicy = {
  companyRoles?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type QueryKeySpecifier = (
  | 'avecraProductAvailability'
  | 'businessInfo'
  | 'cancellableJourneys'
  | 'cancellationInsuranceOffer'
  | 'cancellationSummary_v2'
  | 'commercialTrains'
  | 'company'
  | 'createBusJourneyOffers'
  | 'createCabinOptions'
  | 'createCabinWagonmapOffers'
  | 'createCorporateProductOffers'
  | 'createCorporateProductOffers_v2'
  | 'createSeatChangeOffers'
  | 'createSeriesBundleOffers'
  | 'crmCompany'
  | 'crmUsers'
  | 'generalIncidents'
  | 'health'
  | 'legExtraInfo'
  | 'multiTicket'
  | 'multiTicketUsages'
  | 'order'
  | 'orderHistory'
  | 'orders'
  | 'paymentStatuses'
  | 'priceCalendar'
  | 'profiles'
  | 'reportEmbedInfo'
  | 'reports'
  | 'salesSession'
  | 'searchJourney'
  | 'searchJourneyForGroup'
  | 'searchReplacementJourney'
  | 'station'
  | 'stations'
  | 'tempPaymentState'
  | 'trafficDisruptions_v2'
  | 'trainIncidents'
  | 'unpaidGroupOrders'
  | 'user'
  | 'userStatus'
  | 'vehicleCancellationInsuranceOffer'
  | 'wagonMapDataV3'
  | QueryKeySpecifier
)[];
export type QueryFieldPolicy = {
  avecraProductAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  businessInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellableJourneys?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationInsuranceOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationSummary_v2?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrains?: FieldPolicy<any> | FieldReadFunction<any>;
  company?: FieldPolicy<any> | FieldReadFunction<any>;
  createBusJourneyOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  createCabinOptions?: FieldPolicy<any> | FieldReadFunction<any>;
  createCabinWagonmapOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  createCorporateProductOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  createCorporateProductOffers_v2?: FieldPolicy<any> | FieldReadFunction<any>;
  createSeatChangeOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  createSeriesBundleOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  crmCompany?: FieldPolicy<any> | FieldReadFunction<any>;
  crmUsers?: FieldPolicy<any> | FieldReadFunction<any>;
  generalIncidents?: FieldPolicy<any> | FieldReadFunction<any>;
  health?: FieldPolicy<any> | FieldReadFunction<any>;
  legExtraInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  multiTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  multiTicketUsages?: FieldPolicy<any> | FieldReadFunction<any>;
  order?: FieldPolicy<any> | FieldReadFunction<any>;
  orderHistory?: FieldPolicy<any> | FieldReadFunction<any>;
  orders?: FieldPolicy<any> | FieldReadFunction<any>;
  paymentStatuses?: FieldPolicy<any> | FieldReadFunction<any>;
  priceCalendar?: FieldPolicy<any> | FieldReadFunction<any>;
  profiles?: FieldPolicy<any> | FieldReadFunction<any>;
  reportEmbedInfo?: FieldPolicy<any> | FieldReadFunction<any>;
  reports?: FieldPolicy<any> | FieldReadFunction<any>;
  salesSession?: FieldPolicy<any> | FieldReadFunction<any>;
  searchJourney?: FieldPolicy<any> | FieldReadFunction<any>;
  searchJourneyForGroup?: FieldPolicy<any> | FieldReadFunction<any>;
  searchReplacementJourney?: FieldPolicy<any> | FieldReadFunction<any>;
  station?: FieldPolicy<any> | FieldReadFunction<any>;
  stations?: FieldPolicy<any> | FieldReadFunction<any>;
  tempPaymentState?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficDisruptions_v2?: FieldPolicy<any> | FieldReadFunction<any>;
  trainIncidents?: FieldPolicy<any> | FieldReadFunction<any>;
  unpaidGroupOrders?: FieldPolicy<any> | FieldReadFunction<any>;
  user?: FieldPolicy<any> | FieldReadFunction<any>;
  userStatus?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleCancellationInsuranceOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  wagonMapDataV3?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReceiptKeySpecifier = ('grossAmount' | 'taxAmount' | ReceiptKeySpecifier)[];
export type ReceiptFieldPolicy = {
  grossAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  taxAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type RefundEligibilityKeySpecifier = (
  | 'eligibleForRefundWithCancellationInsurance'
  | 'nonEligibilityReason'
  | RefundEligibilityKeySpecifier
)[];
export type RefundEligibilityFieldPolicy = {
  eligibleForRefundWithCancellationInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  nonEligibilityReason?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportEmbedInfoKeySpecifier = (
  | 'accessToken'
  | 'embedUrl'
  | 'expiration'
  | ReportEmbedInfoKeySpecifier
)[];
export type ReportEmbedInfoFieldPolicy = {
  accessToken?: FieldPolicy<any> | FieldReadFunction<any>;
  embedUrl?: FieldPolicy<any> | FieldReadFunction<any>;
  expiration?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReportInfoKeySpecifier = ('groupId' | 'id' | 'name' | ReportInfoKeySpecifier)[];
export type ReportInfoFieldPolicy = {
  groupId?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  name?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ReservationKeySpecifier = (
  | 'attribute'
  | 'cabinId'
  | 'compartmentNumber'
  | 'discount'
  | 'floor'
  | 'id'
  | 'placeNumber'
  | 'placePrice'
  | 'position'
  | 'price'
  | 'product'
  | 'reference'
  | 'services'
  | 'ticketClass'
  | 'wagonNumber'
  | ReservationKeySpecifier
)[];
export type ReservationFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  cabinId?: FieldPolicy<any> | FieldReadFunction<any>;
  compartmentNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  floor?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  placeNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  placePrice?: FieldPolicy<any> | FieldReadFunction<any>;
  position?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  reference?: FieldPolicy<any> | FieldReadFunction<any>;
  services?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketClass?: FieldPolicy<any> | FieldReadFunction<any>;
  wagonNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type S3BucketWriteCredentialsKeySpecifier = (
  | 's3'
  | 'sessionId'
  | S3BucketWriteCredentialsKeySpecifier
)[];
export type S3BucketWriteCredentialsFieldPolicy = {
  s3?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type S3CredentialsKeySpecifier = (
  | 'AccessKeyId'
  | 'Expiration'
  | 'SecretAccessKey'
  | 'SessionToken'
  | S3CredentialsKeySpecifier
)[];
export type S3CredentialsFieldPolicy = {
  AccessKeyId?: FieldPolicy<any> | FieldReadFunction<any>;
  Expiration?: FieldPolicy<any> | FieldReadFunction<any>;
  SecretAccessKey?: FieldPolicy<any> | FieldReadFunction<any>;
  SessionToken?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type S3ResponseKeySpecifier = (
  | 'bucketName'
  | 'credentials'
  | 'keyPrefix'
  | 'kmsKeyId'
  | S3ResponseKeySpecifier
)[];
export type S3ResponseFieldPolicy = {
  bucketName?: FieldPolicy<any> | FieldReadFunction<any>;
  credentials?: FieldPolicy<any> | FieldReadFunction<any>;
  keyPrefix?: FieldPolicy<any> | FieldReadFunction<any>;
  kmsKeyId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesSessionKeySpecifier = (
  | 'amountToPay'
  | 'externalServicePaymentStartDeadline'
  | 'groupJourneys'
  | 'id'
  | 'onBehalfOf'
  | 'orderNumber'
  | 'price'
  | 'seriesTicket'
  | 'sessionSummary'
  | 'validUntil'
  | SalesSessionKeySpecifier
)[];
export type SalesSessionFieldPolicy = {
  amountToPay?: FieldPolicy<any> | FieldReadFunction<any>;
  externalServicePaymentStartDeadline?: FieldPolicy<any> | FieldReadFunction<any>;
  groupJourneys?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  onBehalfOf?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seriesTicket?: FieldPolicy<any> | FieldReadFunction<any>;
  sessionSummary?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SalesSessionPriceKeySpecifier = (
  | 'amount'
  | 'elements'
  | SalesSessionPriceKeySpecifier
)[];
export type SalesSessionPriceFieldPolicy = {
  amount?: FieldPolicy<any> | FieldReadFunction<any>;
  elements?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeatChangeOfferKeySpecifier = (
  | 'additionalReservationOffers'
  | 'offers'
  | 'passengerLegId'
  | SeatChangeOfferKeySpecifier
)[];
export type SeatChangeOfferFieldPolicy = {
  additionalReservationOffers?: FieldPolicy<any> | FieldReadFunction<any>;
  offers?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerLegId?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeatReservationOfferKeySpecifier = (
  | 'attribute'
  | 'commercialTrainNumber'
  | 'discount'
  | 'id'
  | 'price'
  | 'product'
  | 'totalPrice'
  | SeatReservationOfferKeySpecifier
)[];
export type SeatReservationOfferFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  discount?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  product?: FieldPolicy<any> | FieldReadFunction<any>;
  totalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeriesBundleOfferKeySpecifier = (
  | 'discountPercentage'
  | 'id'
  | 'originalPrice'
  | 'periodLengthDays'
  | 'price'
  | 'seatClass'
  | 'taxRatePercentage'
  | 'ticketCount'
  | 'type'
  | 'usageEndTime'
  | 'usageStartTime'
  | SeriesBundleOfferKeySpecifier
)[];
export type SeriesBundleOfferFieldPolicy = {
  discountPercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  originalPrice?: FieldPolicy<any> | FieldReadFunction<any>;
  periodLengthDays?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  seatClass?: FieldPolicy<any> | FieldReadFunction<any>;
  taxRatePercentage?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketCount?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  usageEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usageStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeriesBundleOffersKeySpecifier = ('offers' | SeriesBundleOffersKeySpecifier)[];
export type SeriesBundleOffersFieldPolicy = {
  offers?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SeriesTicketKeySpecifier = (
  | 'arrivalStation'
  | 'bookingId'
  | 'departureStation'
  | 'orderNumber'
  | 'passengerJourneyId'
  | 'passengerType'
  | 'periodLengthDays'
  | 'price'
  | 'stations'
  | 'ticketClass'
  | 'ticketCount'
  | 'trafficType'
  | 'type'
  | 'usageEndTime'
  | 'usageStartTime'
  | 'validUntil'
  | SeriesTicketKeySpecifier
)[];
export type SeriesTicketFieldPolicy = {
  arrivalStation?: FieldPolicy<any> | FieldReadFunction<any>;
  bookingId?: FieldPolicy<any> | FieldReadFunction<any>;
  departureStation?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  periodLengthDays?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  stations?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketClass?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketCount?: FieldPolicy<any> | FieldReadFunction<any>;
  trafficType?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  usageEndTime?: FieldPolicy<any> | FieldReadFunction<any>;
  usageStartTime?: FieldPolicy<any> | FieldReadFunction<any>;
  validUntil?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StationKeySpecifier = (
  | 'abbreviation'
  | 'countryCodeUic'
  | 'hslStation'
  | 'id'
  | 'matkahuoltoDestinations'
  | 'matkahuoltoPlaceId'
  | 'names'
  | 'timetableRows'
  | 'type'
  | 'vehicleConnections'
  | 'vehicleLoadingStation'
  | StationKeySpecifier
)[];
export type StationFieldPolicy = {
  abbreviation?: FieldPolicy<any> | FieldReadFunction<any>;
  countryCodeUic?: FieldPolicy<any> | FieldReadFunction<any>;
  hslStation?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  matkahuoltoDestinations?: FieldPolicy<any> | FieldReadFunction<any>;
  matkahuoltoPlaceId?: FieldPolicy<any> | FieldReadFunction<any>;
  names?: FieldPolicy<any> | FieldReadFunction<any>;
  timetableRows?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleConnections?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleLoadingStation?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StationNamesKeySpecifier = ('en' | 'fi' | 'sv' | StationNamesKeySpecifier)[];
export type StationNamesFieldPolicy = {
  en?: FieldPolicy<any> | FieldReadFunction<any>;
  fi?: FieldPolicy<any> | FieldReadFunction<any>;
  sv?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StationTimePairKeySpecifier = ('station' | 'time' | StationTimePairKeySpecifier)[];
export type StationTimePairFieldPolicy = {
  station?: FieldPolicy<any> | FieldReadFunction<any>;
  time?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SummaryKeySpecifier = ('breakdown' | 'total' | SummaryKeySpecifier)[];
export type SummaryFieldPolicy = {
  breakdown?: FieldPolicy<any> | FieldReadFunction<any>;
  total?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type SummaryGrossPriceKeySpecifier = ('grossAmount' | SummaryGrossPriceKeySpecifier)[];
export type SummaryGrossPriceFieldPolicy = {
  grossAmount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TicketHistoryKeySpecifier = ('journeys' | 'orderNumber' | TicketHistoryKeySpecifier)[];
export type TicketHistoryFieldPolicy = {
  journeys?: FieldPolicy<any> | FieldReadFunction<any>;
  orderNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TimetableKeySpecifier = (
  | 'actualTime'
  | 'cancelled'
  | 'commercialTrack'
  | 'commuterLineID'
  | 'departureDate'
  | 'differenceInMinutes'
  | 'runningCurrently'
  | 'scheduledTime'
  | 'stationShortCode'
  | 'trainCategory'
  | 'trainNumber'
  | 'trainType'
  | TimetableKeySpecifier
)[];
export type TimetableFieldPolicy = {
  actualTime?: FieldPolicy<any> | FieldReadFunction<any>;
  cancelled?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrack?: FieldPolicy<any> | FieldReadFunction<any>;
  commuterLineID?: FieldPolicy<any> | FieldReadFunction<any>;
  departureDate?: FieldPolicy<any> | FieldReadFunction<any>;
  differenceInMinutes?: FieldPolicy<any> | FieldReadFunction<any>;
  runningCurrently?: FieldPolicy<any> | FieldReadFunction<any>;
  scheduledTime?: FieldPolicy<any> | FieldReadFunction<any>;
  stationShortCode?: FieldPolicy<any> | FieldReadFunction<any>;
  trainCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  trainType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrafficDisruptionKeySpecifier = (
  | 'body'
  | 'created'
  | 'dates'
  | 'id'
  | 'incidentId'
  | 'language'
  | 'modified'
  | 'subject'
  | 'tag'
  | TrafficDisruptionKeySpecifier
)[];
export type TrafficDisruptionFieldPolicy = {
  body?: FieldPolicy<any> | FieldReadFunction<any>;
  created?: FieldPolicy<any> | FieldReadFunction<any>;
  dates?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  incidentId?: FieldPolicy<any> | FieldReadFunction<any>;
  language?: FieldPolicy<any> | FieldReadFunction<any>;
  modified?: FieldPolicy<any> | FieldReadFunction<any>;
  subject?: FieldPolicy<any> | FieldReadFunction<any>;
  tag?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrainKeySpecifier = ('id' | 'label' | 'type' | TrainKeySpecifier)[];
export type TrainFieldPolicy = {
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  label?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrainIncidentMessageKeySpecifier = (
  | 'incidentMessages'
  | 'trainNumber'
  | TrainIncidentMessageKeySpecifier
)[];
export type TrainIncidentMessageFieldPolicy = {
  incidentMessages?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrainInfoKeySpecifier = (
  | 'availability'
  | 'commercialLineId'
  | 'productAvailability'
  | 'rampServiceAvailable'
  | 'route'
  | 'services'
  | 'trainNumber'
  | 'trainType'
  | 'wheelchairAccessible'
  | TrainInfoKeySpecifier
)[];
export type TrainInfoFieldPolicy = {
  availability?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialLineId?: FieldPolicy<any> | FieldReadFunction<any>;
  productAvailability?: FieldPolicy<any> | FieldReadFunction<any>;
  rampServiceAvailable?: FieldPolicy<any> | FieldReadFunction<any>;
  route?: FieldPolicy<any> | FieldReadFunction<any>;
  services?: FieldPolicy<any> | FieldReadFunction<any>;
  trainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  trainType?: FieldPolicy<any> | FieldReadFunction<any>;
  wheelchairAccessible?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type TrainStopKeySpecifier = (
  | 'arrivalTime'
  | 'departureTime'
  | 'station'
  | TrainStopKeySpecifier
)[];
export type TrainStopFieldPolicy = {
  arrivalTime?: FieldPolicy<any> | FieldReadFunction<any>;
  departureTime?: FieldPolicy<any> | FieldReadFunction<any>;
  station?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UnpaidGroupOrdersKeySpecifier = (
  | 'items'
  | 'pagination'
  | UnpaidGroupOrdersKeySpecifier
)[];
export type UnpaidGroupOrdersFieldPolicy = {
  items?: FieldPolicy<any> | FieldReadFunction<any>;
  pagination?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UpdateCabinsErrorResponseKeySpecifier = (
  | 'code'
  | UpdateCabinsErrorResponseKeySpecifier
)[];
export type UpdateCabinsErrorResponseFieldPolicy = {
  code?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserKeySpecifier = (
  | 'companyIds'
  | 'companyRoles'
  | 'customerId'
  | 'customerRoles'
  | 'details'
  | 'firstName'
  | 'info'
  | 'isAuthenticated'
  | 'isCiamUser'
  | 'isConsumer'
  | 'isCorporate'
  | 'isEmployee'
  | 'isPlEmployee'
  | 'isTramEmployee'
  | 'isTravelAgent'
  | 'isVrEmployee'
  | 'lastName'
  | 'roles'
  | 'ticketHistory'
  | UserKeySpecifier
)[];
export type UserFieldPolicy = {
  companyIds?: FieldPolicy<any> | FieldReadFunction<any>;
  companyRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  customerRoles?: FieldPolicy<any> | FieldReadFunction<any>;
  details?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  info?: FieldPolicy<any> | FieldReadFunction<any>;
  isAuthenticated?: FieldPolicy<any> | FieldReadFunction<any>;
  isCiamUser?: FieldPolicy<any> | FieldReadFunction<any>;
  isConsumer?: FieldPolicy<any> | FieldReadFunction<any>;
  isCorporate?: FieldPolicy<any> | FieldReadFunction<any>;
  isEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  isPlEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  isTramEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  isTravelAgent?: FieldPolicy<any> | FieldReadFunction<any>;
  isVrEmployee?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  roles?: FieldPolicy<any> | FieldReadFunction<any>;
  ticketHistory?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type UserInfoKeySpecifier = (
  | 'activeCompanyId'
  | 'activeCompanyName'
  | 'activeEmail'
  | 'customerId'
  | 'firstName'
  | 'lastName'
  | 'mobilePhone'
  | UserInfoKeySpecifier
)[];
export type UserInfoFieldPolicy = {
  activeCompanyId?: FieldPolicy<any> | FieldReadFunction<any>;
  activeCompanyName?: FieldPolicy<any> | FieldReadFunction<any>;
  activeEmail?: FieldPolicy<any> | FieldReadFunction<any>;
  customerId?: FieldPolicy<any> | FieldReadFunction<any>;
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  mobilePhone?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type ValuesKeySpecifier = (
  | 'foodSelection1'
  | 'from'
  | 'hslZones'
  | 'passengerType'
  | 'periodLengthDays'
  | 'productName'
  | 'supplementalService'
  | 'to'
  | 'usagesCount'
  | ValuesKeySpecifier
)[];
export type ValuesFieldPolicy = {
  foodSelection1?: FieldPolicy<any> | FieldReadFunction<any>;
  from?: FieldPolicy<any> | FieldReadFunction<any>;
  hslZones?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerType?: FieldPolicy<any> | FieldReadFunction<any>;
  periodLengthDays?: FieldPolicy<any> | FieldReadFunction<any>;
  productName?: FieldPolicy<any> | FieldReadFunction<any>;
  supplementalService?: FieldPolicy<any> | FieldReadFunction<any>;
  to?: FieldPolicy<any> | FieldReadFunction<any>;
  usagesCount?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleKeySpecifier = (
  | 'cancellationInsurance'
  | 'cancellationInsuranceOffer'
  | 'id'
  | 'journeyId'
  | 'passengerJourneyId'
  | 'price'
  | 'vehicleDetails'
  | 'vehicleLoadingDetails'
  | VehicleKeySpecifier
)[];
export type VehicleFieldPolicy = {
  cancellationInsurance?: FieldPolicy<any> | FieldReadFunction<any>;
  cancellationInsuranceOffer?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  journeyId?: FieldPolicy<any> | FieldReadFunction<any>;
  passengerJourneyId?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleDetails?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleLoadingDetails?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleCancellationSummaryKeySpecifier = (
  | 'refundAmount'
  | 'status'
  | VehicleCancellationSummaryKeySpecifier
)[];
export type VehicleCancellationSummaryFieldPolicy = {
  refundAmount?: FieldPolicy<any> | FieldReadFunction<any>;
  status?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleDetailsKeySpecifier = (
  | 'height'
  | 'length'
  | 'manufacturer'
  | 'model'
  | 'registerNumber'
  | 'vehicleType'
  | VehicleDetailsKeySpecifier
)[];
export type VehicleDetailsFieldPolicy = {
  height?: FieldPolicy<any> | FieldReadFunction<any>;
  length?: FieldPolicy<any> | FieldReadFunction<any>;
  manufacturer?: FieldPolicy<any> | FieldReadFunction<any>;
  model?: FieldPolicy<any> | FieldReadFunction<any>;
  registerNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleType?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type VehicleLoadingDetailsKeySpecifier = (
  | 'firstName'
  | 'lastName'
  | 'loadedByVR'
  | 'phoneNumber'
  | 'vehicleLoadingEnd'
  | 'vehicleLoadingStart'
  | VehicleLoadingDetailsKeySpecifier
)[];
export type VehicleLoadingDetailsFieldPolicy = {
  firstName?: FieldPolicy<any> | FieldReadFunction<any>;
  lastName?: FieldPolicy<any> | FieldReadFunction<any>;
  loadedByVR?: FieldPolicy<any> | FieldReadFunction<any>;
  phoneNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleLoadingEnd?: FieldPolicy<any> | FieldReadFunction<any>;
  vehicleLoadingStart?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WagonMapDataV2KeySpecifier = (
  | 'coaches'
  | 'commercialTrainInfo'
  | WagonMapDataV2KeySpecifier
)[];
export type WagonMapDataV2FieldPolicy = {
  coaches?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainInfo?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type WagonMapNightCabinOfferKeySpecifier = (
  | 'attribute'
  | 'commercialTrainNumber'
  | 'discountCategory'
  | 'id'
  | 'price'
  | 'size'
  | 'type'
  | WagonMapNightCabinOfferKeySpecifier
)[];
export type WagonMapNightCabinOfferFieldPolicy = {
  attribute?: FieldPolicy<any> | FieldReadFunction<any>;
  commercialTrainNumber?: FieldPolicy<any> | FieldReadFunction<any>;
  discountCategory?: FieldPolicy<any> | FieldReadFunction<any>;
  id?: FieldPolicy<any> | FieldReadFunction<any>;
  price?: FieldPolicy<any> | FieldReadFunction<any>;
  size?: FieldPolicy<any> | FieldReadFunction<any>;
  type?: FieldPolicy<any> | FieldReadFunction<any>;
};
export type StrictTypedTypePolicies = {
  AdditionalProduct?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdditionalProductKeySpecifier
      | (() => undefined | AdditionalProductKeySpecifier);
    fields?: AdditionalProductFieldPolicy;
  };
  AdditionalReservationOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AdditionalReservationOfferKeySpecifier
      | (() => undefined | AdditionalReservationOfferKeySpecifier);
    fields?: AdditionalReservationOfferFieldPolicy;
  };
  AssemblyWagon?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AssemblyWagonKeySpecifier | (() => undefined | AssemblyWagonKeySpecifier);
    fields?: AssemblyWagonFieldPolicy;
  };
  AvecraExternalServiceLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AvecraExternalServiceLegKeySpecifier
      | (() => undefined | AvecraExternalServiceLegKeySpecifier);
    fields?: AvecraExternalServiceLegFieldPolicy;
  };
  AvecraOffers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AvecraOffersKeySpecifier | (() => undefined | AvecraOffersKeySpecifier);
    fields?: AvecraOffersFieldPolicy;
  };
  AvecraProduct?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AvecraProductKeySpecifier | (() => undefined | AvecraProductKeySpecifier);
    fields?: AvecraProductFieldPolicy;
  };
  AvecraProductsPerLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | AvecraProductsPerLegKeySpecifier
      | (() => undefined | AvecraProductsPerLegKeySpecifier);
    fields?: AvecraProductsPerLegFieldPolicy;
  };
  AvecraService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | AvecraServiceKeySpecifier | (() => undefined | AvecraServiceKeySpecifier);
    fields?: AvecraServiceFieldPolicy;
  };
  BedCabin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BedCabinKeySpecifier | (() => undefined | BedCabinKeySpecifier);
    fields?: BedCabinFieldPolicy;
  };
  Bicycle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BicycleKeySpecifier | (() => undefined | BicycleKeySpecifier);
    fields?: BicycleFieldPolicy;
  };
  BreakfastOffers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BreakfastOffersKeySpecifier
      | (() => undefined | BreakfastOffersKeySpecifier);
    fields?: BreakfastOffersFieldPolicy;
  };
  BundleDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BundleDetailsKeySpecifier | (() => undefined | BundleDetailsKeySpecifier);
    fields?: BundleDetailsFieldPolicy;
  };
  BusJourneyOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BusJourneyOfferKeySpecifier
      | (() => undefined | BusJourneyOfferKeySpecifier);
    fields?: BusJourneyOfferFieldPolicy;
  };
  BusJourneyOfferGroup?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BusJourneyOfferGroupKeySpecifier
      | (() => undefined | BusJourneyOfferGroupKeySpecifier);
    fields?: BusJourneyOfferGroupFieldPolicy;
  };
  BusJourneyOfferLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | BusJourneyOfferLegKeySpecifier
      | (() => undefined | BusJourneyOfferLegKeySpecifier);
    fields?: BusJourneyOfferLegFieldPolicy;
  };
  BusinessInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | BusinessInfoKeySpecifier | (() => undefined | BusinessInfoKeySpecifier);
    fields?: BusinessInfoFieldPolicy;
  };
  CabinDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CabinDetailsKeySpecifier | (() => undefined | CabinDetailsKeySpecifier);
    fields?: CabinDetailsFieldPolicy;
  };
  CabinPassenger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CabinPassengerKeySpecifier | (() => undefined | CabinPassengerKeySpecifier);
    fields?: CabinPassengerFieldPolicy;
  };
  CabinReservation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CabinReservationKeySpecifier
      | (() => undefined | CabinReservationKeySpecifier);
    fields?: CabinReservationFieldPolicy;
  };
  Calendar?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CalendarKeySpecifier | (() => undefined | CalendarKeySpecifier);
    fields?: CalendarFieldPolicy;
  };
  CancelJourneyResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancelJourneyResponseKeySpecifier
      | (() => undefined | CancelJourneyResponseKeySpecifier);
    fields?: CancelJourneyResponseFieldPolicy;
  };
  CancelJourneysResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancelJourneysResponseKeySpecifier
      | (() => undefined | CancelJourneysResponseKeySpecifier);
    fields?: CancelJourneysResponseFieldPolicy;
  };
  CancelResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CancelResponseKeySpecifier | (() => undefined | CancelResponseKeySpecifier);
    fields?: CancelResponseFieldPolicy;
  };
  CancelVehicleResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancelVehicleResponseKeySpecifier
      | (() => undefined | CancelVehicleResponseKeySpecifier);
    fields?: CancelVehicleResponseFieldPolicy;
  };
  CancellationInsurance?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancellationInsuranceKeySpecifier
      | (() => undefined | CancellationInsuranceKeySpecifier);
    fields?: CancellationInsuranceFieldPolicy;
  };
  CancellationInsuranceCoverage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancellationInsuranceCoverageKeySpecifier
      | (() => undefined | CancellationInsuranceCoverageKeySpecifier);
    fields?: CancellationInsuranceCoverageFieldPolicy;
  };
  CancellationInsuranceOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancellationInsuranceOfferKeySpecifier
      | (() => undefined | CancellationInsuranceOfferKeySpecifier);
    fields?: CancellationInsuranceOfferFieldPolicy;
  };
  CancellationSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancellationSummaryKeySpecifier
      | (() => undefined | CancellationSummaryKeySpecifier);
    fields?: CancellationSummaryFieldPolicy;
  };
  CancellationSummaryListItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CancellationSummaryListItemKeySpecifier
      | (() => undefined | CancellationSummaryListItemKeySpecifier);
    fields?: CancellationSummaryListItemFieldPolicy;
  };
  Car?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CarKeySpecifier | (() => undefined | CarKeySpecifier);
    fields?: CarFieldPolicy;
  };
  ChangeSeatsError?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ChangeSeatsErrorKeySpecifier
      | (() => undefined | ChangeSeatsErrorKeySpecifier);
    fields?: ChangeSeatsErrorFieldPolicy;
  };
  CiamCodeExchangeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CiamCodeExchangeResponseKeySpecifier
      | (() => undefined | CiamCodeExchangeResponseKeySpecifier);
    fields?: CiamCodeExchangeResponseFieldPolicy;
  };
  CiamCodeExchangeUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CiamCodeExchangeUserKeySpecifier
      | (() => undefined | CiamCodeExchangeUserKeySpecifier);
    fields?: CiamCodeExchangeUserFieldPolicy;
  };
  CiamResetPasswordResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CiamResetPasswordResponseKeySpecifier
      | (() => undefined | CiamResetPasswordResponseKeySpecifier);
    fields?: CiamResetPasswordResponseFieldPolicy;
  };
  CiamUserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CiamUserInfoKeySpecifier | (() => undefined | CiamUserInfoKeySpecifier);
    fields?: CiamUserInfoFieldPolicy;
  };
  ClaimOrderResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ClaimOrderResponseKeySpecifier
      | (() => undefined | ClaimOrderResponseKeySpecifier);
    fields?: ClaimOrderResponseFieldPolicy;
  };
  CodeExchangeResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CodeExchangeResponseKeySpecifier
      | (() => undefined | CodeExchangeResponseKeySpecifier);
    fields?: CodeExchangeResponseFieldPolicy;
  };
  CommercialTrain?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CommercialTrainKeySpecifier
      | (() => undefined | CommercialTrainKeySpecifier);
    fields?: CommercialTrainFieldPolicy;
  };
  Company?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CompanyKeySpecifier | (() => undefined | CompanyKeySpecifier);
    fields?: CompanyFieldPolicy;
  };
  CompanyRole?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CompanyRoleKeySpecifier | (() => undefined | CompanyRoleKeySpecifier);
    fields?: CompanyRoleFieldPolicy;
  };
  CompanyWithRole?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CompanyWithRoleKeySpecifier
      | (() => undefined | CompanyWithRoleKeySpecifier);
    fields?: CompanyWithRoleFieldPolicy;
  };
  ConsumerUserDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConsumerUserDetailsKeySpecifier
      | (() => undefined | ConsumerUserDetailsKeySpecifier);
    fields?: ConsumerUserDetailsFieldPolicy;
  };
  ConsumerUserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ConsumerUserInfoKeySpecifier
      | (() => undefined | ConsumerUserInfoKeySpecifier);
    fields?: ConsumerUserInfoFieldPolicy;
  };
  CorporateOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CorporateOfferKeySpecifier | (() => undefined | CorporateOfferKeySpecifier);
    fields?: CorporateOfferFieldPolicy;
  };
  CorporateProductOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CorporateProductOptionKeySpecifier
      | (() => undefined | CorporateProductOptionKeySpecifier);
    fields?: CorporateProductOptionFieldPolicy;
  };
  CorporateUserDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CorporateUserDetailsKeySpecifier
      | (() => undefined | CorporateUserDetailsKeySpecifier);
    fields?: CorporateUserDetailsFieldPolicy;
  };
  CorporateUserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CorporateUserInfoKeySpecifier
      | (() => undefined | CorporateUserInfoKeySpecifier);
    fields?: CorporateUserInfoFieldPolicy;
  };
  Creator?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CreatorKeySpecifier | (() => undefined | CreatorKeySpecifier);
    fields?: CreatorFieldPolicy;
  };
  CrmCompany?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CrmCompanyKeySpecifier | (() => undefined | CrmCompanyKeySpecifier);
    fields?: CrmCompanyFieldPolicy;
  };
  CrmCompanyCreate?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | CrmCompanyCreateKeySpecifier
      | (() => undefined | CrmCompanyCreateKeySpecifier);
    fields?: CrmCompanyCreateFieldPolicy;
  };
  CrmCustomer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CrmCustomerKeySpecifier | (() => undefined | CrmCustomerKeySpecifier);
    fields?: CrmCustomerFieldPolicy;
  };
  CrmUser?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | CrmUserKeySpecifier | (() => undefined | CrmUserKeySpecifier);
    fields?: CrmUserFieldPolicy;
  };
  Day?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DayKeySpecifier | (() => undefined | DayKeySpecifier);
    fields?: DayFieldPolicy;
  };
  DeleteCompanyUserStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | DeleteCompanyUserStatusKeySpecifier
      | (() => undefined | DeleteCompanyUserStatusKeySpecifier);
    fields?: DeleteCompanyUserStatusFieldPolicy;
  };
  Discount?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DiscountKeySpecifier | (() => undefined | DiscountKeySpecifier);
    fields?: DiscountFieldPolicy;
  };
  DiscountCode?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | DiscountCodeKeySpecifier | (() => undefined | DiscountCodeKeySpecifier);
    fields?: DiscountCodeFieldPolicy;
  };
  EmployeeUserDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmployeeUserDetailsKeySpecifier
      | (() => undefined | EmployeeUserDetailsKeySpecifier);
    fields?: EmployeeUserDetailsFieldPolicy;
  };
  EmployeeUserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | EmployeeUserInfoKeySpecifier
      | (() => undefined | EmployeeUserInfoKeySpecifier);
    fields?: EmployeeUserInfoFieldPolicy;
  };
  Error?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ErrorKeySpecifier | (() => undefined | ErrorKeySpecifier);
    fields?: ErrorFieldPolicy;
  };
  ExternalJourneyLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExternalJourneyLegKeySpecifier
      | (() => undefined | ExternalJourneyLegKeySpecifier);
    fields?: ExternalJourneyLegFieldPolicy;
  };
  ExternalService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExternalServiceKeySpecifier
      | (() => undefined | ExternalServiceKeySpecifier);
    fields?: ExternalServiceFieldPolicy;
  };
  ExternalServiceDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ExternalServiceDetailsKeySpecifier
      | (() => undefined | ExternalServiceDetailsKeySpecifier);
    fields?: ExternalServiceDetailsFieldPolicy;
  };
  FailedToFetchResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FailedToFetchResponseKeySpecifier
      | (() => undefined | FailedToFetchResponseKeySpecifier);
    fields?: FailedToFetchResponseFieldPolicy;
  };
  FinalizePaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FinalizePaymentResponseKeySpecifier
      | (() => undefined | FinalizePaymentResponseKeySpecifier);
    fields?: FinalizePaymentResponseFieldPolicy;
  };
  FormUploadResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | FormUploadResponseKeySpecifier
      | (() => undefined | FormUploadResponseKeySpecifier);
    fields?: FormUploadResponseFieldPolicy;
  };
  Group?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GroupKeySpecifier | (() => undefined | GroupKeySpecifier);
    fields?: GroupFieldPolicy;
  };
  GroupJourney?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | GroupJourneyKeySpecifier | (() => undefined | GroupJourneyKeySpecifier);
    fields?: GroupJourneyFieldPolicy;
  };
  GroupJourneyCabin?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupJourneyCabinKeySpecifier
      | (() => undefined | GroupJourneyCabinKeySpecifier);
    fields?: GroupJourneyCabinFieldPolicy;
  };
  GroupSalesJourneyOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupSalesJourneyOptionKeySpecifier
      | (() => undefined | GroupSalesJourneyOptionKeySpecifier);
    fields?: GroupSalesJourneyOptionFieldPolicy;
  };
  GroupSalesJourneyOptionPassenger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupSalesJourneyOptionPassengerKeySpecifier
      | (() => undefined | GroupSalesJourneyOptionPassengerKeySpecifier);
    fields?: GroupSalesJourneyOptionPassengerFieldPolicy;
  };
  GroupTicketView?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | GroupTicketViewKeySpecifier
      | (() => undefined | GroupTicketViewKeySpecifier);
    fields?: GroupTicketViewFieldPolicy;
  };
  Health?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | HealthKeySpecifier | (() => undefined | HealthKeySpecifier);
    fields?: HealthFieldPolicy;
  };
  IncidentMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | IncidentMessageKeySpecifier
      | (() => undefined | IncidentMessageKeySpecifier);
    fields?: IncidentMessageFieldPolicy;
  };
  Invoice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | InvoiceKeySpecifier | (() => undefined | InvoiceKeySpecifier);
    fields?: InvoiceFieldPolicy;
  };
  InvoiceNotEditable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoiceNotEditableKeySpecifier
      | (() => undefined | InvoiceNotEditableKeySpecifier);
    fields?: InvoiceNotEditableFieldPolicy;
  };
  InvoicePassenger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoicePassengerKeySpecifier
      | (() => undefined | InvoicePassengerKeySpecifier);
    fields?: InvoicePassengerFieldPolicy;
  };
  InvoicePaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | InvoicePaymentResponseKeySpecifier
      | (() => undefined | InvoicePaymentResponseKeySpecifier);
    fields?: InvoicePaymentResponseFieldPolicy;
  };
  JourneyCancellationSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JourneyCancellationSummaryKeySpecifier
      | (() => undefined | JourneyCancellationSummaryKeySpecifier);
    fields?: JourneyCancellationSummaryFieldPolicy;
  };
  JourneyFee?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JourneyFeeKeySpecifier | (() => undefined | JourneyFeeKeySpecifier);
    fields?: JourneyFeeFieldPolicy;
  };
  JourneyLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JourneyLegKeySpecifier | (() => undefined | JourneyLegKeySpecifier);
    fields?: JourneyLegFieldPolicy;
  };
  JourneyLegOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JourneyLegOfferKeySpecifier
      | (() => undefined | JourneyLegOfferKeySpecifier);
    fields?: JourneyLegOfferFieldPolicy;
  };
  JourneyOption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | JourneyOptionKeySpecifier | (() => undefined | JourneyOptionKeySpecifier);
    fields?: JourneyOptionFieldPolicy;
  };
  JourneyOptionLeg?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JourneyOptionLegKeySpecifier
      | (() => undefined | JourneyOptionLegKeySpecifier);
    fields?: JourneyOptionLegFieldPolicy;
  };
  JourneyOptionPassenger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JourneyOptionPassengerKeySpecifier
      | (() => undefined | JourneyOptionPassengerKeySpecifier);
    fields?: JourneyOptionPassengerFieldPolicy;
  };
  JourneyPassenger?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | JourneyPassengerKeySpecifier
      | (() => undefined | JourneyPassengerKeySpecifier);
    fields?: JourneyPassengerFieldPolicy;
  };
  LegExtraInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LegExtraInfoKeySpecifier | (() => undefined | LegExtraInfoKeySpecifier);
    fields?: LegExtraInfoFieldPolicy;
  };
  LegStop?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LegStopKeySpecifier | (() => undefined | LegStopKeySpecifier);
    fields?: LegStopFieldPolicy;
  };
  LegWithMeals?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LegWithMealsKeySpecifier | (() => undefined | LegWithMealsKeySpecifier);
    fields?: LegWithMealsFieldPolicy;
  };
  LineItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LineItemKeySpecifier | (() => undefined | LineItemKeySpecifier);
    fields?: LineItemFieldPolicy;
  };
  LineItemProductSubItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LineItemProductSubItemKeySpecifier
      | (() => undefined | LineItemProductSubItemKeySpecifier);
    fields?: LineItemProductSubItemFieldPolicy;
  };
  LineItemSubItem?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LineItemSubItemKeySpecifier
      | (() => undefined | LineItemSubItemKeySpecifier);
    fields?: LineItemSubItemFieldPolicy;
  };
  LoginResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | LoginResponseKeySpecifier | (() => undefined | LoginResponseKeySpecifier);
    fields?: LoginResponseFieldPolicy;
  };
  LoginResponseV2?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | LoginResponseV2KeySpecifier
      | (() => undefined | LoginResponseV2KeySpecifier);
    fields?: LoginResponseV2FieldPolicy;
  };
  MatakahuoltoDestination?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MatakahuoltoDestinationKeySpecifier
      | (() => undefined | MatakahuoltoDestinationKeySpecifier);
    fields?: MatakahuoltoDestinationFieldPolicy;
  };
  Meal?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MealKeySpecifier | (() => undefined | MealKeySpecifier);
    fields?: MealFieldPolicy;
  };
  MultiTicket?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MultiTicketKeySpecifier | (() => undefined | MultiTicketKeySpecifier);
    fields?: MultiTicketFieldPolicy;
  };
  MultiTicketUsage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MultiTicketUsageKeySpecifier
      | (() => undefined | MultiTicketUsageKeySpecifier);
    fields?: MultiTicketUsageFieldPolicy;
  };
  MultiTicketUsageDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MultiTicketUsageDetailsKeySpecifier
      | (() => undefined | MultiTicketUsageDetailsKeySpecifier);
    fields?: MultiTicketUsageDetailsFieldPolicy;
  };
  MultiTicketUsagesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | MultiTicketUsagesResponseKeySpecifier
      | (() => undefined | MultiTicketUsagesResponseKeySpecifier);
    fields?: MultiTicketUsagesResponseFieldPolicy;
  };
  MultiTickets?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MultiTicketsKeySpecifier | (() => undefined | MultiTicketsKeySpecifier);
    fields?: MultiTicketsFieldPolicy;
  };
  Mutation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | MutationKeySpecifier | (() => undefined | MutationKeySpecifier);
    fields?: MutationFieldPolicy;
  };
  NoConnections?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NoConnectionsKeySpecifier | (() => undefined | NoConnectionsKeySpecifier);
    fields?: NoConnectionsFieldPolicy;
  };
  NoOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NoOfferKeySpecifier | (() => undefined | NoOfferKeySpecifier);
    fields?: NoOfferFieldPolicy;
  };
  NotLoggedIn?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | NotLoggedInKeySpecifier | (() => undefined | NotLoggedInKeySpecifier);
    fields?: NotLoggedInFieldPolicy;
  };
  Offer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OfferKeySpecifier | (() => undefined | OfferKeySpecifier);
    fields?: OfferFieldPolicy;
  };
  OptionAvailability?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OptionAvailabilityKeySpecifier
      | (() => undefined | OptionAvailabilityKeySpecifier);
    fields?: OptionAvailabilityFieldPolicy;
  };
  Order?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrderKeySpecifier | (() => undefined | OrderKeySpecifier);
    fields?: OrderFieldPolicy;
  };
  OrderHistoryEvent?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderHistoryEventKeySpecifier
      | (() => undefined | OrderHistoryEventKeySpecifier);
    fields?: OrderHistoryEventFieldPolicy;
  };
  OrderHistoryEventChange?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderHistoryEventChangeKeySpecifier
      | (() => undefined | OrderHistoryEventChangeKeySpecifier);
    fields?: OrderHistoryEventChangeFieldPolicy;
  };
  OrderSummaries?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrderSummariesKeySpecifier | (() => undefined | OrderSummariesKeySpecifier);
    fields?: OrderSummariesFieldPolicy;
  };
  OrderSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OrderSummaryKeySpecifier | (() => undefined | OrderSummaryKeySpecifier);
    fields?: OrderSummaryFieldPolicy;
  };
  OrderSummaryJourney?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderSummaryJourneyKeySpecifier
      | (() => undefined | OrderSummaryJourneyKeySpecifier);
    fields?: OrderSummaryJourneyFieldPolicy;
  };
  OrderSummaryJourneyV2?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderSummaryJourneyV2KeySpecifier
      | (() => undefined | OrderSummaryJourneyV2KeySpecifier);
    fields?: OrderSummaryJourneyV2FieldPolicy;
  };
  OrderSummaryService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | OrderSummaryServiceKeySpecifier
      | (() => undefined | OrderSummaryServiceKeySpecifier);
    fields?: OrderSummaryServiceFieldPolicy;
  };
  Owner?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | OwnerKeySpecifier | (() => undefined | OwnerKeySpecifier);
    fields?: OwnerFieldPolicy;
  };
  PaginationInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaginationInfoKeySpecifier | (() => undefined | PaginationInfoKeySpecifier);
    fields?: PaginationInfoFieldPolicy;
  };
  PassengerJourney?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PassengerJourneyKeySpecifier
      | (() => undefined | PassengerJourneyKeySpecifier);
    fields?: PassengerJourneyFieldPolicy;
  };
  PassengerOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PassengerOfferKeySpecifier | (() => undefined | PassengerOfferKeySpecifier);
    fields?: PassengerOfferFieldPolicy;
  };
  PassengerService?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PassengerServiceKeySpecifier
      | (() => undefined | PassengerServiceKeySpecifier);
    fields?: PassengerServiceFieldPolicy;
  };
  Payment?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentKeySpecifier | (() => undefined | PaymentKeySpecifier);
    fields?: PaymentFieldPolicy;
  };
  PaymentFinalized?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentFinalizedKeySpecifier
      | (() => undefined | PaymentFinalizedKeySpecifier);
    fields?: PaymentFinalizedFieldPolicy;
  };
  PaymentFinalizedWithInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentFinalizedWithInfoKeySpecifier
      | (() => undefined | PaymentFinalizedWithInfoKeySpecifier);
    fields?: PaymentFinalizedWithInfoFieldPolicy;
  };
  PaymentMethod?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentMethodKeySpecifier | (() => undefined | PaymentMethodKeySpecifier);
    fields?: PaymentMethodFieldPolicy;
  };
  PaymentStatus?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PaymentStatusKeySpecifier | (() => undefined | PaymentStatusKeySpecifier);
    fields?: PaymentStatusFieldPolicy;
  };
  PaymentStatusesResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PaymentStatusesResponseKeySpecifier
      | (() => undefined | PaymentStatusesResponseKeySpecifier);
    fields?: PaymentStatusesResponseFieldPolicy;
  };
  PreparePaymentResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PreparePaymentResponseKeySpecifier
      | (() => undefined | PreparePaymentResponseKeySpecifier);
    fields?: PreparePaymentResponseFieldPolicy;
  };
  PresignedUrlResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | PresignedUrlResponseKeySpecifier
      | (() => undefined | PresignedUrlResponseKeySpecifier);
    fields?: PresignedUrlResponseFieldPolicy;
  };
  Price?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PriceKeySpecifier | (() => undefined | PriceKeySpecifier);
    fields?: PriceFieldPolicy;
  };
  PriceElement?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | PriceElementKeySpecifier | (() => undefined | PriceElementKeySpecifier);
    fields?: PriceElementFieldPolicy;
  };
  Product?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProductKeySpecifier | (() => undefined | ProductKeySpecifier);
    fields?: ProductFieldPolicy;
  };
  ProductAttribute?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ProductAttributeKeySpecifier
      | (() => undefined | ProductAttributeKeySpecifier);
    fields?: ProductAttributeFieldPolicy;
  };
  Profile?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ProfileKeySpecifier | (() => undefined | ProfileKeySpecifier);
    fields?: ProfileFieldPolicy;
  };
  Query?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | QueryKeySpecifier | (() => undefined | QueryKeySpecifier);
    fields?: QueryFieldPolicy;
  };
  Receipt?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReceiptKeySpecifier | (() => undefined | ReceiptKeySpecifier);
    fields?: ReceiptFieldPolicy;
  };
  RefundEligibility?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | RefundEligibilityKeySpecifier
      | (() => undefined | RefundEligibilityKeySpecifier);
    fields?: RefundEligibilityFieldPolicy;
  };
  ReportEmbedInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | ReportEmbedInfoKeySpecifier
      | (() => undefined | ReportEmbedInfoKeySpecifier);
    fields?: ReportEmbedInfoFieldPolicy;
  };
  ReportInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReportInfoKeySpecifier | (() => undefined | ReportInfoKeySpecifier);
    fields?: ReportInfoFieldPolicy;
  };
  Reservation?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ReservationKeySpecifier | (() => undefined | ReservationKeySpecifier);
    fields?: ReservationFieldPolicy;
  };
  S3BucketWriteCredentials?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | S3BucketWriteCredentialsKeySpecifier
      | (() => undefined | S3BucketWriteCredentialsKeySpecifier);
    fields?: S3BucketWriteCredentialsFieldPolicy;
  };
  S3Credentials?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | S3CredentialsKeySpecifier | (() => undefined | S3CredentialsKeySpecifier);
    fields?: S3CredentialsFieldPolicy;
  };
  S3Response?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | S3ResponseKeySpecifier | (() => undefined | S3ResponseKeySpecifier);
    fields?: S3ResponseFieldPolicy;
  };
  SalesSession?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SalesSessionKeySpecifier | (() => undefined | SalesSessionKeySpecifier);
    fields?: SalesSessionFieldPolicy;
  };
  SalesSessionPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SalesSessionPriceKeySpecifier
      | (() => undefined | SalesSessionPriceKeySpecifier);
    fields?: SalesSessionPriceFieldPolicy;
  };
  SeatChangeOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeatChangeOfferKeySpecifier
      | (() => undefined | SeatChangeOfferKeySpecifier);
    fields?: SeatChangeOfferFieldPolicy;
  };
  SeatReservationOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeatReservationOfferKeySpecifier
      | (() => undefined | SeatReservationOfferKeySpecifier);
    fields?: SeatReservationOfferFieldPolicy;
  };
  SeriesBundleOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeriesBundleOfferKeySpecifier
      | (() => undefined | SeriesBundleOfferKeySpecifier);
    fields?: SeriesBundleOfferFieldPolicy;
  };
  SeriesBundleOffers?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SeriesBundleOffersKeySpecifier
      | (() => undefined | SeriesBundleOffersKeySpecifier);
    fields?: SeriesBundleOffersFieldPolicy;
  };
  SeriesTicket?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SeriesTicketKeySpecifier | (() => undefined | SeriesTicketKeySpecifier);
    fields?: SeriesTicketFieldPolicy;
  };
  Station?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StationKeySpecifier | (() => undefined | StationKeySpecifier);
    fields?: StationFieldPolicy;
  };
  StationNames?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | StationNamesKeySpecifier | (() => undefined | StationNamesKeySpecifier);
    fields?: StationNamesFieldPolicy;
  };
  StationTimePair?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | StationTimePairKeySpecifier
      | (() => undefined | StationTimePairKeySpecifier);
    fields?: StationTimePairFieldPolicy;
  };
  Summary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | SummaryKeySpecifier | (() => undefined | SummaryKeySpecifier);
    fields?: SummaryFieldPolicy;
  };
  SummaryGrossPrice?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | SummaryGrossPriceKeySpecifier
      | (() => undefined | SummaryGrossPriceKeySpecifier);
    fields?: SummaryGrossPriceFieldPolicy;
  };
  TicketHistory?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TicketHistoryKeySpecifier | (() => undefined | TicketHistoryKeySpecifier);
    fields?: TicketHistoryFieldPolicy;
  };
  Timetable?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TimetableKeySpecifier | (() => undefined | TimetableKeySpecifier);
    fields?: TimetableFieldPolicy;
  };
  TrafficDisruption?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TrafficDisruptionKeySpecifier
      | (() => undefined | TrafficDisruptionKeySpecifier);
    fields?: TrafficDisruptionFieldPolicy;
  };
  Train?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TrainKeySpecifier | (() => undefined | TrainKeySpecifier);
    fields?: TrainFieldPolicy;
  };
  TrainIncidentMessage?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | TrainIncidentMessageKeySpecifier
      | (() => undefined | TrainIncidentMessageKeySpecifier);
    fields?: TrainIncidentMessageFieldPolicy;
  };
  TrainInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TrainInfoKeySpecifier | (() => undefined | TrainInfoKeySpecifier);
    fields?: TrainInfoFieldPolicy;
  };
  TrainStop?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | TrainStopKeySpecifier | (() => undefined | TrainStopKeySpecifier);
    fields?: TrainStopFieldPolicy;
  };
  UnpaidGroupOrders?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UnpaidGroupOrdersKeySpecifier
      | (() => undefined | UnpaidGroupOrdersKeySpecifier);
    fields?: UnpaidGroupOrdersFieldPolicy;
  };
  UpdateCabinsErrorResponse?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | UpdateCabinsErrorResponseKeySpecifier
      | (() => undefined | UpdateCabinsErrorResponseKeySpecifier);
    fields?: UpdateCabinsErrorResponseFieldPolicy;
  };
  User?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserKeySpecifier | (() => undefined | UserKeySpecifier);
    fields?: UserFieldPolicy;
  };
  UserInfo?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | UserInfoKeySpecifier | (() => undefined | UserInfoKeySpecifier);
    fields?: UserInfoFieldPolicy;
  };
  Values?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | ValuesKeySpecifier | (() => undefined | ValuesKeySpecifier);
    fields?: ValuesFieldPolicy;
  };
  Vehicle?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VehicleKeySpecifier | (() => undefined | VehicleKeySpecifier);
    fields?: VehicleFieldPolicy;
  };
  VehicleCancellationSummary?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VehicleCancellationSummaryKeySpecifier
      | (() => undefined | VehicleCancellationSummaryKeySpecifier);
    fields?: VehicleCancellationSummaryFieldPolicy;
  };
  VehicleDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | VehicleDetailsKeySpecifier | (() => undefined | VehicleDetailsKeySpecifier);
    fields?: VehicleDetailsFieldPolicy;
  };
  VehicleLoadingDetails?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | VehicleLoadingDetailsKeySpecifier
      | (() => undefined | VehicleLoadingDetailsKeySpecifier);
    fields?: VehicleLoadingDetailsFieldPolicy;
  };
  WagonMapDataV2?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?: false | WagonMapDataV2KeySpecifier | (() => undefined | WagonMapDataV2KeySpecifier);
    fields?: WagonMapDataV2FieldPolicy;
  };
  WagonMapNightCabinOffer?: Omit<TypePolicy, 'fields' | 'keyFields'> & {
    keyFields?:
      | false
      | WagonMapNightCabinOfferKeySpecifier
      | (() => undefined | WagonMapNightCabinOfferKeySpecifier);
    fields?: WagonMapNightCabinOfferFieldPolicy;
  };
};
export type TypedTypePolicies = StrictTypedTypePolicies & TypePolicies;
