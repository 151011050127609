/**
 * Given a type extending array, it returns the type of the items that comprise it.
 * Given a type promise, it returns the type of the result.
 * Taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#type-inference-in-conditional-types
 */

export type Unpacked<T> = T extends ReadonlyArray<infer U>
  ? U
  : T extends (infer U)[]
  ? U
  : T extends Promise<infer U>
  ? U
  : T;

/**
 * Remove types from T that are not assignable to U
 * Taken from https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-8.html#distributive-conditional-types
 */
export type Filter<T, U> = T extends U ? T : never;

export type Maybe<T> = T | null | undefined;

export type Falsy = null | undefined | false | 0 | '';

type Nullable<T> = T extends null | undefined ? T : never;

// Copied from https://stackoverflow.com/a/68699273
export type PartialDeep<T> = T extends
  | string
  | number
  | bigint
  | boolean
  | null
  | undefined
  | symbol
  | Date
  ? T | undefined
  : T extends Array<infer ArrayType>
  ? Array<PartialDeep<ArrayType>>
  : T extends ReadonlyArray<infer ArrayType>
  ? ReadonlyArray<ArrayType>
  : T extends Set<infer SetType>
  ? Set<PartialDeep<SetType>>
  : T extends ReadonlySet<infer SetType>
  ? ReadonlySet<SetType>
  : T extends Map<infer KeyType, infer ValueType>
  ? Map<PartialDeep<KeyType>, PartialDeep<ValueType>>
  : T extends ReadonlyMap<infer KeyType, infer ValueType>
  ? ReadonlyMap<PartialDeep<KeyType>, PartialDeep<ValueType>>
  : {
      [K in keyof T]?: PartialDeep<T[K]>;
    };

export type ArrayElementType<T extends Maybe<any[]>> = NonNullable<T>[number];

/**
 * Utility type guard function that helps narrowing down an unknown type to an object of known keys.
 * From: https://dev.to/wdoug/comment/m263
 * @param obj
 * @param key
 */
export const hasOwnProperty = <O extends object, K extends PropertyKey>(
  obj: O,
  key: K,
): obj is O & Record<K, unknown> => Object.prototype.hasOwnProperty.call(obj, key);

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isObject = (value: unknown): value is object => typeof value === 'object';

const isUndefinedOrNull = (value: unknown): value is undefined | null => value == null;

export const isDefined = <T>(value: T | undefined | null): value is T => !isUndefinedOrNull(value);

export const hasDefinedProp =
  <K extends string>(key: K) =>
  <T extends Partial<Record<K, any>>>(value: T): value is T & Record<K, NonNullable<T[K]>> =>
    isDefined(value[key]);

export const hasDefinedProps =
  <K extends string>(keys: K[]) =>
  <T extends Partial<Record<K, any>>>(value: T): value is T & Record<K, NonNullable<T[K]>> =>
    keys.every((key) => isDefined(value[key]));

export const isDate = (value: unknown): value is Date => value instanceof Date;

export const isDefinedObject = <T extends {}>(value: unknown): value is T => {
  return isObject(value) && !isUndefinedOrNull(value);
};

export const shouldNotHappen = (_: never): never => {
  throw new Error(`Should not happen: ${_}`);
};

export const exhaustiveCheck = (_: never) => {};

export const isNullable = <T>(value: T): value is Nullable<T> =>
  value === null || value === undefined;
