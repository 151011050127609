export const keys = <T extends object>(obj: T) => Object.keys(obj) as Array<keyof T>;

export const entries = <T extends object>(obj: T) =>
  Object.entries(obj) as Array<[keyof T, T[keyof T]]>;

/**
 * Compare function for two objects of same signature. Implements the rules required for Array.sort():
 * === 0 -> equal.
 * > 0: b is before a.
 * < 0: a is before b.
 */
export const compareField =
  <T>(a: T, b: T) =>
  <K extends keyof T>(field: K) => {
    return () => (a[field] < b[field] ? -1 : a[field] > b[field] ? 1 : 0);
  };

export const deepFilter = (filter: (value: any) => boolean) => {
  const fn = (value: any): any => {
    if (Array.isArray(value)) {
      return value.map((item) => fn(item)).filter((item) => filter(item));
    } else if (value !== null && typeof value === 'object') {
      return entries(value).reduce((acc, [key, value]) => {
        if (filter(value)) {
          acc[key] = fn(value);
        }
        return acc;
      }, {} as any);
    }
    return value;
  };

  return fn;
};

export const omitUndefineds = deepFilter((item) => item !== undefined) as <T>(value: T) => T;

export const fromEntries = <Key extends PropertyKey, Value>(
  entries: Iterable<readonly [Key, Value]>,
): Record<Key, Value> => {
  return [...entries].reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {} as Record<Key, Value>);
};

export const getValue = <T extends object>(
  object: T,
  key: string | number | symbol,
): T[keyof T] | undefined => (object as any)[key];

export const copy = <T>(value: T): T => JSON.parse(JSON.stringify(value));
