/* eslint-disable no-restricted-imports */
import queryString, { IParseOptions, IStringifyOptions } from 'qs';

export interface CustomParsedQs {
  [key: string]:
    | undefined
    | string
    | string[]
    | number
    | number[]
    | boolean
    | CustomParsedQs
    | CustomParsedQs[];
}

/** Resolve Boolean query string values (true | false | TRUE | FALSE) */
const booleanMap = new Map<string | null, boolean>([
  ['false', false],
  ['FALSE', false],
  ['true', true],
  ['TRUE', true],
]);

const defaultQueryStringifyOptions: IStringifyOptions = {
  arrayFormat: 'indices',
  encodeValuesOnly: true,
  strictNullHandling: true,
};

const defaultQueryParseOptions: IParseOptions = {
  ignoreQueryPrefix: true,
  strictNullHandling: true,
  decoder(str, defaultDecoder, charset, type) {
    // This is a post-decode processor for values, decode first!
    const decoded = defaultDecoder(str, undefined, charset);
    if (type !== 'value') return decoded;
    if (decoded === '') return '';
    if (booleanMap.has(decoded)) return booleanMap.get(decoded);

    // prevent Infinity and NaN being interpreted as numbers by requiring a digit to exist
    if (/\d/.test(decoded)) {
      const number = +decoded;
      // "001", "0x01", "2021-04-15" must NOT be interpreted as number
      if (`${number}` === decoded) return number;
    }

    return decoded;
  },
};

/**
 * Same as qs.parse with some default options given
 * @param str
 * @param options
 */
const parse: (...args: Parameters<typeof queryString.parse>) => CustomParsedQs = (str, options) =>
  queryString.parse(str, { ...defaultQueryParseOptions, ...options }) as CustomParsedQs;

/**
 * Same as qs.stringify with some default options given
 * @param obj
 * @param options
 */
const stringify = <T extends {}>(
  // Allow only objects since it seems like stringify is not reversible with types like null, number or array
  obj: T,
  options?: IStringifyOptions,
) => queryString.stringify(obj, { ...defaultQueryStringifyOptions, ...options });

export default {
  ...queryString,
  parse,
  stringify,
};
