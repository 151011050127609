import { isSSR } from './isSSR';

const toBoolean = (value: string | undefined) => {
  return !!value && (value.toLowerCase() === 'true' || value === '1');
};

const envs = {
  NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  NEXT_PUBLIC_ENABLE_LAMBDA_AUTH: toBoolean(process.env.NEXT_PUBLIC_ENABLE_LAMBDA_AUTH),
  NEXT_PUBLIC_GTM_AUTH: process.env.NEXT_PUBLIC_GTM_AUTH,
  NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID,
  NEXT_PUBLIC_GTM_PREVIEW: process.env.NEXT_PUBLIC_GTM_PREVIEW,
  NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_V3_SITE_KEY || '-', // Not empty string to avoid hard crash of the ReCAPTCHA
  NEXT_PUBLIC_SHOW_VERSION_NUMBER: toBoolean(process.env.NEXT_PUBLIC_SHOW_VERSION_NUMBER),
  NEXT_PUBLIC_SITE_BASE_URL: process.env.NEXT_PUBLIC_SITE_BASE_URL || '',
  NEXT_PUBLIC_WAGON_MAP_SVG_BASE_URL:
    process.env.NEXT_PUBLIC_WAGON_MAP_SVG_BASE_URL ||
    'https://dev.wagonmap.devvrfi.vrpublic.fi/images/',

  /*
     This is the actual backend URL and should not be used directly from the frontend during runtime.
     The value is used for creating a development proxy for the backend.
     For accessing the backend during runtime, use BACKEND_URL instead.
   */
  BACKEND_URL_FOR_PROXY: process.env.BACKEND_URL_FOR_PROXY || '',
  NEXT_PUBLIC_API_PATH: process.env.NEXT_PUBLIC_API_PATH || '/api/v7',
  NEXT_PUBLIC_MAPBOX_TOKEN: process.env.NEXT_PUBLIC_MAPBOX_TOKEN || '',
  NEXT_PUBLIC_MULTI_TICKET_USAGES_LEFT:
    parseInt(process.env.NEXT_PUBLIC_MULTI_TICKET_USAGES_LEFT || '', 10) || 2,
  NEXT_PUBLIC_MULTI_TICKET_DAYS_LEFT:
    parseInt(process.env.NEXT_PUBLIC_MULTI_TICKET_DAYS_LEFT || '', 10) || 15,
  FORMS_CLIENT_BASE_URL: process.env.FORMS_CLIENT_BASE_URL || '',
  NEXT_PUBLIC_RECEIPT_VIEW_URL:
    process.env.NEXT_PUBLIC_RECEIPT_VIEW_URL || 'https://www.lippu.vr.fi/kuitti',
  NEXT_PUBLIC_RECEIPT_PDF_URL:
    process.env.NEXT_PUBLIC_RECEIPT_PDF_URL || 'https://www.lippu.vr.fi/pdf-receipt',
  NEXT_PUBLIC_FORTE_CLIENT_ID: process.env.NEXT_PUBLIC_FORTE_CLIENT_ID || '',
  NEXT_PUBLIC_AD_LOGIN_PATH: process.env.NEXT_PUBLIC_AD_LOGIN_PATH || '',
  NEXT_PUBLIC_CIAM_BASEURL: process.env.NEXT_PUBLIC_CIAM_BASEURL,
  NEXT_PUBLIC_CIAM_UUA_BACKEND: process.env.NEXT_PUBLIC_CIAM_UUA_BACKEND || '',
  NEXT_PUBLIC_CONVERT_SCRIPT: process.env.NEXT_PUBLIC_CONVERT_SCRIPT,
};

export default {
  ...envs,
  // Use this for backend queries
  get BACKEND_URL() {
    // This is used for runtime queries locally and in other environments
    if (!isSSR() && window?.location?.origin) {
      return window.location.origin + envs.NEXT_PUBLIC_API_PATH;
    }

    // This is used for build time backend queries locally
    if (envs.BACKEND_URL_FOR_PROXY) {
      return envs.BACKEND_URL_FOR_PROXY;
    }

    // This is used for build time queries in CI
    return envs.NEXT_PUBLIC_SITE_BASE_URL + envs.NEXT_PUBLIC_API_PATH;
  },
};

export const isProduction =
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'PRODUCTION' ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'STAGING' ||
  process.env.NEXT_PUBLIC_ENVIRONMENT === 'DR';

export const isLocalDev = process.env.NEXT_PUBLIC_ENVIRONMENT === 'LOCAL_DEV';
